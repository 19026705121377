
// import { ADMIN_BE_URL, adminEndpoint, adminLoginEndpoint } from "../../Constant"

import { request } from "http";
import { BE_URL, taskGrid } from '../Common/Constant';
import { client } from "../Client/Client";


export async function fetchTaskData(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url : "http://localhost:8083/tasks/taskDetails",
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.getTaskData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function taskActionApi(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.updateTaskAction,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function outLookDeltaMigration(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        url: BE_URL + taskGrid.deltaMigration,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};



export async function updateLastVisitedPage(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.updateLastVisitedPage,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};



export async function updateModifiedDate(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
        url: BE_URL + taskGrid.updateModifiedDate,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function clientTaskCount(data: object) {
    let config = {
      method: 'POST',
      // url: BE_URL + '/registration/registration/sendUrl',
    //   url: "http://localhost:8083/tasks/getTaskCount",

      url: BE_URL + taskGrid.clientTaskCOunt,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }
    const response = await client(config);
    //console.log(config, "uyhgfdcv");
    return response;
  };