import { useEffect, useState } from "react";
import { AppSpecificConfigurationsProps, mailboxConfig, sessionResponse } from "../../Common/Interface";
import objCryptoJS from "crypto-js";
import { followCursor } from 'tippy.js';
import Tippy from "@tippyjs/react";

import { decodeSessionDetails } from "../../Services/CommonServices";
import { useHistory } from "react-router";
import { fetchUserOptionsFromDatabase, postOutlookCustomization } from "../../Services/AppSpecificServices";
import { updateModifiedDate } from "../../Services/taskService";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";
import { insertLastVisitedPageValueApi } from "../../Services/MigrationAndManageMigrationServices";

const AppSpecificConfigurations: React.FC<AppSpecificConfigurationsProps> = ({
  taskId,
  applicationName,
  isOpen,
  disabled,
  isEdit,
  isEditCheck,
  roleCheck,
  onToggle,
  onAppSpecificConfigSave,
  updateAppSpecificConfigSaveStatus,
}) => {
  /*PS_01 to PS_09 will be executed*/
  const history = useHistory()
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [endDateMin, setEndDateMin] = useState<string>("");
  const [endDateMax, setEndDateMax] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;
  //  const isOpen= true
  // const [role, setRole] = useState<string>(sessionStorage.getItem("role") || "");
  const [disableActions, setDisableActions] = useState<boolean>(
    roleCheck.toLowerCase() === "viewer"
  );
  const [mbxSelectedOptions, setMbxSelectedOptions] = useState<mailboxConfig>({
    notes: false,
    archiveMailbox: false,
    permissions: false,
    recoverableItems: false,
    rules: false,
    fromDate: "",
    toDate: "",
    taskId: Number(taskId),
    applicationName: "outlook",
  });

  const [basedonApplicationSAC, setBasedonApplicationSAC] = useState<boolean>(false);
  /**PS_31 to PS_37 will be executed
*/
  const mbxHandleDateChange = (date: any, field: any) => {
    setBasedonApplicationSAC(false)
    setMbxSelectedOptions((prevState: any) => ({ ...prevState, [field]: date }));

    if (field === "fromDate") {
      setEndDateMin(date);
    }
    if (field === "toDate") {
      setEndDateMax(date);
    }
  };

  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchData();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, []);

  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
            if(emailAddress == "" || emailAddress == undefined){
                await sessionTokenProperties();
            }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false)
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  /**PS_24 to PS_30 will be executed
*/
  const mbxHandleCheckboxChange = (option: string): void => {
    setBasedonApplicationSAC(false);
    setMbxSelectedOptions((prevState: any) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  }
  /**PS_10 to PS_17 will be executed
*/
  const fetchData = async () => {
    try {
      let token = await getToken()
      let payload = {
        token: token,
        taskId: Number(taskId),
        applicationName
      }
      // Fetch data from the API
      const userOptions = await fetchUserOptionsFromDatabase(payload);
      //console.log(userOptions, "aaaaaaaa");
      /*PS_18 to PS_23 will be eexcuted */

      // Update the state with the fetched data
      if (userOptions?.data?.data[0]) {
        const data = userOptions?.data?.data[0] // Extract the data object
        //console.log(userOptions?.data?.data[0], "test");

        // Update mbxSelectedOptions state based on fetched values
        //console.log(data, "Testmelbin");

        setMbxSelectedOptions((prevState: any) => ({
          ...prevState,
          archiveMailbox: data?.isArcheivedMailboxSelected,
          recoverableItems: data?.isRecoverableItemsSelected,
          notes: data?.isNotesSelected,
          rules: data?.isRulesSelected,
          permissions: data?.isPermissionsSelected,
          fromDate: new Date(data.customStartDate)?.toISOString().split('T')[0], // Convert date to ISO format
          toDate: new Date(data.customEndDate)?.toISOString().split('T')[0], // Convert date to ISO format

        }));
      }
    } catch (error) {
      console.error("Error fetching user options:", error);
    } finally {
      setLoading(false);
    }
  };
  const [errors, setErrors] = useState({
    startDateError: "",
    endDateError: "",
  });
  const validateStartDate = (startDate: any, endDate: any) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (!endDate) {
      return startDate <= currentDate;
    }
    return startDate <= endDate && startDate <= currentDate;
  };

  const validateEndDate = (startDate: any, endDate: any) => {
    const currentDate = new Date().toISOString().split("T")[0];
    return !endDate || (startDate <= endDate && endDate <= currentDate);
  };


  /**PS_38 to PS_60 will be executed
*/
  const mbxHandleSave = async () => {
    try {
      setLoading(true);

      if (!disableActions) {
        let { fromDate, toDate } = mbxSelectedOptions;
        if (!validateStartDate(fromDate, toDate)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            startDateError:
              "*Start date cannot be greater than today's date or less than the end date.",
          }));
          return;
        }
        if (!validateEndDate(fromDate, toDate)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            endDateError:
              "*End date cannot be greater than today's date or less than the start date.",
          }));
          return;
        }

        setErrors({
          startDateError: "",
          endDateError: "",
        });
        const token = await getToken()
        const mbxUpdatedOptions = {
          token: token,
          ...mbxSelectedOptions,
          fromDate,
          toDate,
          applicationName
        }
        await postOutlookCustomization(mbxUpdatedOptions);
        setLoading(false);
        const token1 = await getToken()
        if (isEdit) {
          let payload = {
            token: token1,
            taskId: Number(taskId)
          }
          const result = await updateModifiedDate(payload)
        }

      }

      // Close the accordion
      onToggle();

      updateAppSpecificConfigSaveStatus(true);

      onAppSpecificConfigSave();

      // Handle the result as needed
    } catch (error: any) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  const insertLastVisitedPageValue = async (page: string) => {
    try {
      //console.log(page, "This is the last inserted pageeeee");
      
      let token = await getToken()

      const payload = {
        token: token,
        taskId: taskId,
        lastVisitedValue: page
      };

      //console.log(payload, 'last visited');

      // Insert only newly entered records into the database
      const response: any = await insertLastVisitedPageValueApi(payload);

      if (!response) {
        //console.log('The current page value failed to insert');
      } else {
        //console.log('The current page value inserted successfully');

      }
    } catch (error) {
      console.error('Error inserting records:', error);
    }
  };

  return (<>   {applicationName === "outlook" ? (
    <div className="col-md-12">
      <div className="table-responsive theme-table bg-white mb-3">
        <table className="table table-borderless table-shadow mb-0">
          <thead>
            <tr>
              <th className="text-nowrap table-accordion-head">
                <span className="d-flex align-items-center gap-3">
                  <img
                    src="/images/table-accordion-arrow.svg"
                    alt="table-accordion-arrow"
                    className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"
                      }`}
                    data-bs-toggle="collapse"
                    data-bs-target={disabled == true || isEditCheck == false ? "#userMailboxConfiguration" : ''}
                    onClick={() => {
                      if (disabled == true && isEditCheck == false) {
                        onToggle()
                      }
                      else if (disabled == false) {
                        //console.log(disabled, "KKKKKK");
                      }
                    }}
                  />
                  <span
                    className="ac-heading cursor-pointer"
                    data-bs-toggle="collapse"
                    data-bs-target={disabled == true || isEditCheck == false ? "#userMailboxConfiguration" : ''}
                    onClick={() => {
                      if (disabled == true || isEditCheck == false) {
                        onToggle()
                      }
                      else if (disabled == false) {
                        //console.log(disabled, "KKKKKK");
                      }
                    }}
                  >
                    User Mailbox Configuration
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={`collapse ${isOpen ? "show" : ""}`}
              id="outlook_Configuration"
            >
              <td className="p-4">
                <label className="font-16 font-bold label-color">
                  What do you want to migrate ?
                </label>
                <div className="row">
                  <label className="font-semibold font-15 label-color w-100 mt-4">
                    Objects
                    {/* <img
                    src="/images/tooltip.svg"
                    alt="info"
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="tooltip content"
                  /> */}
                    <></>
                    <Tippy
                      delay={500}
                      arrow={true}
                      followCursor={'horizontal'}
                      plugins={[followCursor]}
                      placement='top'
                      content="During migration, configurations within the Objects features are automatically transferred"
                    >
                      <img className="ms-2"
                        src="/images/tooltip.svg"
                        alt="tooltip"
                        width={11}

                      />
                    </Tippy>
                  </label>
                  <div className="col-md-11 mt-3">
                    <div className="row">
                      <div className="col-md-3 label-color font-14">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>Contacts</span>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>Mails</span>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>Task</span>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>Calendars</span>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <div className="form-check mt-2 mt-md-4">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxPermission"
                            checked={mbxSelectedOptions.permissions}
                            onChange={() =>
                              mbxHandleCheckboxChange("permissions")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxPermission"
                          >
                            Permission
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <div className="mt-2 mt-md-4 form-check d-flex align-items-center mt-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxNotes"
                            checked={mbxSelectedOptions.notes}
                            onChange={() =>
                              mbxHandleCheckboxChange("notes")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxNotes"
                          >
                            Notes
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <div className="form-check mt-2 mt-md-4">
                          <input
                            className="form-check-input cus-check-input flex-shrink-0"
                            type="checkbox"
                            id="userMailboxRecvrItem"
                            checked={mbxSelectedOptions.recoverableItems}
                            onChange={() =>
                              mbxHandleCheckboxChange("recoverableItems")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxRecvrItem"
                          >
                            Recoverable Items Folders
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <div className="mt-2 mt-md-4 form-check d-flex align-items-center mt-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxRules"
                            checked={mbxSelectedOptions.rules}
                            onChange={() =>
                              mbxHandleCheckboxChange("rules")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxRules"
                          >
                            Rules
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">
                    Archive Mailbox
                  </label>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-3 label-color font-14 mt-2 mt-md-0">
                        <div className="form-check d-flex align-items-center mt-0">
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            id="userMailboxArchive"
                            checked={mbxSelectedOptions.archiveMailbox}
                            onChange={() =>
                              mbxHandleCheckboxChange("archiveMailbox")
                            }
                            disabled={disableActions}
                          />
                          <label
                            className="ms-2 mt-md-1"
                            htmlFor="userMailboxArchive"
                          >
                            Migrate Archive Mailbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">
                    User Mailbox Customization Dates
                  </label>
                  <div className="col-md-6 mt-3">
                    <div className="row">
                      <div className="col-md-4 pb-1 pb-md-0 position-relative">
                        <label
                          htmlFor="userMailbox-fromDate"
                          className="form-label w-100 font-semibold font-14 label-color"
                        >
                          From
                        </label>

                        <input
                          type="date"
                          id="userMailbox-fromDate"
                          className="form-control custom-form"
                          value={mbxSelectedOptions?.fromDate}
                          max={endDateMax || new Date().toISOString().split("T")[0]}
                          onChange={(e) =>
                            mbxHandleDateChange(e.target.value, "fromDate")
                          }
                          disabled={disableActions}
                        />
                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                      </div>
                      <div className="col-md-4 mt-2 mt-md-0 position-relative">
                        <label
                          htmlFor="userMailbox-toDate"
                          className="form-label w-100 font-semibold font-14 label-color"
                        >
                          To
                        </label>

                        <input
                          type="date"
                          id="userMailbox-toDate"
                          className="form-control custom-form"
                          value={mbxSelectedOptions.toDate}
                          min={endDateMin}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) =>
                            mbxHandleDateChange(e.target.value, "toDate")
                          }
                          disabled={disableActions}
                        />
                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>

                      </div>
                    </div>
                  </div>
                  {errors.endDateError && (
                    <span style={{ color: "red", fontSize: "12.5px" }}>
                      {errors.endDateError}
                    </span>
                  )}
                  {errors.startDateError && (
                    <span style={{ color: "red", fontSize: "12.5px" }}>
                      {errors.startDateError}
                    </span>
                  )}

                  <label className="font-semibold font-15 label-color w-100 mt-4 pt-2">
                    Other Configurations
                  </label>
                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <div className="col-md-5 label-color font-14">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>
                          Replace sender/recipients based on user Mapping
                        </span>
                      </div>
                      <div className="col-md-5 label-color font-14 mt-2 mt-md-0">
                        <img
                          src="/images/tick-symbol.svg"
                          alt="tick"
                          className="me-2"
                        />
                        <span>Meeting links replacement</span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="d-flex justify-content-end mt-5 pt-md-4">
                <button
    className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
    // disabled={loading || !(mbxSelectedOptions.archiveMailbox || mbxSelectedOptions.fromDate != "" || mbxSelectedOptions.toDate != "" || mbxSelectedOptions.notes || mbxSelectedOptions.recoverableItems || mbxSelectedOptions.permissions || mbxSelectedOptions.rules) || disableActions}
    onClick={() => {
        mbxHandleSave();
        insertLastVisitedPageValue('goLiveConfigurations');
    }}
>
    Save &amp; Continue
</button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null}    {loading && (
    <div className="overlay">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-border Loader loader-color align-center "
          role="status"
        >
          <span>
            <span className="loader-inner-circle" />
          </span>
        </div>
        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
          Loading..
        </p>
      </div>
    </div>

  )}
    {alert ? <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content popup-brd-radius">
          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
            <span className="position-absolute">
              <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
            </span>
          </div>
          <div className="modal-body border-0 text-center">
            <h5
              className="modal-title mb-3 primary-header"
              id="Failure-popupLabel"
            >
              { }
            </h5>
            <p className="primary-data">
              {alert}
            </p>
          </div>
          <div className="modal-footer border-0 pb-4 justify-content-center">
            <button
              type="button"
              className="btn primary-btn font-14 px-4 font-semibold"
              onClick={() => {
                if (isSessionEpired) {
                  history.push('/Login')
                }
                setAlert('')
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div> : null}
    {/*Failure Popup ends here */}
  </>
  )


}

export default AppSpecificConfigurations;