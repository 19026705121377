
import React, { useEffect, useState } from 'react';
// import { getAllOutlook } from './MigrationReportServices'; // Update the correct import path
import * as XLSX from 'xlsx';
import { useHistory, useLocation } from 'react-router-dom';
import Viewmigrationgrid from './ViewMigrationGrid'; // Update the correct import path
import { w3cwebsocket as WebSocket } from 'websocket';
import { BE_URL, commonErrorMsgs } from '../../Common/Constant';
import Loader from '../Common/Loader';
import { sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";
import ViewMigrationGrid from './ViewMigrationGrid';
import { getOutlookData } from '../../Services/MigrationReportServices';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
 
const ViewMigrationMaster= () => {
  /**PS_01 to PS_21 will be executed*/
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [filerApplied, setFilerApplied] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')
 
  let location: any = useLocation().state;
 
  const [getOutlook, setGetOutlook] = useState<Array<any>>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [filter, setFilter] = useState(false);
  const history = useHistory()
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchClick, setSearchClick] = useState<string>("");
  const [page, setPage] = useState<number>(10);
  const [loader, setLoader] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>('');
  //console.log(location,"alfred");
 
  const [taskId, setTaskId] = useState<number>(location?.state?.taskId);
 
  const [applicationName, setApplicationName] = useState<string>(location?.state?.applicationName);
  const [taskName, setTaskName] = useState<string>(location?.state?.taskName);
 
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');
  const [alert, setAlert] = useState<boolean>(false);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;
 
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken
 
        }
 
 
      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
 
          token['adminToken'] = adminToken
 
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchData()
window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
 
    // Cleanup function
    // return () => {
    //   client.close();
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    // };
  }, [filerApplied]);
 
 
  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");
 
      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);
 
      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
            if(emailAddress == "" || emailAddress == undefined){
                await sessionTokenProperties();
            }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
 
        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }
 
  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout
 
      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);
 
        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);
 
        //console.log("Reset Session Timer checking");
 
        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);
       
        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);
       
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);
 
        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);
 
        if (minutes > sessionUpdateTimeGap) {
 
          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }
 
        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };
 
/**PS_89 to
PS_98 will be executed  */
  const handleExport = async () => {
    try {
      const token = await getToken()
      setLoader(true);
 
      const payload = {
        token: token,
        taskId: taskId,
        status: 100,
        searchValue: "",
        sortBy: "ud_source.displayName",
        sortOrder: "asc",
        pageSize: ''
      };
      const result: any = await getOutlookData(payload)
 
      if (result?.data?.statusCode == 200) {
 
        //console.log(result?.data, "eeeeeeeeee");
 
        const filteredData = result?.data?.data?.bindingData?.map((user: any) => ({
          "Source Username": user.SourceDisplayName,
          "Target Username": user.TargetDisplayName,
          "Overall Progress": `${String(user.totalSum)} / ${String(
            user.totalCount
          )}`,
          "Overall Status": user.statusText,
        }));
 
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
 
        const workbook = XLSX.utils.book_new();
 
        XLSX.utils.book_append_sheet(workbook, worksheet, "Migration Summary");
 
        const fileName = `Outlook_Migration_Report.xlsx`
 
 
        XLSX.writeFile(workbook, fileName);
      }
      else {
        setLoader(false);
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }
 
 
    } catch (error: any) {
      console.error("Error exporting data:", error.message);
    } finally {
      setLoader(false);
    }
  };
 
  async function fetchData() {
    try{
      const token = await getToken()
      const payload = {
        token: token,
        taskId: taskId,
        status:filerApplied!='' ? Number(filerApplied)  : 100,
        searchValue: searchValue.trim(),
        sortBy: "ud_source.displayName",
        sortOrder: "asc",
        pageSize: 10
      };
      const result: any = await getOutlookData(payload)
      //console.log(result.data, "result............")
      if (result?.data?.statusCode == 200) {
        setGetOutlook(result?.data?.data?.bindingData)
      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }
    }
    catch(error:any){
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  }
 
  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
 
  // Make sure to handle the other functionalities, states, and side effects according to the requirements.
  // Don't forget to clean up the WebSocket connection when the component is unmounted.
 
  return (
    <> <div className="container-fluid container-height">
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a className="pe-0" onClick={() => {
                  history.push("/task", {
                    state: {
                      applicationName: location?.state?.applicationName,
                      applicationId: location?.state?.applicationId,
                      taskName: location?.state?.taskName,
                      taskType: location?.state?.taskType,
                    },
                  });
                }}>Task</a>
              </li>
              <li className="pe-2">{taskName}</li>
            </ul>
            {/* bread crumb ends here */}
            <div className=" d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
              <h5 className="font-20 font-semibold mb-0">
                <a
                  onClick={() => {
                    history.push("/task", {
                      state: {
                        applicationName: location?.state?.applicationName,
                        applicationId: location?.state?.applicationId,
                        taskName: location?.state?.taskName,
                        taskType: location?.state?.taskType,
                      },
                    });
                  }}
                >
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    className="me-2"
                  />
                </a>
                {taskName} ({getOutlook?.length})
                <img
                  src={imageSource || "/images/ms-outlook-logo.svg"}
                  alt="outlook-logo"
                  className="ms-2"
                />
              </h5>
              <div className="d-flex gap-3 w-md-100 flex-wrap">
                <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                  <button
                    className="btn icon-swap secondary-outline-btn"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/images/filter-active-icon.svg"
                      alt="filter-active-icon"
                    />
                    <img src="/images/filter-icon.svg" alt="filter-icon" />
                  </button>
                  {/*filter*/}
                  <div
                    className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h5 className="font-bold font-20 mb-0 custom-header-color">
                        Advanced Filter
                      </h5>
                      <span>
                        <img
                          className="cursor-pointer"
                          src="/images/close-icon.svg"
                          alt="close"
                        />
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color "
                          htmlFor="environment"
                        >
                          Overall Status
                        </label>
                        <select
                          className="form-select custom-form"
                          aria-label="Default select example"
                          id="environment"
                          onChange={(e) => {
                            debugger
                            //console.log(e.target.options[e.target.selectedIndex].id,"raymond");
                           
                            setFilterValue(
                              e.target.options[e.target.selectedIndex].id
                            );
                          }}
                        >
                          <option
                            id={""}
                            value={filterValue}
                            selected={filterValue == ""}
                            disabled
                          >
                            Select
                          </option>
                          <option id={"0"} value={filterValue}>
                            Warming
                          </option>
                          <option id={"1"} value={filterValue}>
                            Paused
                          </option>
                          <option id={"2"} value={filterValue}>
                            Cancelled
                          </option>
                          <option id={"3"} value={filterValue}>
                            Scheduled
                          </option>
                          <option id={"4"} value={filterValue}>
                            Queued
                          </option>
                          <option id={"5"} value={filterValue}>
                            In-Progress
                          </option>
                          <option id={"6"} value={filterValue}>
                            Completed
                          </option>
                          <option id={"6"} value={filterValue}>
                            Failed
                          </option>
                        </select>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center py-3">
                          <button
                            className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                            onClick={() => {
                              setFilerApplied("");
                              setFilterValue("")
                              setFilter(true);
                            }}
                          >
                            Clear
                          </button>
                          <button
                            className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                            onClick={() => {
                              setFilerApplied(filterValue)
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*filter*/}
                  <div className="input-group input-search">
                    <input
                      type="search"
                      className="form-control border-end-0 font-14 font-regular"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key == "Enter") {
                          setSearchClick(searchValue)
                          fetchData();
                        }
                      }}
                    />
                    <button
                      className="btn border-start-0 d-flex align-items-center"
                      type="button"
                    >
                      <img
                        src="/images/search.svg"
                        alt="search"
                        onClick={() => {
                          fetchData();
                        }}
                      />
                    </button>
                  </div>
                </div>
                <button
                  className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                  onClick={() => {
                    handleExport();
                  }}
                >
                  <img
                    src="/images/excel-icon-white.svg"
                    alt="add-plus-icon"
                  />
                  Export
                </button>
              </div>
            </div>
            <ViewMigrationGrid
              searchClick={searchClick}
              filterClick={filerApplied}
              taskId={taskId}
              taskName={taskName}
            />
          </div>
        </div>
      </div>
    </div>
      {loader && <Loader />}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => { setAlert(false) }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
   
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage }
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlert(false) }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}
 </>
 
 
 
 
 
  );
};
 
export default ViewMigrationMaster;