import { userMappingConfigurationsProps,payLoadInterface, responseInterface, sessionResponse } from "../../Common/Interface";
import React, { SetStateAction, useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { useHistory, useLocation } from 'react-router-dom';
import { getMappedUsers, getUsers, deleteMappedUser, insertMappingUser, importMappedUsers, insertSingleMappingUser, checkUserMappingName, updateUserMapping, insertNewMappingWithEdit } from "../../Services/UserMapping";
import CryptoJS from "crypto-js";
import fs from 'fs';
import path from 'path';
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";
import { saveAs } from 'file-saver';
// import {
//   methodA,
//   methodB,
//   // list the rest of the methods you're importing
// } from './TaskConfigurationService/UserMappingServices';
// import { InterfaceA, InterfaceB } from './Interface';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import FileSaver from 'file-saver';
import 'tippy.js/dist/tippy.css'; // base stylesheet
import 'tippy.js/themes/material.css'; // material theme
import { useStateManager } from "react-select";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { error } from "console";
import { userMappingExportTemplate } from "../../Common/Constant";

export const MappedUsers: React.FC<userMappingConfigurationsProps> = ({
  taskId,
  isOpen,
  disabled,
  isEdit,
  isEditCheck
}) => {
  const location: any = useLocation().state
  const [showImportPopup, setShowImportPopup] = useState<boolean>(false);
  const [enableSourceUsersInput, setEnableSourceUsersInput] = useState<boolean>(true);
  const [sortBySource, setSortBySource] = useState<string>('');
  const [sortOrderSource, setSortOrderSource] = useState<string>('');
  const [sortHideSource, setSortHideSource] = useState<string>('');
  const [sortByTarget, setSortByTarget] = useState<string>('');
  const [sortOrderTarget, setSortOrderTarget] = useState<string>('');
  const [sortHideTarget, setSortHideTarget] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [search, setSearch] = useState<string>("")
  const [sortHideMapped, setSortHideMapped] = useState<string>('');
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const [selectedTargetIndex, setSelectedTargetIndex] = useState<number>(0);
  // const [searchValueSource, setSourceSearchValue] = useState<string>('');
  // const [searchValueTarget, setTargetSearchValue] = useState<string>('');
  const [selectedSourceMailId, getSelectedSourceMailId] = useState<any>('')
  const [selectedTargetMailId, getSelectedTargetMailId] = useState<any>('')
  const [alertPopupVisible, setAlertPopupVisible] = useState<any>('')
  const [loader, setLoader] = useState<boolean>(false);
  const [showAlertPopup, setShowAlertPopup] = useState<boolean>(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [getMappedUserData, setGetMappedUserData] = useState<any>(null); // Adjust the type accordingly
  const [importedValues, setImportedValues] = useState<any>()
  const [createDropDown, setCreateDropDown] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<any>()
  const [sourceUsers, setSourceUsers] = useState<any[]>([]);
  const [targetUsers, setTargetUsers] = useState<any[]>([]);
  const [userSourceSearch, setUserSourceSearch] = useState<any>(null)
  const [userSourceFilter, setUserSourceFilter] = useState<any>(null)
  const [userTargetSearch, setUserTargetSearch] = useState<any>(null)
  const [userTargetFilter, setUserTargetFilter] = useState<any>(null)
  const [userSortBy, setUserSortBy] = useState<any>(null)
  const [userSortOrder, setUserSortOrder] = useState<any>(null)
  const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
  const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
  const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
  const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  let [alert, setAlert] = useState(false)
  const [fileInputValue, setFileInputValue] = useState<string>("");

  const [sourceDisplayName, setSourceDisplayName] = useState("")
  const [sourceResourceType, setSourceResourceType] = useState("")
  const [targetDisplayName, setTargetDisplayName] = useState("")
  const [targetResourceType, setTargetResourceType] = useState("")

  const [sourceSearchValue, setSourceSearchValue] = useState("")
  const [targetSearchValue, setTargetSearchValue] = useState("")


  const [sourceSearchClick, setSourceSearchClick] = useState(false)
  const [targetSearchClick, setTargetSearchClick] = useState(false)

  const [filterSourceValue, setFilterSourceValue] = useState<any>([])
  const [filterTargetValue, setFilterTargetValue] = useState<any>([])

  const [invalidData, setInvalidData] = useState([])

  const [cancelAlertMessage, setcancelAlertMessage] = useState<string>('');

  const [showCreateMapPopup, setShowCreateMapPopup] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;

  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';


  // Assuming the following exist based on context provided by the pseudocode
  //const filterSourceValue: string[] = []; // Replace with actual state/data source
  // const filterTargetValue: string[] = []; // Replace with actual state/data source

  useEffect(() => {
    debugger
    sessionTokenProperties();
    resetTimer();
    fetchMappedUser();
    fetchUserData();

    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, [search, sortBy, sortOrder]);
  let history: any = useHistory()

  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = CryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(CryptoJS.enc.Utf8);
        let updatedToken = CryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  const handleKeyDown = (e: any, type: string) => {
    try {
      if (e.key === 'ArrowDown') {
        if (type === 'source') {
          setSelectedOptionIndex(prevIndex => (prevIndex + 1) % filterSourceValue.length);
        } else if (type === 'target') {
          setSelectedTargetIndex(prevIndex => Math.min(prevIndex + 1, filterTargetValue.length - 1));
        }
      } else if (e.key === 'ArrowUp') {
        if (type === 'source') {
          setSelectedOptionIndex(prevIndex => (prevIndex - 1 + filterSourceValue.length) % filterSourceValue.length);
        } else if (type === 'target') {
          setSelectedTargetIndex(prevIndex => Math.max(prevIndex - 1, 0));
        }
      } else if (e.key === 'Enter') {
        if (type === 'source') {
          // Assuming getSelectedSourceMailId is a function that needs to be implemented
          const mailId: any = getSelectedSourceMailId(selectedOptionIndex);
          if (mailId) {
            setSourceSearchValue(mailId);
            // other processing ...
          }
        } else if (type === 'target') {
          // Assuming getSelectedTargetMailId is a function that needs to be implemented
          const mailId: any = getSelectedTargetMailId(selectedTargetIndex);
          if (mailId) {
            setTargetSearchValue(mailId);
            // other processing ...
          }
        }
      }
    }
    catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    }
  };

  const bindSourceDropdown = () => {
    debugger
    return (
      <ul className="dropdown-menu d-block mb-3 pb-0 rounded-start font-12" style={{ maxHeight: '200px', overflowY: 'auto', maxWidth: '250px' }} >
        {filterSourceValue?.length > 0 ?
          filterSourceValue?.map((value: {
            resourceType: SetStateAction<string>;
            displayName: SetStateAction<string>; mailId: any
          }, index: React.Key | null | undefined) => (
            <li
              key={index}
              className={`dropdown-item py-0 cursor-pointer search-wrapper overflow-hidden`}
              onClick={() => {
                setSourceSearchValue(value?.mailId);
                setSourceDisplayName(value?.displayName)
                setSourceResourceType(value?.resourceType)
                setSourceSearchClick(false);
                //setEnableSource(true);
              }}
            // style={{ backgroundColor: index === selectedOptionIndex ? 'grey' : 'transparent', minWidth: '300px' }}
            >
              <span className="d-flex justify-content-between align-items-center border-bottom py-2">
                <span className="py-0">{value.mailId}</span>
              </span>
            </li>
          )) : <li className="text-center font-13 color-grey-v2 p-3">No Results</li>
        }
      </ul>
    )
  }

  const bindTargetDropdown = () => {
    debugger
    return (
      <ul className="dropdown-menu d-block mb-3 pb-0 rounded-start font-12" style={{ maxHeight: '200px', overflowY: 'auto', maxWidth: '250px' }} >
        {filterTargetValue?.length > 0 ?
          filterTargetValue?.map((value: {
            resourceType: SetStateAction<string>;
            displayName: SetStateAction<string>; mailId: any
          }, index: React.Key | null | undefined) => (
            <li
              key={index}
              className={`dropdown-item py-0 cursor-pointer search-wrapper overflow-hidden`}
              onClick={() => {
                setTargetSearchValue(value?.mailId);
                setTargetDisplayName(value?.displayName)
                setTargetResourceType(value?.resourceType)
                setTargetSearchClick(false);
                //setEnableSource(true);
              }}
            // style={{ backgroundColor: index === selectedOptionIndex ? 'grey' : 'transparent', minWidth: '300px' }}
            >
              <span className="d-flex justify-content-between align-items-center border-bottom py-2">
                <span className="py-0">{value.mailId}</span>
              </span>
            </li>
          )) : <li className="text-center font-13 color-grey-v2 p-3">No Results</li>
        }
      </ul>
    )
  }

  const handleSort = (type: string, event: any) => {
    try {
      const sortId = event?.sortId as string; // Obtain sortId from event assuming it exists in the event
      const sortValue = event?.sortValue as string; // Obtain sortValue from event assuming it exists in the event

      if (type === 'source') {
        let sortID = event.target.id;
        let sortval = sortID.split("@");
        setSortBy(sortval[0]);
        setSortOrder(sortval[1]);
        setSortHideMapped(sortID);
      } else if (type === 'target') {
        let sortID = event.target.id;
        let sortval = sortID.split("@");
        setSortBy(sortval[0]);
        setSortOrder(sortval[1]);
        setSortHideMapped(sortID);
      } else if (type === 'MappedUsers') {
        let sortID = event.target.id;
        let sortval = sortID.split("@");
        setSortBy(sortval[0]);
        setSortOrder(sortval[1]);
        setSortHideMapped(sortID);

      }

      setAlertPopupVisible(true); // Set the alert popup to be visible
    }
    catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    }
  };

  async function insertSingleMappedUserData() {
    setLoader(true)
    try {
      let token = await getToken()
      const payload: any = {
        taskId: location?.state?.taskId, // assuming location.state has taskId
        token: token,
        userMappingName: location?.state?.userMappingName, // use proper variable or constant
        sourceMailId: sourceSearchValue,  // use proper variable or constant
        targetMailId: targetSearchValue, // use proper variable or constant
      };

      let resTargetUsername: any = await insertSingleMappingUser(payload)

      // 113 - 115
      if (resTargetUsername.data.statusCode == 200) {
        setSourceSearchValue('');
        setSourceDisplayName("");
        setSourceResourceType("");
        setTargetSearchValue("");
                setTargetDisplayName("")
                setTargetResourceType("")
        fetchMappedUser()
      }
      else {
        setShowAlertPopup(true)
        setAlertMessage(resTargetUsername.data.statusMessage)
      }
    } catch (error) {
      setLoader(false)
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
      //console.log(error);
    }
  }

  // const onChangeTypeSearch = (e: any) => {
  //   setUserSourceSearch(e.target.value)
  //   // setSourceSearchClick(true)
  //   const searchTerm = e.target.value.toLowerCase();
  //   const filterSource: any = sourceUsers.filter((item) => {
  //     return item.sourceMailId.toLowerCase().includes(searchTerm);
  //   });

  //   filterSource(filterSource);
  // }

  const onChangeTypeSearch = (e: any) => {
    debugger
    setSourceDisplayName('')
    setSourceResourceType('')
    setSourceSearchValue(e.target.value)
    setSourceSearchClick(true)
    //console.log(sourceUsers)
    const searchTerm = e.target.value.toLowerCase();


    // const filterSource = sourceUsers.filter((item) => {
    //     return item?.mailId?.toLowerCase().includes(searchTerm);
    // });

    const filterSource = sourceUsers.filter((item) => {
      const mailId = item?.mailId?.toLowerCase();
      if (!mailId) return false; // Skip items with nullish mailId

      // Check if the current mailId is present in the ignoredEmailIds array
      const shouldIgnore = getMappedUserData.some(
        (ignored: any) => ignored.sourceMailId.toLowerCase() === mailId
      );

      if (shouldIgnore) return false; // Ignore the item if the mailId is in the ignoredEmailIds array

      return mailId.includes(searchTerm);
    });



    setFilterSourceValue(filterSource);
  }

  const onChangeTargetSearch = (e: any) => {
    debugger
    setTargetDisplayName('')
    setTargetResourceType('')
    setTargetSearchValue(e.target.value)
    setTargetSearchClick(true)
    const searchTerm = e.target.value.toLowerCase();

    // const filterTarget = targetUsers.filter((item) => {
    //     return item.mailId?.toLowerCase().includes(searchTerm);
    // });

    const filterTarget = targetUsers.filter((item) => {
      const mailId = item?.mailId?.toLowerCase();
      if (!mailId) return false; // Skip items with nullish mailId

      // Check if the current mailId is present in the ignoredEmailIds array
      const shouldIgnore = getMappedUserData.some(
        (ignored: any) => ignored.targetMailId.toLowerCase() === mailId
      );

      if (shouldIgnore) return false; // Ignore the item if the mailId is in the ignoredEmailIds array

      return mailId.includes(searchTerm);
    });

    setFilterTargetValue(filterTarget);
  }

  const onHandleChange = (e: any) => {
    const files = e.target.files[0]; // Get the first file selected
    const fileName = files ? files.name : ''; // Check if files is truthy before accessing properties
    setFileInputValue(fileName);
    setImportedValues(e)
  }

  //   const onChangeTargetSearch = (e) => {
  //     setTargetSearchValue(e.target.value)
  //     setTargetSearchClick(true)
  //     const searchTerm = e.target.value.toLowerCase();
  //     const filterSource = getTargetUserData.filter((item) => {
  //         return item.targetMailId?.toLowerCase().includes(searchTerm);
  //     });
  //     setFilterTargetValue(filterSource);
  // }

  const templateDownload = async () => {
    try {
      setLoader(true)
      const columnNames = [
        'Source Username',
        'Source Email Address',
        'Source Type',
        'Target Username',
        'Target Email Address',
        'Target Type'
      ];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Source & Target Users Template');

      // Add the column names to the worksheet
      worksheet.addRow(columnNames);

      // Write the workbook to a buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Create a Blob for the Excel file
      //const excelBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a CSV string from the worksheet
      const csvContent = await workbook.csv.writeBuffer();

      // Create a Blob for the CSV file
      const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

      // Download the Excel file
      // saveAs(excelBlob, 'Template.xlsx');

      // Download the CSV file
      saveAs(csvBlob, 'Source & Target Users Template.csv');

      setShowImportPopup(false);
    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
      console.error('Error downloading template:', error);
    }
    finally {
      setLoader(false)
    }
  };

  // const handleImport = async (event: any) => {
  //   debugger
  //   const file = event.target?.files?.[0];
  //   if (!file) {
  //     return;
  //   }

  //   // const isExcel = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type);
  //   // const isCSV = file.type === 'text/csv';

  //   // debugger

  //   // if (!isExcel && isCSV) {
  //     if (!isExcel && isCSV) {

  //     setShowAlertPopup(true);
  //     setAlertMessage("Invalid file type");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = async (event) => {
  //     try {
  //       debugger
  //       const text = event.target?.result as string;
  //       const lines = text.split('\r\n');
  //       const headers = lines[0].split(',');
  //       let result: any[] = [];
  //       for (let i = 1; i < lines.length; i++) {
  //         const currentLine = lines[i].split(',');
  //         const obj: any = {};
  //         for (let j = 0; j < headers.length; j++) {
  //           obj[headers[j]] = currentLine[j];
  //         }
  //         result.push(obj);
  //       }
  //       result = result.splice(0, result.length - 1)
  //       //console.log(result)
  //       // Ensure you have userMappingExportTemplate defined somewhere in your code
  //       let template = [userMappingExportTemplate];
  //       const templateHeaders = Object.keys(template[0]);
  //       debugger
  //       if (
  //         !result[result.length - 1]['Source Username'] || !result[result.length - 1]['Source Email  Address'] ||
  //         !result[result.length - 1]['Source Type'] || !result[result.length - 1]['Target Username'] ||
  //         !result[result.length - 1]['Target Email  Address'] || !result[result.length - 1]['Target Type']
  //       ) {
  //         result.pop();
  //       }
  //       let validTemplate = true;
  //       templateHeaders.forEach((header) => {
  //         if (!headers.includes(header)) {
  //           validTemplate = false;
  //         }
  //       });
  //       debugger
  //       if (!validTemplate) {
  //         //console.log("!validTemplate");
  //         // setImportData([]);
  //         // setFileInputValue("");
  //         // setValidateImport({ status: true, message: importMsgs.invalidColumns });
  //         return; // Exit if a template header is not found
  //       }
  //       debugger
  //       const props = [
  //         'Source Username',
  //         'Source Email Address',
  //         'Source Type',
  //         'Target Username',
  //         'Target Email Address',
  //         'Target Type'
  //       ];

  //       const emptyValidation = result.every(obj =>
  //         props.every(prop => obj[prop] !== '')
  //       );
  //       if (!emptyValidation) {
  //         // setValidateImport({ status: true, message: importMsgs.emptyValues });
  //         return;
  //       }
  //       else {
  //         let data = result.map(item => {
  //           return {
  //             'sourceDisplayName': item['Source Username'],
  //             'sourceMailId': item['Source Email Address'],
  //             'sourceResourceType': item['Source Type'],
  //             'targetDisplayName': item['Target Username'],
  //             'targetMailId': item['Target Email Address'],
  //             'targetResourceType': item['Target Type']
  //           };
  //         });
  //         const uniqueMap = new Map();
  //         for (const obj of data) {
  //           const key = `${obj.sourceMailId}-${obj.targetMailId}`;
  //           if (!uniqueMap.has(key)) {
  //             uniqueMap.set(key, obj);
  //           }
  //         }
  //         data = Array.from(uniqueMap.values());
  //         // setValidateImport({ status: false, message: "" });
  //         // setFileInputValue(event.target.files[0].name);
  //         // setImportData(data);

  //         let token = await getToken();
  //         debugger
  //         let payload = {
  //           taskId: location?.state?.taskId,
  //           token: token,
  //           userMappingName: location?.state?.userMappingName,
  //           csvFile: data
  //         };

  //         //console.log("payload: ", payload);
  //         debugger
  //         const response = await importMappedUsers(payload);
  //         debugger
  //         if (response.data.statusCode === 200) {
  //           //setShowAlertPopup(true);
  //           fetchMappedUser()
  //           // setAlertMessage("Data imported successfully");
  //         } else {
  //           if (response.data.statusMessage == 'Invalid Data provided') {
  //             setInvalidData(response.data.data)
  //           }
  //           setShowAlertPopup(true);
  //           setAlertMessage(response.data.statusMessage);

  //         }
  //       }

  //     } catch (readError) {
  //       setShowAlertPopup(true);
  //       setAlertMessage("Error reading file");
  //     }
  //     setLoader(false);
  //   };

  //   reader.onerror = (error) => {
  //     //console.log(error);
  //     setShowAlertPopup(true);
  //     setAlertMessage("Error reading file");
  //   };

  //   reader.readAsText(file);
  // };

  const handleImport = async (event: any) => {

    setFileInputValue("");
    setImportedValues("");
    setShowImportPopup(false);

    debugger
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }

    // Check if the file type is CSV
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      setShowAlertPopup(true);
      setAlertMessage("Invalid file type. Please upload a CSV file.");
      return;
    }
    // else {
    //   setShowSuccessPopup(true)
    //   setAlertMessage("Imported Source and Target users successfully.");

    // }
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        debugger
        const text = event.target?.result as string;
        const lines = text.split('\r\n');
        const headers = lines[0].split(',');
        let result: any[] = [];
        for (let i = 1; i < lines.length; i++) {
          const currentLine = lines[i].split(',');
          const obj: any = {};
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
          }
          result.push(obj);
        }
        // result = result.splice(0, result.length - 1)
        //console.log(result)
        // Ensure you have userMappingExportTemplate defined somewhere in your code
        let template = [userMappingExportTemplate];
        const templateHeaders = Object.keys(template[0]);
        debugger
        if (
          !result[result.length - 1]['Source Username'] || !result[result.length - 1]['Source Email  Address'] ||
          !result[result.length - 1]['Source Type'] || !result[result.length - 1]['Target Username'] ||
          !result[result.length - 1]['Target Email  Address'] || !result[result.length - 1]['Target Type']
        ) {
          result.pop();
        }
        let validTemplate = true;
        templateHeaders.forEach((header) => {
          if (!headers.includes(header)) {
            validTemplate = false;
          }
        });
        debugger
        if (!validTemplate) {
          //console.log("!validTemplate");
          // setImportData([]);
          // setFileInputValue("");
          // setValidateImport({ status: true, message: importMsgs.invalidColumns });
          return; // Exit if a template header is not found
        }
        debugger
        const props = [
          'Source Username',
          'Source Email Address',
          'Source Type',
          'Target Username',
          'Target Email Address',
          'Target Type'
        ];

        const emptyValidation = result.every(obj =>
          props.every(prop => obj[prop] !== '')
        );
        if (!emptyValidation) {
          // setValidateImport({ status: true, message: importMsgs.emptyValues });
          return;
        }
        else {
          let data = result.map(item => {
            return {
              'sourceDisplayName': item['Source Username'],
              'sourceMailId': item['Source Email Address'],
              'sourceResourceType': item['Source Type'],
              'targetDisplayName': item['Target Username'],
              'targetMailId': item['Target Email Address'],
              'targetResourceType': item['Target Type']
            };
          });
          const uniqueMap = new Map();
          for (const obj of data) {
            const key = `${obj.sourceMailId}-${obj.targetMailId}`;
            if (!uniqueMap.has(key)) {
              uniqueMap.set(key, obj);
            }
          }
          data = Array.from(uniqueMap.values());
          // setValidateImport({ status: false, message: "" });
          // setFileInputValue(event.target.files[0].name);
          // setImportData(data);
          setLoader(true)
          let token = await getToken();
          debugger
          let payload = {
            taskId: location?.state?.taskId,
            token: token,
            userMappingName: location?.state?.userMappingName,
            csvFile: data
          };

          //console.log("payload: ", payload);
          debugger
          const response = await importMappedUsers(payload);
          debugger
          if (response.data.statusCode === 200) {
            //setShowAlertPopup(true);
            fetchMappedUser()
            setLoader(false)
            setShowSuccessPopup(true)
            setAlertMessage("Imported Source and Target users successfully.");
            // setAlertMessage("Data imported successfully");
          } else {
            if (response.data.statusMessage == 'Please upload file with valid details') {
              setInvalidData(response.data.data)
            }
            setShowAlertPopup(true);
            setAlertMessage('Please upload file with valid details');

          }
        }

      } catch (readError) {
        setShowAlertPopup(true);
        setAlertMessage("Please upload file with valid details");
      }
      setLoader(false);
    };

    reader.onerror = (error) => {
      //console.log(error);
      setShowAlertPopup(true);
      setAlertMessage("Error reading file");
    };

    reader.readAsText(file);
  };

  const fetchMappedUser = async () => {

    try {

      let token = await getToken()
      debugger
      setLoader(true);

      const payload: payLoadInterface = {
        taskId: location?.state?.taskId, // assuming location.state has taskId
        token: token,
        searchValue: search, // use proper variable or constant
        userMappingName: location?.state?.userMappingName, // use proper variable or constant
        sortBy: sortBy,  // use proper variable or constant
        sortOrder: sortOrder, // use proper variable or constant
      };


      const response: any = await getMappedUsers(payload)
      //console.log(response, "jhvhdvwhqgvdhgqwvdhgqwvdjhvqwhvdjhqwbdjhqbjdhbjqhwbd");

      if (response?.data?.statusCode === 200) {
        //console.log(response.data.data)
        setGetMappedUserData(response.data.data);
      } else if (response?.data?.statusCode === 503) {
        window.location.href = '/serverdown'; // Redirect to '/serverdown'
      } else {
        // Handle other statuses if necessary
      }
    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoader(false);
    }
  };

  const exportMappedUsers = async () => {


    try {
      let token = await getToken();

      setLoader(true);
      debugger

      const payload: payLoadInterface = {
        taskId: location?.state?.taskId, // assuming location.state has taskId
        token: token,
        searchValue: search, // use proper variable or constant
        userMappingName: location?.state?.userMappingName, // use proper variable or constant
        sortBy: sortBy, // use proper variable or constant
        sortOrder: sortOrder, // use proper variable or constant
      };
      const response: any = await getMappedUsers(payload);

      //console.log(response, "jhvhdvwhqgvdhgqwvdhgqwvdjhvqwhvdjhqtbdjhqbjdhbjqhwbd");

      // Convert the response data to CSV
      const convertToCSV = (data: any) => {
        const keys = Object.keys(data[0]);
        const rows = [keys.join(',')];

        data.forEach((obj: any) => {
          const values = keys.map((key) => obj[key]);
          rows.push(values.join(','));
        });

        const csvString = rows.join('\n');
        return csvString;
      };
      let data = response.data.data.map((item: any) => {
        let obj = {
          "Source Username": item.sourceDisplayName,
          "Source email address": item.sourceMailId,
          "Source type": item.sourceResourceType,
          "Target Username": item.targetDisplayName,
          "Target email address": item.targetMailId,
          "Target type": item.targetResourceType
        }
        //console.log(obj);

        return obj
      })
      const csvData = convertToCSV(data);

      // Create a blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

      // Trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'Mapped_users.csv';
      downloadLink.click();
    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoader(false);
    }
  };

  const createMapping = async () => {
    debugger
    try {
      setLoader(true);
      let token = await getToken()

      const checkNamepayload: any = {
        token: token,
        taskId: location?.state?.taskId,
        userMappingName: createDropDown,
        Action: "checkname"
      };

      const checkMappingRes: any = await checkUserMappingName(checkNamepayload);



      if (checkMappingRes.status === 200) {
        if (checkMappingRes.data.statusMessage === 'User mapping name already exisits choose a different name') {
          setErrorMsg("Entered Name already Exist");
        } else {
          setShowCreateMapPopup(false);
          insertMappingUser()


        }
      }
    }

    catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoader(false);
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      setAlert(true);

      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = CryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
          clientToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin !== undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = CryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(CryptoJS.enc.Utf8);
          adminToken = CryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      setAlert(true);

      throw error
    }
  }
  //console.log("location -- ", location);

  function bindMappedUser() {
    return (
      <>
        {getMappedUserData?.length == 0 ? <tr>
          <td colSpan={7}>
            <div className="text-center">
              <div className="pt-4 pb-3 text-center">
                <img
                  src="/images/no-data-found-img.svg"
                  alt="no-data-found-img"
                  className="mb-3"
                />
                <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                <p className="font-10 font-regular color-no-rec">
                  There is no data to show you right now
                </p>
              </div>
            </div>
          </td>
        </tr> : getMappedUserData?.map((value: any, index: any) => {
          return (
            <tr key={index}>
              <td>
                {value?.sourceDisplayName?.length > 25 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.sourceDisplayName}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.sourceDisplayName.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.sourceDisplayName
                )}
              </td>
              <td>
                {value?.sourceMailId?.length > 25 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.sourceMailId}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.sourceMailId.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.sourceMailId
                )}
              </td>
              <td>
                {value?.sourceResourceType?.length > 25 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.sourceResourceType}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.sourceResourceType.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.sourceResourceType
                )}</td>
              <td>
                {value?.targetDisplayName?.length > 25 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.targetDisplayName}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.targetDisplayName.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.targetDisplayName
                )}</td>
              <td>
                {value?.targetMailId?.length > 25 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.targetMailId}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.targetMailId.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.targetMailId
                )}</td>
              <td>{value?.targetResourceType?.length > 25 ? (
                <Tippy
                  delay={500}
                  arrow={true}
                  followCursor={'horizontal'}
                  plugins={[followCursor]}
                  placement='top-start'
                  content={value.targetResourceType}
                >
                  <span style={{ cursor: 'pointer' }}>{value.targetResourceType.substring(0, 20)}...</span>
                </Tippy>
              ) : (
                value.targetResourceType
              )}</td>
              <td className="text-center">
                <span className="d-inline-block">
                  <button className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center" onClick={() => { deleteUser(value.userMappingId) }}>
                    <img src="/images/red-delete-icon.svg" alt="blue-plus-icon" /></button>
                </span>
              </td>
            </tr>
          )
        })}
      </>
    )
  }
  async function exportUserList() {
    try {
      let token = await getToken();
      setLoader(true);
      debugger
      const payload = {
        taskId: location?.state?.taskId,
        token: token,
        sourceSearchValue: null,
        sourceFilterValue: null,
        targetSearchValue: null,
        targetFilterValue: null,
        sortBy: sortBy,
        sortOrder: sortOrder
      };

      const userData: any = await getUsers(payload);

      // Combine sourceUsers and targetUsers into a single array
      // const sourceUsers = [...userData.data.recordset];
      // const targetUsers = [...userData.data.recordsets[1]];
      // const allUsers = [...sourceUsers, ...targetUsers];

      //console.log(userData.data.data[0])

      const sourcekeysToCheck: [string, string][] = [
        ['displayName', 'sourceDisplayName'],
        ['mailId', 'sourceMailId'],
        ['resourceType', 'sourceResourceType'],
      ];

      const sourceUsers = userData.data.data[0].map((obj: { [x: string]: any; }) => {
        const newObj: { [key: string]: any } = {};

        for (const key in obj) {
          const newKey = sourcekeysToCheck.find(([oldKey, newKey]) => key === oldKey)
            ? sourcekeysToCheck.find(([oldKey, newKey]) => key === oldKey)![1]
            : key;
          newObj[newKey] = obj[key];
        }

        return newObj;
      });

      const targetkeysToCheck: [string, string][] = [
        ['displayName', 'targetDisplayName'],
        ['mailId', 'targetMailId'],
        ['resourceType', 'targetResourceType'],
      ];

      const targetUsers = userData.data.data[1].map((obj: { [x: string]: any; }) => {
        const newObj: { [key: string]: any } = {};

        for (const key in obj) {
          const newKey = targetkeysToCheck.find(([oldKey, newKey]) => key === oldKey)
            ? targetkeysToCheck.find(([oldKey, newKey]) => key === oldKey)![1]
            : key;
          newObj[newKey] = obj[key];
        }

        return newObj;
      });
      let allUsers
      if (sourceUsers.length > targetUsers.length) {
        allUsers = sourceUsers.map((obj1: any, index: string | number) => {
          const obj2 = targetUsers[index] || {};
          return { ...obj1, ...obj2 };
        });
      }
      else {
        allUsers = targetUsers.map((obj1: any, index: string | number) => {
          const obj2 = sourceUsers[index] || {};
          return { ...obj1, ...obj2 };
        });
      }

      //console.log(allUsers)


      //const allUsers:any = [...sourceUsers, ...targetUsers];


      const columnNames = [
        'sourceDisplayName',
        'sourceMailId',
        'sourceResourceType',
        'targetDisplayName',
        'targetMailId',
        'targetResourceType'
      ];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Users');

      worksheet.addRow(columnNames);

      allUsers.forEach((user: { sourceDisplayName: any; sourceMailId: any; sourceResourceType: any; targetDisplayName: any; targetMailId: any; targetResourceType: any; }) => {
        // Check if any of the required fields are empty
        if (
          user.sourceDisplayName ||
          user.sourceMailId ||
          user.sourceResourceType ||
          user.targetDisplayName ||
          user.targetMailId ||
          user.targetResourceType
        ) {
          worksheet.addRow([
            user.sourceDisplayName || '',
            user.sourceMailId || '',
            user.sourceResourceType || '',
            user.targetDisplayName || '',
            user.targetMailId || '',
            user.targetResourceType || ''
          ]);
        }
      });

      const csvContent = await workbook.csv.writeBuffer();

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

      // Use file-saver to save the CSV file
      saveAs(blob, 'users_export.csv');
    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoader(false);
    }
  }


  const fetchUserData = async () => {

    try {
      let token = await getToken()
      setLoader(true);
      const payload = {
        taskId: location?.state?.taskId,
        token: token,
        sourceSearchValue: userSourceSearch,
        sourceFilterValue: userSourceFilter,
        targetSearchValue: userTargetSearch,
        targetFilterValue: userTargetFilter,
        sortBy: sortBy,
        sortOrder: sortOrder

      }; // Make sure the clientId is defined

      const userData: any = await getUsers(payload)
      //console.log(userData, "data data data data data data data data................................");


      if (userData.data.statusCode == 200) {
        setSourceUsers(userData.data.data[0]);
        setTargetUsers(userData.data.data[1]);
      } else {
        setShowAlertPopup(true)
        setAlertMessage(userData.data.statusMessage)
      }
    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    } finally {
      setLoader(false);
    }
  };
  function bindSourceDetails() {
    return (
      <>
        {sourceUsers?.length !== 0 ?
          sourceUsers.map((value: any, index: any) => (
            <tr key={index}>
              <td>
                {value?.displayName?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.displayName}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.displayName.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.displayName
                )}
              </td>
              <td>
                {value?.mailId?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.mailId}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.mailId.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.mailId
                )}
              </td>
              <td>
                {value?.resourceType?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.resourceType}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.resourceType.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.resourceType
                )}
              </td>
            </tr>
          ))
          : <tr>
            <td colSpan={7}>
              <div className="text-center">
                <div className="pt-4 pb-3 text-center">
                  <img
                    src="/images/no-data-found-img.svg"
                    alt="no-data-found-img"
                    className="mb-3"
                  />
                  <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                  <p className="font-10 font-regular color-no-rec">
                    There is no data to show you right now
                  </p>
                </div>
              </div>
            </td>
          </tr>
        }
      </>
    )
  }

  function bindTargetDetails() {
    return (
      <>
        {targetUsers?.length !== 0 ?
          targetUsers.map((value: any, index: any) => (
            <tr key={index}>
              <td>
                {value?.displayName?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.displayName}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.displayName.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.displayName
                )}
              </td>
              <td>
                {value?.mailId?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.mailId}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.mailId.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.mailId
                )}
              </td>
              <td>
                {value?.resourceType?.length > 21 ? (
                  <Tippy
                    delay={500}
                    arrow={true}
                    followCursor={'horizontal'}
                    plugins={[followCursor]}
                    placement='top-start'
                    content={value.resourceType}
                  >
                    <span style={{ cursor: 'pointer' }}>{value.resourceType.substring(0, 20)}...</span>
                  </Tippy>
                ) : (
                  value.resourceType
                )}
              </td>
            </tr>
          ))
          :
          <tr>
            <td colSpan={7}>
              <div className="text-center">
                <div className="pt-4 pb-3 text-center">
                  <img
                    src="/images/no-data-found-img.svg"
                    alt="no-data-found-img"
                    className="mb-3"
                  />
                  <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                  <p className="font-10 font-regular color-no-rec">
                    There is no data to show you right now
                  </p>
                </div>
              </div>
            </td>
          </tr>
        }
      </>
    )
  }

  async function deleteUser(userMappingId: any) {
    try {
      setLoader(true)
      let token = await getToken()
      let payload = {
        taskId: location?.state?.taskId,
        token: token,
        userMappingId: userMappingId
      }
      let resDeleteUser: any = await deleteMappedUser(payload)
      // 103
      debugger
      if (resDeleteUser.data.statusCode == 200) {

        fetchMappedUser()
      }
      else {
        setShowAlertPopup(true)
        setAlertMessage(resDeleteUser.data.statusMessage)
      }
    } catch (error) {
      //setAlert(true)
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    }
    finally {
      setLoader(false)
    }
  }

  function handleInvalidDataDownload() {
    debugger
    try {
      debugger
      const headers = Object.keys(invalidData[0]);
      let rows = invalidData.map((obj: any) => headers.map(header => obj[header] || ''));
      rows.unshift(headers);
      let invalid = rows.map((row: any) => row.join(',')).join('\n');
      let csvData = new Blob([invalid], { type: 'text/csv;charset=utf-8;' });
      saveAs(csvData, `Invalid_Data.csv`);
      setInvalidData([])

    } catch (error) {
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
      setInvalidData([])
    }
  }

  async function insertMappingUser() {
    try {
      debugger
      setLoader(true)
      let token = await getToken()
      let payload = {
        taskId: location?.state?.taskId,
        token: token,
        userMappingName: location?.state?.userMappingName,
        newMappingName: createDropDown
      }
      let response: any = await insertNewMappingWithEdit(payload)
      debugger
      if (response.data.statusCode == 200) {
        history.push("/Configurations", {
          state: {
            ...location.state,
            lastVisited: 'userMapping'
          }
        })
        //updateUserMappingStatus('reset')
      }
      else {
        setShowAlertPopup(true)
        setAlertMessage(response.data.statusMessage)
      }
    } catch (error) {
      //setAlert(true)
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    }
    finally {
      setLoader(false)
    }
  }

  async function updateUserMappingStatus(action: string) {
    try {
      setLoader(true)
      let token = await getToken()
      let payload = {
        taskId: location?.state?.taskId,
        token: token,
        action: action,
        userMappingName: location?.state?.userMappingName
      }
      let response: any = await updateUserMapping(payload)
      debugger
      if (response.data.statusCode == 200) {
        history.push("/Configurations", {
          state: {
            ...location.state,
               lastVisited: 'userMapping'
            
          }
        })
      }
      else {
        setShowAlertPopup(true)
        setAlertMessage(response.data.statusMessage)
      }
    } catch (error) {
      //setAlert(true)
      setShowAlertPopup(true)
      setAlertMessage('Please reach AVAMIGRATRON SUPPORT')
    }
    finally {
      setLoader(false)
    }
  }
  const handleKeyDownSearch = (event: any) => {
    if (event.key === "Enter") {
      // If the pressed key is Enter, trigger the search function
      fetchUserData();
    }
  };

  //console.log(invalidData, "  invaliddata")

  return (
    <div>
      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className=" card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <h5 className="primary-header mb-4">
                    <a href=""><img src="/images/head-back-arrow.svg" alt="back-arrow" className="me-2"
                      onClick={
                        () => {
                          history.push("/Configurations", {
                            state: {

                              ...location.state
                            }
                          });
                        }}

                    /></a>
                    {location?.state?.userMappingName}
                  </h5>
                </div>
                <div className="col-md-12 col-sm-12">
                  <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link theme-tab active font-16" data-bs-toggle="pill" data-bs-target="#MappedUsers" type="button" role="tab" aria-selected="true">Mapped Users</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link theme-tab font-16" data-bs-toggle="pill" data-bs-target="#UsersList" type="button" role="tab" aria-selected="false">Users
                        List</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="MappedUsers" role="tabpanel">
                      <div className="row flex-wrap g-5">
                        <div className="col-md-12 col-sm-12 mt-2 py-3">
                          <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                            <h5 className="secondary-header mb-0">
                              Mapped Users <span>({getMappedUserData?.length})</span>
                            </h5>
                            <div className="d-flex gap-3 w-md-100 flex-wrap">
                              <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                                <div className="input-group input-search">
                                  <input type="search" className="form-control border-end-0 font-14 font-regular" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                  <button className="btn border-start-0 d-flex align-items-center" type="button"><img src="/images/search.svg" alt="search" onClick={() => { fetchMappedUser() }} /></button>
                                </div>
                              </div>
                              <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={(e) => { exportMappedUsers() }}>
                                <img src="/images/export-icon.svg" alt="export-icon" /> Export Mapped Users
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive theme-table bg-white mb-3 custom-scroll am-table-scrl  ">
                            <table className="table table-borderless table-withborder mb-0">
                              <thead className="sticky-top inline-grid-th">
                                <tr>
                                  <th className="text-nowrap">Source User Name
                                    <span className="ms-1 cursor-pointer">

                                      <img
                                        src={`/images/sort-arrow-down.svg`}
                                        alt="sort-arrow"
                                        id="su.displayName@DESC"
                                        hidden={
                                          sortHideMapped == "su.displayName@DESC" || ""
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          handleSort("MappedUsers", e);
                                        }}
                                      />

                                      <img
                                        src={`/images/sort-arrow-up.svg`}
                                        alt="sort-arrow"
                                        id="su.displayName@ASC"
                                        hidden={
                                          sortHideMapped == "su.displayName@DESC" || ""
                                            ? false
                                            : true
                                        }
                                        onClick={(e) => {
                                          handleSort('MappedUsers', e);
                                        }}
                                      />


                                    </span></th>
                                  <th className="text-nowrap">Source Email Address
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src={`/images/sort-arrow-up.svg`}
                                        alt="sort-arrow"
                                        id="userDetails.sourceMailId@ASC"
                                        hidden={
                                          sortHideMapped == "userDetails.sourceMailId@DESC" || ""
                                            ? false
                                            : true
                                        }
                                        onClick={(e) => {
                                          handleSort("MappedUsers", e);
                                        }}
                                      />
                                      <img
                                        src={`/images/sort-arrow-down.svg`}
                                        alt="sort-arrow"
                                        id="userDetails.sourceMailId@DESC"
                                        hidden={
                                          sortHideMapped == "userDetails.sourceMailId@DESC" || ""
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          handleSort("MappedUsers", e);
                                        }}
                                      />
                                    </span>
                                  </th>

                                  <th className="text-nowrap" style={{ cursor: "pointer" }}>Source Type<span className="ms-1 cursor-pointer">
                                    <img
                                      src={`/images/sort-arrow-up.svg`}
                                      alt="sort-arrow"
                                      id="sourceUser.resourceType@ASC"
                                      hidden={
                                        sortHideMapped == "sourceUser.resourceType@DESC" || ""
                                          ? false
                                          : true
                                      }
                                      onClick={(e) => {
                                        //handleSortMapped(e);
                                      }}
                                    />
                                    <img
                                      src={`/images/sort-arrow-down.svg`}
                                      alt="sort-arrow"
                                      id="sourceUser.resourceType@DESC"
                                      hidden={
                                        sortHideMapped == "sourceUser.resourceType@DESC" || ""
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        //handleSortMapped(e);
                                      }}
                                    />
                                  </span></th>

                                  <th className="text-nowrap">Target User Name<span className="ms-1 cursor-pointer">
                                    <img
                                      src={`/images/sort-arrow-up.svg`}
                                      alt="sort-arrow"
                                      id="userDetails.targetDisplayName@ASC"
                                      hidden={
                                        sortHideMapped == "userDetails.targetDisplayName@DESC" || ""
                                          ? false
                                          : true
                                      }
                                      onClick={(e: any) => {
                                        handleSort("MappedUsers", e);
                                      }}
                                    />
                                    <img
                                      src={`/images/sort-arrow-down.svg`}
                                      alt="sort-arrow"
                                      id="userDetails.targetDisplayName@DESC"
                                      hidden={
                                        sortHideMapped == "userDetails.targetDisplayName@DESC" || ""
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        handleSort("MappedUsers", e);
                                      }}
                                    />
                                  </span></th>
                                  <th className="text-nowrap">Target Email Address<span className="ms-1 cursor-pointer">
                                    <img
                                      src={`/images/sort-arrow-up.svg`}
                                      alt="sort-arrow"
                                      id="userDetails.targetMailId@ASC"
                                      hidden={
                                        sortHideMapped == "userDetails.targetMailId@DESC" || ""
                                          ? false
                                          : true
                                      }
                                      onClick={(e) => {
                                        handleSort("MappedUsers", e);
                                      }}
                                    />
                                    <img
                                      src={`/images/sort-arrow-down.svg`}
                                      alt="sort-arrow"
                                      id="userDetails.targetMailId@DESC"
                                      hidden={
                                        sortHideMapped == "userDetails.targetMailId@DESC" || ""
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        handleSort("MappedUsers", e);
                                      }}
                                    />                                  </span></th>

                                  <th className="text-nowrap" style={{ cursor: "pointer" }}>Target Type<span className="ms-1 cursor-pointer">
                                    <img
                                      src={`/images/sort-arrow-up.svg`}
                                      alt="sort-arrow"
                                      id="targetUser.resourceType@ASC"
                                      hidden={
                                        sortHideMapped == "targetUser.resourceType@DESC" || ""
                                          ? false
                                          : true
                                      }
                                      onClick={(e) => {
                                        //handleSortMapped(e);
                                      }}
                                    />
                                    <img
                                      src={`/images/sort-arrow-down.svg`}
                                      alt="sort-arrow"
                                      id="targetUser.resourceType@DESC"
                                      hidden={
                                        sortHideMapped == "targetUser.resourceType@DESC" || ""
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        //handleSortMapped(e);
                                      }}
                                    />
                                  </span></th>

                                  <th className="text-nowrap text-center ">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="sticky-top cust-inline-grid-pos">
                                  <td><input type="text" className="form-control custom-form" disabled value={sourceDisplayName} placeholder="Source User Name" /></td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Source User Email"
                                      className="form-control custom-form"
                                      value={sourceSearchValue}
                                      maxLength={320}
                                      onChange={(e) => { onChangeTypeSearch(e) }}
                                      onKeyDown={(e) => { handleKeyDown(e, 'source') }}
                                    />
                                    {sourceSearchValue == "" || sourceSearchClick == false ? "" : bindSourceDropdown()}
                                  </td>
                                  <td><input type="text" className="form-control custom-form" disabled value={sourceResourceType} placeholder="Source Type"/></td>
                                  <td><input type="text" className="form-control custom-form" disabled value={targetDisplayName} placeholder="Target User Name" /></td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Target User Email"
                                      className="form-control custom-form"
                                      value={targetSearchValue}
                                      maxLength={320}
                                      onChange={(e) => { onChangeTargetSearch(e) }}
                                      onKeyDown={(e) => { handleKeyDown(e, 'target') }}
                                    />
                                    {targetSearchValue == "" || targetSearchClick == false ? "" :
                                      bindTargetDropdown()
                                    }
                                  </td>
                                  <td><input type="text" className="form-control custom-form" disabled value={targetResourceType} placeholder="Target Type" /></td>
                                  <td className="text-center">
                                    <span className="d-inline-block">
                                      {/* 160 */}
                                      <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                        onClick={() => {
                                          //setDisableSave(false); 
                                          insertSingleMappedUserData()
                                        }}
                                        disabled={sourceSearchValue == "" || targetSearchValue == "" || sourceDisplayName == "" || sourceResourceType == "" || targetDisplayName == "" || targetResourceType == "" || targetResourceType != sourceResourceType}>
                                        <img src="/images/blue-plus-icon.svg" alt="blue-plus-icon" /></button>
                                    </span>
                                  </td>
                                </tr>
                                {bindMappedUser()}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-md-flex justify-content-md-end mt-4 pt-2 gap-3 d-grid">
                            <button className="btn secondary-btn font-14 font-semibold" onClick={() => { setcancelAlertMessage("Are you sure you want to cancel the user mapping ?") }}>Cancel</button>
                            <button className="btn primary-btn font-14 font-semibold" disabled={getMappedUserData?.length == 0} onClick={() => {
                              if (location?.state?.creating) {
                                setcancelAlertMessage('')
                                updateUserMappingStatus('update')
                              }
                              else if (!location?.state?.creating) {
                                setcancelAlertMessage('')
                                setShowCreateMapPopup(true)
                                //insertMappingUser()
                              }
                              else {
                                setcancelAlertMessage('')
                              }
                            }}>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="UsersList" role="tabpanel">
                      <div className="row flex-wrap g-5">
                        <div className="col-md-12 col-sm-12 mt-2 py-3">
                          <div className="d-flex align-items-center justify-content-between flex-wrap gap-3 ">
                            <h5 className="secondary-header mb-0">
                              Users List
                            </h5>
                            <div className="d-flex gap-3 w-md-100 flex-wrap">
                              <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" disabled={!location?.state?.creating} onClick={(e: any) => { setShowImportPopup(true) }}>
                                <img src="/images/import-icon.svg" alt="import-icon" /> Import
                                Mapped Users
                              </button>
                              <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={(e: any) => { exportUserList() }}>
                                <img src="/images/export-icon.svg" alt="export-icon" /> Export Users List
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-3">
                          <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                            <h5 className="tertiary-header mb-0">
                              Source Users <span>({sourceUsers.length})</span>
                            </h5>
                            <div className="d-flex gap-3 flex-wrap">
                              <div className="d-flex gap-3 flex-row-reverse flex-md-row ">
                                <div className="input-group input-search">
                                  <input type="search" className="form-control border-end-0 font-14 font-regular" placeholder="Search"
                                    // onChange={(e) => { setUserSourceSearch(e.target.value) }}
                                    onChange={(e) => setUserSourceSearch(e.target.value?.trim())}
                                    onKeyDown={(e: any) => handleKeyDownSearch(e)}
                                  />
                                  <button className="btn border-start-0 d-flex align-items-center" type="button"><img src="/images/search.svg" alt="search" onClick={() => { fetchUserData }} /></button>
                                </div>
                              </div>
                              <button className="btn secondary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                                <img src="/images/refresh-icon.svg" alt="refresh-icon" />
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive theme-table bg-white custom-scroll am-table-scrl">
                            <table className="table table-borderless mb-0">
                              <thead className="sticky-top">
                                <tr>
                                  <th className="text-nowrap">Source User Name<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-down.svg" alt="sort-arrow-down" /></span></th>
                                  <th className="text-nowrap">Source Email Address<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-up.svg" alt="sort-arrow-up" /></span></th>
                                  <th className="text-nowrap">Source Type<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-up.svg" alt="sort-arrow-up" /></span></th>
                                </tr>
                              </thead>
                              <tbody>
                                {bindSourceDetails()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-3">
                          <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                            <h5 className="tertiary-header mb-0">
                              Target Users <span>({targetUsers.length})</span>
                            </h5>
                            <div className="d-flex gap-3 flex-wrap">
                              <div className="d-flex gap-3 flex-row-reverse flex-md-row">
                                <div className="input-group input-search">
                                  <input type="search" className="form-control border-end-0 font-14 font-regular" placeholder="Search"
                                    onChange={(e) => { setUserTargetSearch(e.target.value?.trim()) }}

                                    onKeyDown={(e: any) => handleKeyDownSearch(e)}
                                  />
                                  <button className="btn border-start-0 d-flex align-items-center" type="button"><img src="/images/search.svg" alt="search" onClick={() => { fetchUserData() }} /></button>
                                </div>
                              </div>
                              <button className="btn secondary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                                <img src="/images/refresh-icon.svg" alt="refresh-icon" />
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive theme-table bg-white custom-scroll am-table-scrl">
                            <table className="table table-borderless mb-0">
                              <thead className="sticky-top">
                                <tr>
                                  <th className="text-nowrap">Target User Name<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-down.svg" alt="sort-arrow-down" /></span></th>
                                  <th className="text-nowrap">Target Email Address<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-up.svg" alt="sort-arrow-up" /></span></th>
                                  <th className="text-nowrap">Target Type<span className="ms-1 cursor-pointer"><img src="images/sort-arrow-up.svg" alt="sort-arrow-up" /></span></th>
                                </tr>
                              </thead>
                              <tbody>
                                {bindTargetDetails()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {
                        showImportPopup == true ?
                          <div
                            className="modal fade show "
                            id="TeamsChannelCreation"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="TeamsChannelCreationLabel"
                            aria-hidden="true"
                            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}

                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content p-4">
                                <div className="modal-header border-0 p-0">
                                  <h1
                                    className="font-20 font-bold custom-header-color m-0"
                                    id="TeamsChannelCreationLabel"
                                  >Import Mapped Users

                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close shadow-none"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={
                                      (e) => {
                                        setFileInputValue("");
                                        setImportedValues("");
                                        setShowImportPopup(false);
                                      }}
                                  />
                                </div>

                                <div className="modal-body border-0 p-0 pb-2">
                                  <div className="row">

                                    <div className="col-md-12 mt-3">
                                      <div className="mb-1">
                                        <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">
                                          Import User Mapping Details
                                          <span className="mandatory-color">*</span>
                                        </label>
                                        <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                                          <div className="input-group mb-3">
                                            <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">
                                              Browse
                                              <input className="form-control" type="file" id="formFile" onChange={(e) => onHandleChange(e)} />
                                            </span>
                                            <input
                                              type="text"
                                              className="form-control file-input-field"
                                              placeholder="No file chosen"
                                              aria-label="Example text with button addon"
                                              readOnly
                                              value={fileInputValue}

                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <button onClick={() => { templateDownload() }} className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0">
                                        <img src="/images/download-icon.svg" alt="back" width={12} />
                                        <span className="ms-1">Download User Mapping Template</span>
                                      </button>
                                      <div className="d-flex justify-content-end mt-4 gap-2">
                                        <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" onClick={
                                          (e) => {
                                            setFileInputValue("");
                                            setImportedValues("");
                                            setShowImportPopup(false);
                                          }}>Cancel</button>
                                        <button type="button" className="btn primary-btn" onClick={() => { setShowImportPopup(false); handleImport(importedValues) }}>Import</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> : <div></div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAlertPopup && (
        <div className="modal fade show" id="Alert-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Alert-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="Alert-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Alert-popupLabel">Alert</h5>
                <p className="primary-data font-16">{alertMessage}</p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button type="button" className="btn primary-btn font-14 px-4 font-semibold me-2" data-bs-dismiss="modal" onClick={() => {
                  { invalidData.length > 0 ? handleInvalidDataDownload() : null }; if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setShowAlertPopup(false); setAlertMessage('');
                }}>OK</button>

              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <div className="container-fluid">
        <div className="overlay">
          <div className="position-absolute top-50 start-50 translate-middle">
            <div
              className="spinner-border Loader loader-color align-center "
              role="status"
            >
              <span>
                <span className="loader-inner-circle" />
              </span>
            </div>
            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
              Loading..
            </p>
          </div>
        </div>
      </div>}

      {cancelAlertMessage && (
        <div className="modal fade show" id="Alert-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Alert-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="Alert-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Alert-popupLabel">Alert</h5>
                <p className="primary-data font-16">{cancelAlertMessage}</p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button type="button" className="btn secondary-btn font-14 px-4 font-semibold me-2" data-bs-dismiss="modal" onClick={() => { setcancelAlertMessage('') }}>No</button>
                <button type="button" className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal" onClick={() => {

                  setcancelAlertMessage('')
                  updateUserMappingStatus('reset')

                }}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      )}


      {showCreateMapPopup ?
        <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }} id="createMapping" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="createMappingLabel" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="modal-header border-0 p-0">
                <h1 className="font-20 font-bold custom-header-color m-0" id="createMappingLabel">Create Mapping
                </h1>
                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" style={{ cursor: 'pointer' }} aria-label="Close" onClick={() => { setShowCreateMapPopup(false); setErrorMsg(""); setCreateDropDown("") }} />
              </div>
              <div className="modal-body border-0 p-0 pt-2 pb-2">
                <div className="row mt-2">
                  <div className="col-12">
                    <label className="font-14 font-semibold label-color w-100 my-2 pt-3" htmlFor="mappingName">Mapping Name <span className="mandatory-color">*</span></label>
                    {/* 26 */}
                    <input type="text" className="form-control custom-form font-14 font-regular" id="mappingName" value={createDropDown} placeholder="Enter Mapping Name" maxLength={80} onChange={(e) => {
                      setCreateDropDown(e.target.value)
                      if (!/^[a-zA-Z0-9_]+$/.test(e.target.value)) {
                        setErrorMsg("Entered Name is Invalid");
                      }
                      else {
                        setErrorMsg("");
                      }
                    }} disabled={role.toLowerCase() == 'adminviewer' || role.toLowerCase() == 'viewer'} />
                    <span style={{ color: "red", fontSize: "12px" }}>{errorMsg}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 p-0 mt-5">
                <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" style={{ cursor: 'pointer' }} onClick={() => { setShowCreateMapPopup(false); setErrorMsg(""); setCreateDropDown("") }}>Cancel</button>
                {/* 27 */}
                <button type="button" className="btn primary-btn" onClick={() => { createMapping() }} style={{ cursor: 'pointer' }} disabled={role?.toLowerCase() == 'adminviewer' || role?.toLowerCase() == 'viewer' || createDropDown?.trim() == "" || errorMsg !== ''}>Continue</button>
              </div>
            </div>
          </div>
        </div>
        : <></>}
      {showSuccessPopup && (
        <div className="modal fade show" id="Alert-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Alert-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,1.0)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/success-popup-icon.svg" alt="Success-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">Success !</h5>
                <p className="primary-data font-16">{alertMessage}</p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button type="button" className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal" onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setShowSuccessPopup(false); setAlertMessage('');
                }}>OK</button>

              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

