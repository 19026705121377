import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";

import {
    getMappedMailboxes,
    updateMailUserAction,
    insertScheduleMigration,
    startOutlookMigration
} from "../../Services/MigrationAndManageMigrationServices";

interface SelectMigrationProps {
    taskId: any;
    clientId: any;
    applicationName: any;
    isOpen: boolean;
    onToggle: () => void;
    onSelectMigrationSave: () => void;
    reloadMigration: boolean;
    fetchDataInManageMigration: () => void;
}
// PS_61, PS_62, PS_63, PS_64, PS_65, PS_66, PS_67, PS_68, PS_69, PS_70, PS_71, PS_72, PS_73,
const SelectMigration: React.FC<SelectMigrationProps> = ({ taskId, clientId, isOpen, onToggle, applicationName, onSelectMigrationSave, reloadMigration, fetchDataInManageMigration }) => {
    //PS_26
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [totalDataCount, settotalDataCount] = useState(0);
    let history = useHistory();
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [sortBy, setSortBy] = useState<string>("su.sourceDisplayName");
    const [sortOrder, setSortOrder] = useState<string>("asc");
    const [sortHide, setSortHide] = useState<string>("");

    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [renderstate, setrenderstate] = useState<boolean>(false)

    const [alertpopup, setAlertpopup] = useState<boolean>(false);
    const [isAlterOn, setIsAlterOn] = useState<boolean>(true)

    const [scheduleMigrationDateTime, setScheduleMigrationDateTime] = useState<boolean>(false)
    const [scheduleMigrationDateTimeWarning, setScheduleMigrationDateTimeWarning] = useState<string>('')

    const [dateInput, setDateInput] = useState<string>('')
    const [timeInput, setTimeInput] = useState<string>('')
    const [minTime, setMinTime] = useState<string>('');
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;


    const [filterValueForGroups, setFilterValueForGroups] = useState<string>('');

    // PS_27
    const obj: any = {
        outlook: [
            {
                header: "Source User Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Email",
                value: "sourceMailId",
                sort: "su.sourceMailId"
            },
            {
                header: "Target User Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Email",
                value: "targetMailId",
                sort: "tu.targetMailId"
            },
        ],
    }

    // PS_74, PS_75, PS_76, PS_77, PS_78, PS_79, PS_80,
    useEffect(() => {
        sessionTokenProperties();
        resetTimer();
        fetchData();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };


    }, [sortBy, sortHide, sortOrder, reloadMigration]);


    let resetTimer = async () => {
        try {
            // debugger;
            //console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            //console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            //console.log("Reset Timer End");
        }
        catch (error) {
            //console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoading(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            //console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                //console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    //console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    //console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                //console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                //console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                //console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    //console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                //console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            //console.log("Reset Session Timer Catch");
            throw ex
        }
    };


    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            throw error
        }
    }


    useEffect(() => {
        setMinTime(dateInput === new Date().toISOString().split('T')[0] ? getCurrentTime() : '00:00');
    }, [dateInput]);

    // PS_29, PS_30, PS_31, PS_32, PS_33
    const fetchData = async () => {
        setLoading(true); // Set loading state to true
        try {
            let token = await getToken()

            switch (applicationName) {
                case 'outlook': {
                    let payload: any = {
                        taskId: `${taskId}`,
                        token: token,
                        applicationName: `${applicationName}`,
                        searchValue: searchTerm,
                        sortOrder: sortOrder,
                        sortBy: sortBy,
                        Action: "migration",
                    }
                    const mappedMailboxes: any = await getMappedMailboxes(payload);

                    // PS_37, PS_38, PS_39, PS_40
                    setSelectAll(false)

                    const newData = mappedMailboxes.data.data.map((item: any) => ({
                        ...item,
                        checked: false,
                    }));

                    setData(newData);
                    settotalDataCount(mappedMailboxes.data.data.length)

                    break;
                }
                default: {
                    break;
                }
            }

        } catch (error: any) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Set loading state to false when done fetching
        }
    };

    //PS_38
    const getHeaderText = (applicationName: any) => {
        switch (applicationName) {
            case 'outlook':
                return 'User Mailbox';
            case 'groups':
                return 'Group';
            case 'roomsandequipments':
                return "Rooms & Equipment's";
            case 'onedrive':
                return 'OneDrive';
            case 'sharedmailbox':
                return 'Shared Mailbox';
            case 'sharepoint':
                return 'Mapped Sites';
            case 'teamschat':
                return 'Mapped Teams Chat';
            case 'teamschannel':
                return 'Mapped Teams Channel';
            default:
                return null;
        }
    };

    // PS_41
    // PS_42
    const handleSearch = (event: any) => {
        debugger;
        setSearchTerm(event.target.value);
    };

    // PS_81, PS_82, PS_83, 
    const handleSearchClick = () => {
        const trimmedSearchTerm = searchTerm.trim();
        if (searchTerm.length === 0) {
            fetchData();
        }
        else if (searchTerm.length !== 0 && trimmedSearchTerm.length !== 0) {
            fetchData();
        }
    };

    //PS_84, PS_85, PS_86, PS_87,
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            const trimmedSearchTerm = searchTerm.trim();
            if (searchTerm.length === 0) {
                fetchData();
            }
            else if (searchTerm.length !== 0 && trimmedSearchTerm.length !== 0) {
                fetchData();
            }
        }
    };

    //  PS_88, PS_89, PS_90, PS_91
    const handleSort = (e: any) => {
        try {
            debugger;
            let sortID = e.target.id;
            let sortval = sortID.split("@");
            setSortBy(sortval[0]);
            setSortOrder(sortval[1]);
            setSortHide(sortID);
            //setSearchValue("");
        } catch (e: any) {
            //console.log(e.message);
        }
    };

    // PS_101, PS_102, PS_103, PS_104, PS_105, PS_106, PS_107, PS_108, PS_109, PS_110, PS_111,
    const generateTimeOptions = () => {
        let options = [];
        options.push(<option key="placeholder" value="" disabled selected>hh:mm</option>); // Placeholder option

        const now = new Date();
        const currentHour = now.getUTCHours();
        const currentMinute = now.getUTCMinutes();

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 60) {
                let hh = hour.toString().padStart(2, '0');
                let mm = minute.toString().padStart(2, '0');
                let time = `${hh}:${mm}`;
                if (dateInput === new Date().toISOString().split('T')[0]) {
                    if (hour < currentHour || (hour === currentHour && minute < currentMinute)) {
                        options.push(<option key={time} value={time} disabled>{time}</option>);
                    } else {
                        options.push(<option key={time} value={time}>{time}</option>);
                    }
                } else {
                    options.push(<option key={time} value={time}>{time}</option>);
                }
            }
        }
        return options;
    };

    const timeOptions: any = generateTimeOptions();


    // PS_112, PS_113, PS_114
    const handleDateChange = (e: any) => {
        setDateInput(e.target.value);
        setMinTime(e.target.value === new Date().toISOString().split('T')[0] ? getCurrentTime() : '00:00');
    };
    // PS_115, PS_116, PS_117
    const getCurrentTime: any = () => {
        const now = new Date();
        const currentHour = now.getUTCHours().toString().padStart(2, '0');
        const currentMinute = now.getUTCMinutes().toString().padStart(2, '0');
        return `${currentHour}:${currentMinute}`;
    };


    // PS_118, PS_119, PS_120,
    const handleTimeChange = (e: any) => {
        setTimeInput(e.target.value);

    }
    // PS_121, PS_122
    const handleSelectAllChange = () => {
        const newData: any = data.map((item: any) => ({
            ...item,
            checked: !selectAll,
        }));
        //console.log(data, "data in seal");
        setData(newData);
        setSelectAll(!selectAll);
    };

    // PS_85, PS_86, PS_87, PS_88, PS_89, PS_90, PS_91
    const handleStartMigration: any = async () => {
        // Extract the IDs of the selected rows
        setLoading(true);
        try {
            let token = await getToken()

            switch (applicationName) {
                case "outlook": {
                    const mappingIds: any = data
                        .filter((row: { checked: any; }) => row.checked)
                        .map((row: { userMigrationMappingId: any; }) => ({ mappingId: row.userMigrationMappingId }));

                    // //console.log(data)

                    // const mappingIds: string[] = data
                    // .filter((obj: any) => obj.checked === true)
                    // .map((obj: any) => obj.userMappingId);

                    //console.log(mappingIds, "can you see meeeeeeeeeeeeeeeeeeee")

                    let response: any = await startOutlookMigration({
                        taskId: taskId,
                        token: token,
                        id: mappingIds,
                        Action: "select",
                        applicationName: applicationName,
                        reqFrom: "Default"
                    });

                    if (response.data.data.message === 'Upgrade your plan to get more licenses.') {
                        setAlertpopup(true);
                    } else {
                        // Logic to start migration
                        setSelectAll(false)

                        fetchData()

                        // setManageMigration(true);
                        onSelectMigrationSave()

                        setLoading(false);

                        fetchDataInManageMigration()
                    }

                    break;
                }
                default: {
                    break;
                }
            }
        }
        catch (error) {
            //console.log(error, "this is the error");
        }
        finally {
            setLoading(false);
        }

    };

    //PS_126, PS_127, PS_128, PS_129, PS_130, PS_131, PS_132, PS_133, PS_134, PS_135, PS_136, PS_137, PS_138, PS_139, PS_140, PS_141, PS_142, PS_143, PS_144, PS_145, PS_146, PS_147, PS_148
    const handleScheduleMigration: any = async () => {
        try {
            let token = await getToken()

            if (dateInput === "" || timeInput === "") {
                setScheduleMigrationDateTimeWarning('Provide the date and time')
                return
            }

            const dateTimeString = `${dateInput}T${timeInput}`;
            const dateTimeUTC = moment.utc(dateTimeString); // Parse as UTC

            const currentDateTimeUTC = moment.utc(); // Current UTC time

            // Check if selected date and time (in UTC) are greater than current date and time (in UTC)
            const isValid = dateTimeUTC.isAfter(currentDateTimeUTC);

            //console.log(isValid, "valid");

            if (!isValid) {
                setScheduleMigrationDateTimeWarning('Please select a future date & time')
                return
            }

            setScheduleMigrationDateTimeWarning('')
            setScheduleMigrationDateTime(false)

            setDateInput('')
            setTimeInput('')

            setLoading(true);

            switch (applicationName) {
                case "outlook": {
                    // Check if data is defined and not empty before filtering and mapping
                    const selectedIds: any[] = data && data.length > 0 ?
                        data
                            .filter((row: any) => row.checked)
                            .map((row: any) => ({ "mappingId": row.userMigrationMappingId }))
                        : [];

                    let result: any = await insertScheduleMigration({
                        taskId: taskId,
                        token: token,
                        Id: selectedIds,
                        Action: "schedule",
                        applicationName: applicationName,
                        scheduleMigrationDateTime: dateTimeUTC
                    });
            
                    if (result.data.statusMessage === 'Insuffecient License') {
                        setLoading(false);
                        setAlertpopup(true);
                    } else {
                        // Logic to start migration
                        setSelectAll(false);
                        fetchData();
                        onSelectMigrationSave();
                        setLoading(false);
                        fetchDataInManageMigration();
                    }
                    break;
                }

            }


        }
        catch (error) {
            //console.log(error);
        }

    };

    //PS_52
    const handleCheckboxChange = (index: any) => {
        debugger
        const newData: any = [...data];
        newData[index].checked = !newData[index].checked;
        //console.log(data);
        setData(newData);
        let isSelectedAll = newData.every((obj: any) => obj['checked'] === true);
        //console.log(isSelectedAll, "is that all we got")
        setSelectAll(isSelectedAll)
    };

    //PS_72, PS_73, PS_74
    const handleScheduleCancel = () => {
        setDateInput('');
        setTimeInput('');
        setScheduleMigrationDateTimeWarning('')
        setScheduleMigrationDateTime(false);
    };

    //PS_75, PS_76, PS_77

    const handleScheduleClose = () => {
        setDateInput('');
        setTimeInput('');
        setScheduleMigrationDateTime(false);
        setScheduleMigrationDateTimeWarning('')
    };

    const isTimeInputLessThanCurrentTime = () => {
        if (dateInput === new Date().toISOString().split('T')[0]) {
            const currentTime = getCurrentTime();
            return timeInput.localeCompare(currentTime) < 0; // Compare time strings
        }
        return false; // Allow save button if date is not current date
    };

    const checkScheduledDate = () => {
        if (dateInput === new Date().toISOString().split('T')[0]) {
            const currentTime = getCurrentTime();
            return timeInput.localeCompare(currentTime) < 0; // Compare time strings
        }
        return false; // Allow save button if date is not current date
    };


    return (
        <div className="table-responsive theme-table bg-white mb-3">
            <table className="table table-borderless mb-0">
                <thead>
                    <tr>
                        <th className="text-nowrap table-accordion-head">
                            <span className="d-flex align-items-center gap-3">
                                <img
                                    src="/images/table-accordion-arrow.svg"
                                    alt="table-accordion-arrow"
                                    className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#avaZeb"
                                    onClick={onToggle}
                                />
                                <span className="ac-heading" onClick={onToggle}>
                                    Migration
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={`collapse ${isOpen ? "show" : ""}`} id="avaZeb">
                        <td colSpan={2}>
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                <h5 className="font-18 font-semibold mb-0 ">
                                    {getHeaderText(applicationName) && (
                                        <>
                                            {getHeaderText(applicationName)} (<span>{totalDataCount}</span>)
                                        </>
                                    )}
                                </h5>
                                <span className="d-flex gap-3 flex-wrap align-items-center">
                                    <span className="d-flex gap-3 align-items-center">
                                        <span className="input-group input-search flex-nowrap">
                                            <input
                                                type="search"
                                                className="form-control border-end-0 font-14 font-regular w-auto"
                                                placeholder="Search"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                                onKeyDown={(event) => { handleKeyDown(event) }}
                                            />
                                            <button className="btn border-start-0 d-flex align-items-center" type="button">
                                                <img src="/images/search.svg" alt="search" className="d-block "
                                                    onClick={handleSearchClick}
                                                />
                                            </button>
                                        </span>

                                        {/* {applicationName?.toLowerCase()?.trim() === 'groups' ?
                                            <><label className="font-14 font-semibold label-color text-nowrap ">Group Type</label>
                                                <select
                                                    name="groupType"
                                                    id="groupType"
                                                    className="form-select w-100 h-100 m-0 font-14 font-regular"
                                                >
                                                    <option value="All" selected="">
                                                        All
                                                    </option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>Lorem, ipsum.</option>
                                                    <option value={3}>3</option>
                                                </select></> : null} */}
                                    </span>
                                    <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                        onClick={() => { setScheduleMigrationDateTime(true) }}
                                        disabled={!(data.some((obj: { checked: boolean; }) => obj.checked === true)) || role === 'viewer'}
                                    // disabled={!(data.some(obj => obj.checked === true)) || role === 'viewer'}
                                    >
                                        Schedule Migration
                                    </button>
                                    <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                        onClick={handleStartMigration}
                                        disabled={!(data.some((obj: { checked: boolean; }) => obj.checked === true)) || role === 'viewer'}
                                    >
                                        Start Migration
                                    </button>
                                </span>
                            </div>
                            <div className="table-responsive theme-table bg-white d-inline-block w-100 custom-scroll table-y-scroll">
                                <table className="table table-borderless mb-0">
                                    <thead className="sticky-top">
                                        <tr>
                                            <th>
                                                <input
                                                    className="form-check-input cus-check-input"
                                                    type="checkbox"
                                                    onClick={() => { data.length === selectedRows.length ? setSelectedRows([]) : setSelectedRows(data) }}
                                                    checked={selectAll && data.length > 0}
                                                    onChange={handleSelectAllChange}
                                                    disabled={true ? role === 'viewer' : false}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </th>
                                            {
                                                obj && obj[applicationName.toLocaleLowerCase().trim()] && obj[applicationName.toLocaleLowerCase().trim()].map(({ header = '', value = '', sort = '' }: { header: string, value: string, sort: any }, index: number) => (
                                                    <th key={index} className="text-nowrap">
                                                        {header}
                                                        <span className="ms-1 cursor-pointer">
                                                            <img
                                                                src={`/images/sort-arrow-up.svg`}
                                                                alt="sort-arrow-up"
                                                                id={`${sort}@ASC`}
                                                                style={{ cursor: 'pointer', display: sortHide === `${sort}@ASC` ? 'inline' : 'none' }}
                                                                onClick={(e) => handleSort(e)}
                                                            />
                                                            <img
                                                                src={`/images/sort-arrow-down.svg`}
                                                                alt="sort-arrow-down"
                                                                id={`${sort}@DESC`}
                                                                style={{ cursor: 'pointer', display: sortHide === `${sort}@DESC` ? 'none' : 'inline' }}
                                                                onClick={(e) => handleSort(e)}
                                                            />
                                                        </span>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.length > 0 ? (
                                            data?.map((user: any, index: any) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input className="form-check-input cus-check-input" type="checkbox"
                                                            checked={user.checked}
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    index
                                                                )
                                                            }
                                                            disabled={true ? role === 'viewer' : false}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </td>

                                                    {

                                                        obj[applicationName.toLowerCase().trim()].map(({ header = '', value = '' }: { header: string, value: string }, index: number) => {
                                                            const userValue: any = user[value];

                                                            return (
                                                                <>
                                                                    {(userValue && userValue.length > 21) ? (
                                                                        <td style={{ textTransform: value.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                                                            <span
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="right"
                                                                                data-bs-title="Type Team Site"
                                                                                data-bs-original-title=""
                                                                                title={userValue}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                {userValue.slice(0, 21) + '...'}
                                                                            </span>
                                                                        </td>
                                                                    ) : (
                                                                        <td style={{ textTransform: value.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                                                            {userValue}
                                                                        </td>
                                                                    )}
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className="text-center">
                                                        <div className="pt-4 pb-3 text-center">
                                                            <img src="/images/no-data-found-img.svg" alt="no-data-found-img" className="mb-3" />
                                                            <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                            <p className="font-10 font-regular color-no-rec">There is no data to show you right now</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {loading && (
                <div className="container-fluid">
                    <div className="overlay">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <div
                                className="spinner-border Loader loader-color align-center "
                                role="status"
                            >
                                <span>
                                    <span className="loader-inner-circle"></span>
                                </span>
                            </div>
                            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                Loading..
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/*Schedule migration starts here */}
            {scheduleMigrationDateTime ? (
                <div className="modal fade show" tabIndex={-1} style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4">
                            <div className="modal-header border-0 p-0">
                                <h1 className="font-20 font-bold custom-header-color m-0" id="scheduleMigrationLabel">Schedule Migration</h1>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={handleScheduleClose} />
                            </div>
                            <div className="modal-body border-0 p-0 pt-2 pb-2">
                                <div className="row mt-2">
                                    <span className="font-16 popup-data d-block font-regular mt-2 mb-3">If any migration is in progress, the scheduled migration will be in queue.</span>
                                    <div className="col-md-6 col-12 position-relative">
                                        <label className="font-14 font-semibold label-color w-100 mb-2" htmlFor="date">Date</label>
                                        <input type="date" className="form-control custom-form font-14 font-regular cust-date position-relative" id="date" value={dateInput} min={new Date().toISOString().split('T')[0]} onChange={handleDateChange} />
                                        <img src="/images/date-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>
                                    </div>
                                    <div className="col-md-6 col-12 position-relative">
                                        <label className="font-14 font-semibold label-color w-100 mb-2" htmlFor="time">Time (UTC)</label>
                                        <select className="form-control custom-form font-14 font-regular cust-time position-relative" id="time" value={timeInput} onChange={handleTimeChange}>
                                            {timeOptions}
                                        </select>
                                        <img src="/images/time-icon.svg" alt="date-icon" className="position-absolute custom-input-icon"></img>
                                    </div>
                                </div>
                            </div>
                            <span style={{ color: 'red' }}>{scheduleMigrationDateTimeWarning}</span>
                            <div className="modal-footer border-0 p-0 mt-3">
                                <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" onClick={handleScheduleCancel}>Cancel</button>
                                <button type="button" className="btn primary-btn" onClick={() => { handleScheduleMigration() }} disabled={true ? dateInput === '' || timeInput === '' || isTimeInputLessThanCurrentTime() || scheduleMigrationDateTimeWarning !== "" : false}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/*Schedule migration popup ends here */}

            {/*Failure Popup starts here */}
            {alertpopup ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                Alert
                            </h5>
                            <p className="primary-data">
                                You don't have enough license, Please <a href="" style={{ textDecoration: 'none', cursor: 'pointer' }}>upgrade plan</a> to proceed further.
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"

                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlertpopup(false)
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}
        </div>
    );
};

export default SelectMigration;