
// ViewMigrationGrid.tsx
import React, { useState, useEffect } from 'react';
import { BE_URL, commonErrorMsgs } from '../../Common/Constant';
// import { getAllOutlook } from './MigrationReportServices'; // Update the correct import path
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { followCursor } from 'tippy.js';
// import { Tooltip } from 'react-tippy';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import { ViewMigrationGridProps, sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import objCryptoJS from "crypto-js";
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { getOutlookData, updateMailUserAction } from '../../Services/MigrationReportServices';

const ViewMigrationGrid: React.FC<ViewMigrationGridProps> = ({
  searchClick,
  filterClick,
  taskId,
  taskName
}) => {
  /**PS_40 to PS_70 will be executed*/
  const history = useHistory()
  const location: any = useLocation()?.state
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [sortBy, setSortBy] = useState("ud_source.displayName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');
  //   const location :any = useLocation()?.location
  const [imageSource, setImageSource] = useState("");
  const [page, setPage] = useState<number>(10);
  const [loader, setLoader] = useState<boolean>(false);
  const [sortHide, setSortHide] = useState<string>('');
  const [getOutlook, setGetOutlook] = useState<Array<any>>([]);
  const [getData, setGetData] = useState<any[]>([]); // Set type to expected data structure
  //   const [applicationName, setApplicationName] = useState(applicationName || ""
  //   );
  const [applicationName, setApplicationName] = useState("outlook");
  // The outline of the outlook array structure to be used in the grid
  let bindingObject: any = {
    outlook: [
      {
        header: "Source Username",
        value: "SourceDisplayName",
        sort: "ud_source.displayName",
      },
      {
        header: "Target Username",
        value: "TargetDisplayName",
        sort: "ud_target.displayName",
      },
      {
        header: "Overall Progress",
        value: "totalCount",
        sort: "overallSummary.totalCount",
      },
      {
        header: "Overall Status",
        value: "statusText",
        sort: "umm.statusText",
      },
      {
        header: "Action",
        value: "Status",
        sort: null,
      },
    ],
    groups: [
      {
        header: "source Group Name",
        value: "sourceGroupName",
        sort: "sourceGroupName",
      },
      {
        header: "target Group Name",
        value: "targetGroupName",
        sort: "targetGroupName",
      },
      {
        header: "Overall Progress",
        value: "overAllProgress",
        sort: "overAllProgress",
      },
      {
        header: "Overall Status",
        value: "userMappingActions",
        sort: "userMappingActions",
      },
      {
        header: "Action",
        value: "action",
        sort: null,
      },
    ],
  };
  useEffect(() => {
    //console.log(applicationName, "hbjhjbnkjjhb");
    if (applicationName === "outlook") {
      setImageSource("/images/ms-outlook-logo.svg");
    } else if (applicationName === "sharedmailbox") {
      setImageSource("/images/shared-mailbox-icon.svg");
    } else if (applicationName === "roomsandequipments") {
      setImageSource("/images/rooms-n-equipment-icon.svg");
    } else if (applicationName === "teamschannel") {
      setImageSource("/images/teams-logo.svg");
    } else if (applicationName === "groups") {
      setImageSource("/images/user-groups.svg");
    }

    sessionTokenProperties()
    fetchData()
    if (!isFetching) {
      const intervalId = setInterval(() => {
        fetchData()
      }, 10000); 
      //const intervalId = setInterval(fetchDataToBind("initiaload"), 5000); // Call every 5 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount

    }
  }, [sortBy, sortOrder, sortHide, page, filterClick, searchClick]);



  // TODO: Code needed to render the table/grid with the data processed from the WebSocket
  // See steps 1.22 to 1.38 for the actual logic to be run on page load and component rendering
  const getStatusLabel = (action: any) => {
    switch (action) {
      case "0" || null:
        return "Warning";
      case "1":
        return "Paused";
      case "2":
        return "Canceled";
      case "3":
        return "Scheduled";
      case "4":
        return "Queued";
      case "5":
        return "In Progress";
      case "6":
        return "Completed";
      case "7":
        return "Failed";
      default:
        return "Unknown";
    }
  };
  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          setEmailAddress(tokenResponse.clientDetails.Email);
          setRole(tokenResponse.clientDetails.Role);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  async function fetchData() {
    try {
      const token = await getToken()
      const payload = {
        token: token,
        taskId: taskId,
        status: filterClick!='' ?Number(filterClick) : 100,
        searchValue: searchClick.trim(),
        sortBy: sortBy,
        sortOrder: sortOrder,
        pageSize: page
      };
      const result: any = await getOutlookData(payload)
      if (result?.data?.statusCode == 200) {
        setTotalDataCount(result?.data?.data?.totalDataCount)
        setGetData(result?.data?.data?.bindingData)
      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }
    }
    catch (error) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  }
  /**PS_80 to PS_88 will be executed */

  function formatSize(sizeInBytes: number): string {
    let sizeInBytesProper: number = sizeInBytes * 1024 * 1024 * 1024;

    const units: string[] = ["B", "KB", "MB", "GB", "TB"];
    let size: number = sizeInBytesProper;
    let unitIndex: number = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    let formattedSize: string = size.toFixed(2);

    let unit: string = units[unitIndex];

    return `${formattedSize} ${units[unitIndex]}`;
  }


  async function handleActionButtonClick(item: any, action: any, isAdmin = false) {
    // debugger
    //console.log("handle user action", item, action, isAdmin)
    try {
      let token = await getToken();

      switch (applicationName) {
        case 'outlook': {
          if (action === '2' && action === '3' && item?.scheduleMigrationDateTime && !isAdmin) {
            //console.log("Hello there");
            let currentDateTimeUTC = moment.utc(); // Current UTC time
            //console.log("🚀 ~ item?.scheduleMigrationDateTime:", item?.scheduleMigrationDateTime)
            //console.log("🚀 ~ handleActionButtonClick ~ currentDateTimeUTC:", currentDateTimeUTC)

            // Convert scheduleMigrationDateTime to moment object if it's not already
            let scheduledTime = moment.utc(item.scheduleMigrationDateTime);

            // Check if the difference is within a tolerance (e.g., 5 seconds)
            if (scheduledTime.diff(currentDateTimeUTC, 'minutes') <= 5) {
              setLoader(false)
              setAlert(true);
              setAlertMessage("Cannot perform the action, migration is about to begin...");
            }
            else {
              //console.log('lets send api')
              let payload = {
                taskId:taskId,
                token: token,
                id: item.sourceMailId,
                applicationName: `${applicationName}`,
                data: item,
                Action: action,
                isAdmin: isAdmin,
                statusText:getStatusLabel(action)
              }
              setLoader(true)
              const response: any = await updateMailUserAction(payload);
              //console.log("mappedMailboxes", response);
              if (response.data?.Status_code === 409) {
                setAlert(true)
                setAlertMessage(response.data.message)
                fetchData()
                setLoader(false)
              }
              else{
                fetchData()
                setLoader(false)
              }
            }
          }
          else {
            //console.log('lets send api')
            let payload = {
              taskId:taskId,
              token: token,
              id: item.sourceMailId,
              applicationName: `${applicationName}`,
              data: item,
              Action: action,
              isAdmin: isAdmin,
              statusText:getStatusLabel(action)
            }
            setLoader(true)
            const response: any = await updateMailUserAction(payload);
            //console.log("mappedMailboxes", response);
            if (response.data?.Status_code === 409) {
              setLoader(false)
              setAlert(true)
              setAlertMessage(response.data.message)
              fetchData()
            }
            else{
              fetchData()
              setLoader(false)
            }
          }
          break;
        }
        default: {
          break;
        }
      }

    }
    catch (error) {
      console.error("Error updating user Action data:", error);
    } finally {
      // setLoading(false)
    }
  }
  /*8PS_135 to 
PS_139 will be executed*/

  const handleSort = (e: any) => {
    try {
      debugger;
      let sortID: string = (e.target as HTMLElement).id;
      let sortval: string[] = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
      //setSearchValue("");
    } catch (e: any) {
      //console.log(e.message);
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div className="table-responsive theme-table bg-white ">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              {bindingObject[applicationName.toLowerCase().trim()].map(
                ({ header, sort }: { header: string; sort: string }, index: number) => (
                  <th
                    key={index}
                    className={`text-nowrap ${header === "Action" ? "text-center" : ""
                      }`}
                  >
                    {header}
                    {header !== "Action" && (
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: "pointer" }}
                          id={`${sort}@ASC`}
                          hidden={
                            sortHide === `${sort}@DESC` || "" ? false : true
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@DESC`}
                          style={{ cursor: "pointer" }}
                          hidden={
                            sortHide === `${sort}@DESC` || "" ? true : false
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                      </span>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {getData?.length > 0 ? (
              getData?.map((item, index) => (
                <tr key={index}>
                  {bindingObject[applicationName?.toLowerCase().trim()].map(
                    ({ header, value }: { header: string; value: string }, index: number) => {
                      if (Object.is(value, "totalCount")) {
                        //console.log(item["totalSum"], item["totalCount"], "tetetetetetetetetetetetetete")
                        return (
                          <td className="d-flex align-items-right justify-content-right">
                            {item["totalSum"]}/{item["totalCount"]}
                            <Tippy
                              delay={500}
                              arrow={true}
                              plugins={[followCursor]}
                              placement="right"
                              content={
                                <div
                                  style={{
                                    color: "white",
                                    padding: "5px",
                                  }}
                                >
                                  Count {item["totalSum"]}/
                                  {item["totalCount"] !== undefined
                                    ? item["totalCount"]
                                    : 0}{" "}
                                  <br />
                                  Size{" "}
                                  {formatSize(
                                    parseFloat(item["totalSizeAppDetails"])
                                  )}
                                  /{formatSize(parseFloat(item["totalSize"]))}{" "}
                                </div>
                              }
                            >
                              <img
                                src="/images/tooltip.svg"
                                alt="tooltip"
                                className="ms-1 mb-1"
                                data-bs-placement="right"
                                width={11}
                              />
                            </Tippy>
                          </td>
                        );
                      }
                      // if (Object.is(value, "Status")) {
                      //   return (
                      //     <td>
                      //       <span className="text-nowrap">
                      //         <span
                      //           className={`table-status ${
                      //             item[value] === "0" || item[value] === null
                      //               ? "warning"
                      //               : item[value] === "1"
                      //               ? "paused"
                      //               : item[value] === "2"
                      //               ? "danger"
                      //               : item[value] === "3"
                      //               ? "scheduled"
                      //               : item[value] === "4"
                      //               ? "draft"
                      //               : item[value] === "5"
                      //               ? "warning"
                      //               : item[value] === "6"
                      //               ? "success"
                      //               : item[value] === "7"
                      //               ? "danger"
                      //               : "warning"
                      //           } d-inline-block me-2`}
                      //         ></span>
                      //         {getStatusLabel(item[value])}
                      //       </span>{" "}
                      //     </td>
                      //   );
                      // }
                      if (Object.is(value, "Status")) {
                        //console.log(item[value], "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");

                        return (
                          <td className="d-flex align-items-center justify-content-center">
                            <span className="d-inline-flex gap-3">
                              <button
                                disabled={
                                  role?.toLowerCase() === "viewer" ||
                                  adminrole?.toLowerCase() === "adminviewer"
                                }
                                className="btn action-rounded-btn view-action d-flex align-items-center justify-content-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="View"
                                title="View"
                                onClick={() => {
                                  history.push("/MigrationReport", {
                                    state: {
                                      taskId: taskId,
                                      taskName: taskName,
                                      userMappingId: item.userMigrationMappingId,
                                      sourceDisplayName: item.SourceDisplayName,
                                      targetDisplayName: item.TargetDisplayName,
                                      sourceMailId: item.SourceMailID,
                                      targetMailId: item.TargetMailID,
                                      applicationName: applicationName,
                                    },
                                  });
                                }}
                              >
                                <img
                                  src="/images/view-icon-filled.svg"
                                  alt="View"
                                />
                              </button>
                              {(item[value] == "1" || item[value] == "5") && adminrole.toLowerCase().includes("primary") ? (
                                <button
                                  className="btn action-rounded-btn cancel-action d-flex align-items-center justify-content-center"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  data-bs-title="Cancel"
                                  title="Cancel"
                                  onClick={() =>
                                    handleActionButtonClick(item, "2")
                                  }
                                >
                                  <img
                                    src="/images/cancel-action-icon.svg"
                                    alt="Cancel"
                                  />
                                </button>
                              )
                                :
                                null}
                              {item[value] == "6"
                                ? (<div></div>)
                                : (
                                  <button
                                    disabled={
                                      role?.toLowerCase() === "viewer"
                                    }
                                    className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="Pause"
                                    title="Pause"
                                    onClick={() =>
                                      handleActionButtonClick(
                                        item,
                                        (item[value] == "3" || item[value] == "4" ) ? "2"
                                          : (item[value] == "1" || item[value] == "2" || item[value] == "7" || item[value] == "0" ? '5' : '1')
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        item[value] == "3" ||
                                          item[value] == "4" ||
                                          item[value] == "0"
                                          ? "/images/refresh-icon-filled.svg"
                                          : item[value] == "1"
                                            ? "/images/play-icon-filled.svg"
                                            : item[value] == "2" ||
                                              item[value] == "7"
                                              ? "/images/refresh-icon-filled.svg"
                                              : "/images/pause-icon.svg"
                                      }
                                      alt="pause"
                                    />
                                  </button>
                                )}
                            </span>
                          </td>
                        );
                      }
                      return (
                        <td
                          style={{
                            textTransform:
                              header?.toLowerCase() === "resourcetype"
                                ? "capitalize"
                                : "none",
                          }}
                        >
                          {item[value]?.length > 21 ? (
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title={item[value]}
                            >
                              {item[value].slice(0, 21) + "..."}
                            </span>
                          ) : (
                            item[value]
                          )}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">
                        No Records Found
                      </h5>
                      <p className="font-10 font-regular color-no-rec">
                        No Matching Results Found
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div></div>
      <p className="text-start mb-3" style={{ color: 'black' }}>
        # Records of :<span style={{ fontWeight: 'bold' }}>{getData.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalDataCount}</span>
      </p>
      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn primary-outline-btn font-14 font-semibold"
          onClick={() => {
            setPage(page + 10);
          }}
          hidden={totalDataCount < page}>
          Load More
        </button>
      </div>
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => { setAlert(false) }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
    </>);
};

export default ViewMigrationGrid;