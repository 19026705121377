/*  */// import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { useLocation, useHistory} from "react-router-dom";
// import AppSpecificConfigurations from './AppSpecificConfigurations';

import { useState } from "react";
import AppSpecificConfigurations from "./AppSpecificConfigurations"
import UserMapping from "./UserMapping";
import { sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { useHistory, useLocation } from "react-router";
import objCryptoJS from "crypto-js";
import { useEffect } from "react";
import MasterMigConfiguration from "./migMasterComponent";
import GoliveConfigurations from "./GoLiveConfigurations";
import { fetchApplicationNames } from "../../Services/assessmentGridService";
import { insertLastVisitedPageValueApi } from "../../Services/MigrationAndManageMigrationServices";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";
import { getConfigData } from "../../Services/psConfigServices";
// // import UserMapping from './userMapping';
// // import GeneralConfigurations from './generalConfiguration'

// // import GoLiveConfigurations from './goLiveConfigurations';
// // import { fetchApplicationNames, insertLastVisitedPageValueApi } from '../../Services/GeneralConfigurationService/appSpecificConfigurationsApi';

const Configurations = () => {
  const history = useHistory()
  let location: any = useLocation().state;

  const stage = 3;
  const [taskId, setTaskId] = useState<any>(location?.state?.taskId || "");
  const [applicationName, setApplicationName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSource, setImageSource] = useState<string>("")
  const [channelMigrationPopUp, setChannelMigrationPopUp] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<any>(location?.state?.isEdit);
  const [isEditCheck, setIsEditCheck] = useState<boolean>(false);

  const [isDirectMigration, setIsDirectMigration] = useState<number>(0);

  const [isActualUser, setIsActualUser] = useState<number>(0);
  let lastVisitedPage = location?.state?.lastVisited;
  console.log(lastVisitedPage,"lastvisited");
  

  const [openAccordion, setOpenAccordion] = useState<any>(lastVisitedPage ? lastVisitedPage : 'generalconfigurations'); // State to track which accordion is open
  const [userMappingAccordion, setUserMappingAccordion] = useState<boolean>(false);
  const [goLiveAccordion, setGoLiveAccordion] = useState<boolean>(false);
  const [migrationAccordion, setMigrationAccordion] = useState<boolean>(false);

  const [enableUserMapping, setEnableUserMapping] = useState<boolean>(false)
  const [enableMapping, setEnableMapping] = useState<boolean>(false)


  const [goLiveSaveStatus, setGoLiveSaveStatus] = useState<boolean>(false);
  const [appSpecificConfigSaveStatus, setappSpecificConfigSaveStatus] = useState<boolean>(false);
  // let [clientId, setClientId] = useState<any>()
  const [generalConfigSaveStatus, setgeneralConfigSaveStatus] = useState<boolean>(false);
  const [userMappingSaveStatus, setuserMappingSaveStatus] = useState<boolean>(false);
  const [roleCheck, setRoleCheck] = useState<string>("")
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
  const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
  const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
  const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;

  const [accordionInteractions, setAccordionInteractions] = useState<any>({
    userMapping: false,
    generalConfigurations: false,
    appSpecificConfigurations: false,
    goLiveConfigurations: false
  });


  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchApplicationName();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, []);


async function getConfigurationData(){
try{
  setLoading(true)
  let token = await getToken()
  let payload={
    taskId:taskId,
    token:token
  }
  const result = await getConfigData(payload)
  setLoading(false)
  if(result?.data.message=='Success'){
    setEnableNextButton(true)
    setUserMappingAccordion(true);
  }
  else{
    if (generalConfigSaveStatus) {
      setEnableNextButton(true)
    }
    else{
      setEnableNextButton(false)
    }
  }
}
catch(error){
  setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
}
finally{
  setLoading(false)
}
  

}
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      //console.log("start of session token");

      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setRoleCheck(tokenResponse.adminDetails.Role)
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setRoleCheck(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //console.log("end of session token");

      //#endregion
    } catch (error) {
      // setAlert(true);
      // setLoader(false)

      console.error('Failed in sesion Token', error);
    }
  };


  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      //console.log(" start of get token");

      if (objClient !== undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin !== undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      //console.log("end of get token");

      return token
    } catch (error) {
      // setAlert(true);
      // setLoader(false)
      //console.log("error in getToken");

      throw error
    }
  }


  const fetchApplicationName: any = async () => {
    try {
      setLoading(true);
      let token = await getToken()

      const applicationNames: any = await fetchApplicationNames({
        token: token,
        taskId,
      });

      //console.log(applicationNames.data.data[0].applicationName, "This is the application name");

      setApplicationName(applicationNames.data.data[0].applicationName);

      if (applicationNames.data.data[0].applicationName === "outlook") {
        setImageSource("/images/ms-outlook-logo.svg")
      } else if (applicationNames.data.data[0].applicationName === "sharedmailbox") {
        setImageSource("/images/shared-mailbox-icon.svg")
      } else if (applicationNames.data.data[0].applicationName === "roomsandequipments") {
        setImageSource("/images/rooms-n-equipment-icon.svg")
      } else if (applicationNames.data.data[0].applicationName === "teamschannel") {
        setImageSource("/images/teams-logo.svg")
      } else if (applicationNames.data.data[0].applicationName === "groups") {
        setImageSource("/images/user-groups.svg")
      }

    } catch (error: any) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionToggle = (accordionName: any) => {
    debugger
    setOpenAccordion(accordionName === openAccordion ? null : accordionName);
    // setOpenAccordion(accordionName)
  };

  const enableUserMappingToggle = () => {
    setEnableUserMapping(true);
  };

  const handleUserMappingToggle = () => {
    setUserMappingAccordion(true);
  };

  const handleGoliveAndMigrationToggle = () => {
    setMigrationAccordion(true)
    setGoLiveAccordion(true)
  };

  const handleGoLiveSaveStatusUpdate = (status: any) => {
    //console.log(goLiveSaveStatus, "this si go live");
    setGoLiveSaveStatus(status);
  };

  const handleAppSpecificConfigSaveStatusUpdate = (status: any) => {
    setappSpecificConfigSaveStatus(status);
    //console.log(appSpecificConfigSaveStatus, "this is app live");

  };

  const handleGeneralConfigSaveStatusUpdate = (status: any) => {
    setgeneralConfigSaveStatus(status);
    //console.log(generalConfigSaveStatus, "this si general live");
  };

  const handleUserMappingSaveStatusUpdate = (status: any) => {
    setuserMappingSaveStatus(status);
    //console.log(userMappingSaveStatus, "this si mapping live");
  };

  const insertLastVisitedPageValue = async (page: string) => {
    try {
      // console.log(page, "This is the last inserted pageeeee");

      let token = await getToken()

      const payload = {
        token: token,
        taskId: taskId,
        lastVisitedValue: page
      };

      //console.log(payload, 'last visited');

      // Insert only newly entered records into the database
      const response: any = await insertLastVisitedPageValueApi(payload);

      if (!response) {
        //console.log('The current page value failed to insert');
      } else {
        //console.log('The current page value inserted successfully');

      }
    } catch (error) {
      console.error('Error inserting records:', error);
    }
  };

  // async function selectMigration() {
  //   try {
  //     let token = await getToken()

  //     const payload = {
  //       token: token,
  //       taskId: taskId,
  //       lastVisitedValue: 'Migration'
  //     };

  //     //console.log(payload, 'last visited');

  //     // Insert only newly entered records into the database
  //     const response: any = await insertLastVisitedPageValueApi(payload);

  //     if (!response) {
  //       //console.log('The current page value failed to insert');
  //     } else {
  //       //console.log('The current page value inserted successfully');

  //     }

  //     history.push("/Migration"
  //       , {
  //         state: {
  //           ...location.state,
  //           taskId: taskId,
  //           taskName: location?.state?.taskName,
  //           applicationName: applicationName
  //         }
  //       }
  //     )
  //   }
  //   catch (error) {
  //     console.error('Error inserting records:', error);
  //   }

  // }

  useEffect(() => {
    getConfigurationData()
  }, [generalConfigSaveStatus,userMappingSaveStatus]);

  //console.log(" ", isEditCheck)

  //console.log("location -  ", location)

  return (
    <div className="container-fluid container-height">
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a
                  className="pe-0"
                  onClick={() => {
                    history.push("/task");
                  }}
                >
                  Task
                </a>
              </li>
              <li className="pe-2">{location?.state?.taskName || ""}</li>
            </ul>
            {/* bread crumb ends here */}
            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 mt-2">
              <h5 className="primary-header mb-0 d-flex align-items-center">
                <a >
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    width={19}
                    style={{ cursor: 'pointer' }}
                    className="me-2"
                    onClick={
                      () => {
                        history.push("/task", {
                          state: {
                            taskId: location?.state?.taskId,
                            taskName: location?.state?.taskName,
                            applicationName: applicationName
                          }
                        })
                      }
                    }
                    hidden={false}
                  />
                </a>
                {location?.state?.taskName || ""} - Migration
                {/* User Migration icon */}
                <img
                  src={imageSource}
                  alt={""}
                  width={23}
                  className="ms-2"
                />
                {/* Team logo */}
                {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                {/* Sharepoint logo */}
                {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                {/* Outlook logo */}
                {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
              </h5>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card p-md-4 border-0 rounded-3">
                  <div className="row justify-content-center">
                    {/* Wizard structure starts here */}
                    <div className="col-md-7">
                      <div className="d-flex justify-content-between align-items-center step-wizard">
                        <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                          onClick={() => {
                            return history.push('/selectapps', {
                              state: {
                                ...location.state,
                                taskId: location.state.taskId,
                                taskName: location.state.taskName,
                                applicationName: location?.state?.applicationName,
                                isEdit: true,
                                pageType: 'selectapps',
                                taskType: 'migration',
                                stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage,

                              }
                            })

                          }
                          }>
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                          >
                            1
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                            Select App
                          </span>
                        </div>

                        <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                            onClick={() => {
                              history.push('/Connectors', {
                                state: {
                                  ...location?.state,
                                  // taskType: 'migration',
                                  isEdit: true,
                                  stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                }
                              });
                            }}
                          >
                            2
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                            onClick={() => {
                              history.push('/Connectors', {
                                state: {
                                  isEdit: true,
                                  ...location?.state,
                                  taskType: 'migration',
                                  stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                }
                              });
                            }}
                          >
                            Connectors
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                            3
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                            Configurations
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                          onClick={() => {
                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 4) {
                              location?.state?.taskType?.toLowerCase() == "assessment" ?
                                history.push("/Assessment", {
                                  state: {
                                    ...location.state,
                                    taskId: taskId,
                                    taskName: location?.state?.taskName,
                                    applicationName: location?.state?.applicationName,
                                    isEdit: true,
                                    stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                  },
                                })
                                :
                                history.push("/Migration", {
                                  state: {
                                    ...location.state,
                                    taskId: taskId,
                                    taskName: location?.state?.taskName,
                                    applicationName: location?.state?.applicationName,
                                    isEdit: true,
                                    stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                  },
                                })
                            }
                          }}>
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14">
                            4
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                            Migration
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Wizard structure ends here */}

                    <MasterMigConfiguration
                      taskId={taskId}
                      isOpen={openAccordion === 'generalconfigurations'}
                      // isOpen={location?.state?.lastVisited ? openAccordion.toLowerCase().includes('generalconfigurations') : true}
                      onToggle={() => handleAccordionToggle('generalconfigurations')}
                      onGeneralConfigSave={() => { handleAccordionToggle('userMapping') }}
                      updateGeneralConfigSaveStatus={handleGeneralConfigSaveStatusUpdate}
                      applicationName={applicationName}
                      onToggleAccordian={() => { handleUserMappingToggle() }}
                      isEdit={isEdit}
                      isEditCheck={isEditCheck}
                      setIsEditCheck={setIsEditCheck}
                      enableUserMappingToggle={enableUserMappingToggle}
                    />
                    <>
                      <UserMapping
                        taskId={location?.state?.taskId}
                        taskName={location?.state?.taskName}
                        roleCheck={roleCheck}
                        isOpen={openAccordion === 'userMapping'}
                        accordianOpen={userMappingAccordion}
                        onToggle={() => { handleAccordionToggle('userMapping') }}
                        onUserMappingSave={() => { handleAccordionToggle('appSpecificConfigurations') }}
                        updateUserMappingSaveStatus={handleUserMappingSaveStatusUpdate}
                        disabled={userMappingAccordion}
                        onToggleAccordian={() => { handleGoliveAndMigrationToggle() }}
                        isEdit={false}
                        isEditCheck={false}
                        enableUserMapping={enableUserMapping}
                      />



                      <AppSpecificConfigurations
                        taskId={location?.state?.taskId}
                        applicationName={"outlook"}
                        roleCheck={roleCheck}
                        isOpen={openAccordion === 'appSpecificConfigurations'}
                        onToggle={() => handleAccordionToggle('appSpecificConfigurations')}
                        onAppSpecificConfigSave={() => { handleAccordionToggle('goLiveConfigurations') }}
                        updateAppSpecificConfigSaveStatus={handleAppSpecificConfigSaveStatusUpdate}
                        disabled={migrationAccordion}
                        isEdit={isEdit}
                        isEditCheck={isEditCheck}
                      />
                    </>


                    <GoliveConfigurations
                      taskId={taskId}
                      applicationName={applicationName}
                      roleCheck={roleCheck}
                      isOpen={openAccordion === 'goLiveConfigurations'}
                      onToggle={() => handleAccordionToggle('goLiveConfigurations')}
                      onToggleAccordian={() => { handleUserMappingToggle() }}
                      updateGoLiveSaveStatus={handleGoLiveSaveStatusUpdate}
                      disabled={goLiveAccordion}
                      isEdit={isEdit}
                      isEditCheck={isEditCheck}


                    />

                    <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                      <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0 cursor-pointer"

                        onClick={() => {
                          // insertLastVisitedPageValue('Connectors');
                          history.push('/Connectors', { state: { isEdit: true, ...location.state } })
                        }}
                      >
                        <img src="/images/left-arw-blue.svg" alt="back" width={12} />
                        <span className="ms-1" >Back</span>
                      </button>
                      <div className="d-md-flex d-grid gap-3">
                        <button
                          onClick={() => {
                            insertLastVisitedPageValue('Configurations');
                            history.push("/task");
                          }}
                          className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0"
                          disabled={goLiveSaveStatus === false}
                        >
                          Cancel
                        </button>
                        <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                          disabled={!enableNextButton}
                          // disabled={!enableNextButton && !isEdit}
                          onClick={() => { insertLastVisitedPageValue('Selectmigration'); 
                             history.push("/Migration", {
                              state: {
                                ...location.state,
                                taskId: taskId,
                                taskName: location?.state?.taskName,
                                applicationName: applicationName
                              }
                            })
                          }}
                        // onClick={navigate("/Migration")}

                        >
                          Next
                          <img src="/images/right-arw.svg" alt="next" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="container-fluid">
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle"></span>
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        </div>
      )}


      {/* Migration popup starts here */}
      {channelMigrationPopUp ? <div
        className="modal fade show"

        tabIndex={-1}

        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="modal-header border-0 p-0">
              <h1
                className="font-20 font-bold custom-header-color m-0"
                id="migrationPopupLabel"
              >
                Migration
              </h1>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => { setChannelMigrationPopUp(false) }}
              />
            </div>
            <div className="modal-body border-0 p-0 pt-2 pb-5">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">
                    What kind of migration do you need ?
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-radio-input"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="DirectMigration"
                      defaultValue="option1"
                      onClick={() => { setIsDirectMigration(1) }} />
                    <label
                      className="form-check-label font-14 font-regular"
                      htmlFor="DirectMigration"
                    >
                      Direct Migration
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-radio-input"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="CustomMigration"
                      defaultValue="option2"
                    />
                    <label
                      className="form-check-label font-14 font-regular"
                      htmlFor="CustomMigration"
                      onClick={() => { setIsDirectMigration(0) }}>
                      Custom Migration
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <label
                    className="font-14 font-semibold label-color w-100 my-2 pt-3"
                    htmlFor="taskName"
                  >
                    For teams application, what kind of migration do you like to
                    proceed with?
                  </label>
                  <div className="d-block d-sm-block d-md-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="ServiceAccountMigration"
                        defaultValue="option1"
                        onClick={() => { setIsActualUser(0) }} />
                      <label
                        className="form-check-label font-14 font-regular"
                        htmlFor="ServiceAccountMigration"
                      >
                        Service Account Migration{" "}
                        <span className="d-block mt-1 font-12 color-grey-v3">
                          Migrate channels from Teams on behalf of Service Account
                          as MS Teams public or private channels.
                        </span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="actualusermigration"
                        defaultValue="option1"
                        onClick={() => { setIsActualUser(1) }} />
                      <label
                        className="form-check-label font-14 font-regular"
                        htmlFor="actualusermigration"
                      >
                        Actual User Migration
                        <span className="d-block mt-1 font-12 color-grey-v3">
                          Migrate channels from Teams on behalf of Actual users as
                          MS Teams public channels.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 p-0">
              <button
                type="button"
                className="btn secondary-btn"
                data-bs-dismiss="modal"
                onClick={() => { setChannelMigrationPopUp(false) }}>
                Cancel
              </button>
              <button type="button" className="btn primary-btn">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div> : <>
        {alert ? <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  { }
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }
                    setAlert('')
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div> : null}
        {/*Failure Popup ends here */}
      </>}
      {/* Migration popup ends here */}

    </div>

  )
}


export default Configurations