import { useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

export default function PageNotFound(): JSX.Element {

    useEffect(() => {
    }, [])

    return (
        <Router>

            <div className="container-fluid p-2 bg-white card-bg-img">
                <div className='centerallign'>
                    <h4 className="text-center contact-us font-bold font-24 mb-4">Page Not Found</h4>
                    <p className="text-center contact-us-para font-18 font-regular">Please provide the valid URL</p>
                </div>
            </div>


            <div id="tourFadeDiv" className="modal-backdrop fade show" hidden></div>

        </Router>
    );
}