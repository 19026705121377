import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { OutlookTypeGrid, invokeOutlookReportApi } from "../../Services/MigrationReportServices";
import Loader from "../Common/Loader";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";

interface MigrationTypeChildProps {
  searchClick: string;
  filterClick: string;
  applicationName: string;
  sourceMailId: string;
  targetMailId: string;
  totalCount: number;
}

const MigrationTypeChild: React.FC<MigrationTypeChildProps> = ({
  searchClick,
  filterClick,
  applicationName,
  sourceMailId,
  targetMailId,
  totalCount,
}) => {
  const location :any = useLocation()?.state;
  const [loading, setLoading] = useState<boolean>(false);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [taskId, setTaskId] = useState<string | undefined>(
    location?.state?.taskId
  );
  const [userMappingId, setUserMappingId] = useState<string | undefined>(
    location?.state?.userMappingId
  );
  const [sourceDisplayName, setSourceDisplayName] = useState<string | undefined>(
    location?.state?.sourceDisplayName
  );
  const [page, setPage] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>("migrationStartedAt");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [getMigrationTypeGrid, setgetMigrationTypeGrid] = useState<any[]>([]);
  const [sortHide, setSortHide] = useState<string>("");
  const [success, setsuccess] = useState<boolean>(false);
  const [failure, setfailure] = useState<boolean>(false);


  let bindingObject: any = {
    outlook: [
      {
        header: "Migration Type",
        value: "migrationType",
        sort: "migrationType",
      },
      {
        header: "Scheduled At",
        value: "scheduledAt",
        sort: "scheduledAt",
      },
      {
        header: "Migration Started At",
        value: "migrationStartedAt",
        sort: "migrationStartedAt",
      },
      {
        header: "Migration Ended At",
        value: "migrationEndedAt",
        sort: "migrationEndedAt",
      },
      {
        header: "Status",
        value: "Status",
        sort: "status",
      },
      {
        header: "Report",
        value: "report",
        sort: null, // Assuming report cannot be sorted
      },
    ],
  };

  useEffect(() => {
    fetchData();
    //console.log(filterClick, "gfchgvjhkhjfhgjhkj");
  }, [searchClick, filterClick, sortOrder, sortBy, page]);
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
        if (objClient != undefined) {
            let sessionToken: any = sessionStorage.getItem('c_at')
            if (sessionToken) {
                sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                token['clientToken'] = clientToken

            }


        }
        if (objAdmin != undefined) {
            let sessionToken: any = sessionStorage.getItem('a_at')
            if (sessionToken) {
                sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                token['adminToken']  = adminToken

            }
        }
        return token
    } catch (error) {
        throw error
    }
}
  const fetchData = async (): Promise<void> => {
    try {
        let token = await getToken()
      setLoading(true);
      debugger;
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        search: searchClick.trim(),
        filter: filterClick,
        sortBy: sortBy,
        sortOrder: sortOrder,
        pageSize: page,
        applicationName: "outlook",
      };
      let result = await OutlookTypeGrid(payload);
      if(result?.data?.statusCode==200){
        setgetMigrationTypeGrid(result?.data?.data?.final)
}
else{
setAlert(true)
setAlertMessage(commonErrorMsgs.generalError)
}
 
    } catch (error:any) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    } finally {
      setLoading(false);
    }
  };
/**PS_233 to 
PS_240 will be executed */
  const getStatusClass = (status: string): string => {
    //console.log(status, "status");
    switch (status.toLowerCase().trim()) {
      case "completed":
        return "table-status success d-inline-block me-2";
      case "scheduled":
        return "table-status scheduled d-inline-block me-2";
      case "in progress":
        return "table-status warning d-inline-block me-2";
      case "queued":
        return "table-status draft d-inline-block me-2";
      case "paused":
        return "table-status paused d-inline-block me-2";
      case "failed":
        return "table-status danger d-inline-block me-2";
      case "warning":
        return "table-status danger d-inline-block me-2";
      default:
        return `table-status ${status?.toLowerCase()} d-inline-block me-2`;
    }
  };

  const rendersuccesstoast = (): JSX.Element => {
    return (
      <>
        <div className="toast-container">
          <div
            className="toast show custom-toast-success"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <div className="d-flex p-3 pb-0 border-0 align-items-center">
              <img
                src="/images/success-toast-icon.svg"
                className="rounded me-2"
                alt="..."
              />
              <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                Success
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setsuccess(false)}
              />
            </div>
            <div className="toast-body px-5 pt-0">
              {`data has been imported`}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderfailuretoast = (): JSX.Element => {
    return (
      <>
        <div className="toast-container">
          <div
            className="toast show custom-toast-failure"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <div className="d-flex p-3 pb-0 border-0 align-items-center">
              <img
                src="/images/failure-toast-icon.svg"
                className="rounded me-2"
                alt="..."
              />
              <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                Failure
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setfailure(false)}
              />
            </div>
            <div className="toast-body px-5 pt-0">
              {`data not has been exported`}
            </div>
          </div>
        </div>
      </>
    );
  };
/**PS_161 to 
PS_194 will be executed */
  const invokeDownload = async (dal: any): Promise<void> => {
    setLoading(true);
    try {
      const token= await getToken()
      const sourceEmail: string = sourceMailId;
      const targetEmail: string = targetMailId;
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        migrationType: dal?.migrationType,
      };

      let data = await invokeOutlookReportApi(payload);

      const headers = [
        "Source Email Address",
        "Target Email Address",
        "Source Entity Type",
        "Source Entity Count",
        "Target Entity Type",
        "Target Entity Count",
      ];

      const mailHeaders = [
        "Source Email Address",
        "Target Email Address",
        "Source Mail Folder Name",
        "Target Mail Folder Name",
        "Source Mail ID",
        "Target Mail ID",
        "Error Log - Mail",
      ];

      const calendarHeaders = [
        "Source Email Address",
        "Target Email Address",
        "Source Calendar Folder Name",
        "Target Calendar Folder Name",
        "Source Event ID",
        "Target Event ID",
        "Error Log - Calendar",
      ];

      const contactHeaders = [
        "Source Email Address",
        "Target Email Address",
        "Source Contact Folder Name",
        "Target Contact Folder Name",
        "Source Contact ID",
        "Target Contact ID",
        "Error Log - Contacts",
      ];

      const todoHeaders = [
        "Source Email Address",
        "Target Email Address",
        "Source ToDo Folder Name",
        "Target ToDo Folder Name",
        "Source Task ID",
        "Target Task ID",
        "Error Log - ToDo",
      ];

      const entityTypes = [
        "Mail",
        "Mail Folder",
        "Calendar Folder",
        "CalendarEvent",
        "Contact Folder",
        "Contacts",
        "ToDo Folder",
        "Task",
      ];

      let dataArray :any = [headers];
      let transformedDataArray :any = [mailHeaders];
      let calendarArray : any  = [calendarHeaders];
      let contactArray : any  = [contactHeaders];
      let todoArray : any  = [todoHeaders];

   
      if(data?.data?.statusCode==200){
        if (data?.data?.data?.mail?.length > 0) {
          data?.data?.data?.mail?.forEach((item: any) => {
            const row = [
              sourceEmail,
              targetEmail,
              item.sourcefoldername,
              item.targetfoldername,
              item.sourcemessageid,
              item.targetmessageid,
              item.errorlog,
            ];
            transformedDataArray.push(row);
          });
        }
  
        if (data?.data?.data?.calendar?.length > 0) {
          data?.data?.data?.calendar?.forEach((item: any) => {
            const row = [
              sourceEmail,
              targetEmail,
              item.sourceFolderName,
              item.targetFolderName,
              item.sourceEventId,
              item.targetEventId,
              item.errorLog,
            ];
            calendarArray.push(row);
          });
        }
  
        if (data?.data?.data?.contacts?.length > 0) {
          data?.data?.data?.contacts?.forEach((item: any) => {
            const row = [
              sourceEmail,
              targetEmail,
              item.sourcefoldername,
              item.targetfoldername,
              item.sourcecontactid,
              item.targetcontactid,
              item.errorlog,
            ];
            contactArray.push(row);
          });
        }
  
        if (data?.data?.data?.todo?.length > 0) {
          data?.data?.data?.todo?.forEach((item: any) => {
            const row = [
              sourceEmail,
              targetEmail,
              item.sourcefoldername,
              item.targetfoldername,
              item.sourcetodoid,
              item.targettodoid,
              item.errorlog,
            ];
            todoArray.push(row);
          });
        }
  
        const entityTypeToKeyMap: any = {
          Mail: "MailCount",
          "Mail Folder": "MailFolderCount",
          "Calendar Folder": "CalendarFolderCount",
          Event: "CalendarEventCount",
          "Contact Folder": "ContactFolderCount",
          Contacts: "ContactsCount",
          "ToDo Folder": "ToDoFolderCount",
          Task: "TaskCount",
        };
  
  
        data?.data?.data?.Count?.forEach((item:any) => {
          entityTypes.forEach((entityType) => {
            const row = [
              sourceEmail,
              targetEmail,
              entityType,
              item[`source${entityType.replace(/\s/g, "")}Count`]|| 0,
              entityType,
              item[`target${entityType.replace(/\s/g, "")}Count`] || 0,
            ];
            dataArray.push(row);
          });
        });
  
        const workbook = XLSX.utils.book_new();
  
        const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
        XLSX.utils.book_append_sheet(workbook, worksheet, "overView");
  
        const worksheet1 = XLSX.utils.aoa_to_sheet(transformedDataArray);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Mail");
  
        const worksheet2 = XLSX.utils.aoa_to_sheet(calendarArray);
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Calendar");
  
        const worksheet3 = XLSX.utils.aoa_to_sheet(contactArray);
        XLSX.utils.book_append_sheet(workbook, worksheet3, "Contacts");
  
        const worksheet4 = XLSX.utils.aoa_to_sheet(todoArray);
        XLSX.utils.book_append_sheet(workbook, worksheet4, "ToDo");
  
        const filePath = `${sourceDisplayName}_${dal?.migrationType}_Report.xlsx`;
  
        XLSX.writeFile(workbook, filePath);
}
else{
setAlert(true)
setAlertMessage(commonErrorMsgs.generalError)
}
    } catch (error) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    } finally {
      setLoading(false);
    }
  };
/*PS_201 to 
PS_204 will be executed */
  const handleSort = (e: React.MouseEvent<HTMLImageElement>): void => {
    try {
      let sortID = e.currentTarget.id;
      let sortval = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
    } catch (e:any) {
      //console.log(e.message);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {success && rendersuccesstoast()}
      {failure && renderfailuretoast()}

      <div className="table-responsive theme-table bg-white">
        <table className="table table-borderless mb-0">
          <thead>
            <tr>
              {bindingObject[applicationName?.toLowerCase().trim()].map(
                ({ header, sort }: any, index: React.Key | null | undefined) => (
                  <th
                    key={index}
                    className={`text-nowrap ${
                      header === "Report" ? "text-center" : ""
                    }`}
                  >
                    {header}
                    {header?.toLowerCase() !== "report" && (
                      <span className="ms-1 cursor-pointer">
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: "pointer" }}
                          id={`${sort}@ASC`}
                          hidden={sortHide === `${sort}@DESC` ? false : true}
                          onClick={handleSort}
                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@DESC`}
                          style={{ cursor: "pointer" }}
                          hidden={sortHide === `${sort}@DESC` ? true : false}
                          onClick={handleSort}
                        />
                      </span>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {getMigrationTypeGrid?.length > 0 ? (
              getMigrationTypeGrid.map((data, index) => (
                <tr key={index}>
                  {bindingObject[applicationName?.toLowerCase().trim()].map(
                    ({ value }: any, index: React.Key | null | undefined) => (
                      <React.Fragment key={index}>
                        {value === "Status" ? (
                          <td>
                            <span className="text-nowrap">
                              <span
                                className={getStatusClass(data[value])}
                              ></span>
                              {data[value]}
                            </span>
                          </td>
                        ) : value === "report" ? (
                          <td className="d-flex align-items-center justify-content-center">
                            <span className="d-inline-flex gap-3">
                              <button
                                className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Download"
                                data-bs-original-title=""
                                title=""
                                onClick={() => {
                                  invokeDownload(data);
                                }}
                              >
                                <img
                                  src="/images/download-icon-filled.svg"
                                  alt="Download"
                                />
                              </button>
                            </span>
                          </td>
                        ) : (
                          <td>{data[value] || "-"}</td>
                        )}
                      </React.Fragment>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={
                    bindingObject[applicationName?.toLowerCase().trim()].length
                  }
                >
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">
                        No Records Found
                      </h5>
                      <p className="font-10 font-regular color-no-rec">
                        No Matching Results Found
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div></div>
            <p className="text-start mb-3" style={{ color: 'black' }}>
            # Records of :<span style={{ fontWeight: 'bold' }}>{getMigrationTypeGrid?.length}</span> out of <span style={{ fontWeight: 'bold' }}>{totalCount}</span>
          </p>
        <div className="d-flex justify-content-center mt-5">
         
          {totalCount <= page ? (
            ""
          ) : (
            <button
              className="btn primary-outline-btn font-14 font-semibold"
              hidden={totalCount<page}
              onClick={() => {
                setPage(page + 10);
              }}
            >
              Load More
            </button>
          )}
        </div>
        {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { setAlert(false) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
    </>
  );
};

export default MigrationTypeChild;