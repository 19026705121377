import { client } from "../Client/Client";
import { BE_URL, dashboard } from "../Common/Constant";
 
/*PS_DB_63  to PS_DB_66 will be executed for calling services */
 
export async function getAdminCardsData(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + dashboard.getAdminCards,
        // url: "http://localhost:8080/dashboard/getAdminCards",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    return response;
};
 
export async function getAdminLicenseRenewalData(data:object) {
    //console.log("inside license");
   
    let config = {
        method: 'POST',
        url: BE_URL + dashboard.getLicensRenewal,
        // url: "http://localhost:8080/dashboard/getAdminLicenseRenewal",
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);
    return response;
};
 
export async function getUserData(data:object) {
    let config = {
        method: 'POST',
        url: BE_URL + dashboard.getClientDashboard,
        // url:"http://localhost:8080/dashboard/fetchUserDashBoard",
        headers: {
            'Content-Type': 'application/json'
        },
        data:data
    }
    const response = await client(config);
    //console.log(config,"uyhgfdcv");
    return response;
  };