
import { BE_URL, ADMIN_BE_URL, connection, helpAndDocumentation, HelpAndDocumentation } from '../Common/Constant';
import { client } from "../Client/Client";


export async function getDropDownConnections(data:object) {//PS_AD-43
    const config = {
        url: BE_URL + connection.getConnections,
        method: 'POST',
        data: data
      };
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function getConnectionForTask(data:object) {//PS_AD-43
    const config = {
        url: BE_URL + connection.getConnectionEdit,
        method: 'POST',
        data: data
      };
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function checkAndCreateTask(data:object) {//PS_AD-43
    const config = {
        url: BE_URL + connection.checkAndCreateTask,
        // url:'http://localhost:8080/tasks/checkAndCreateTask',
        method: 'POST',
        data: data
      };
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};


export async function generateBlobSaasTokenApi(data = {}) {//PS_AD-10
  let Config = {
    url: ADMIN_BE_URL + HelpAndDocumentation.blobTokenUrl,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    data: data,
  };

  let response = await client(Config); //PS_AD-12
  return response; //PS_AD-13
}