import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActionInterface, Filter, Filters, OutlookDeltaInterface, SortCriteria, TaskPayloadInterface, sessionResponse } from "../../Common/Interface";
import { getAdminCardsData, getAdminLicenseRenewalData, getUserData } from "../../Services/DashboardServices";
import moment from "moment";
import * as XLSX from "xlsx";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { commonErrorMsgs } from "../../Common/Constant";
import { decodeSessionDetails } from "../../Services/CommonServices";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { fetchTaskData, outLookDeltaMigration, taskActionApi } from "../../Services/taskService";
import { getSession } from "../../Services/manageClientAPI";


export default function Dashboard() {

  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>({ field: '', order: 'asc', key: "", }); // Replace with default field and order

  const history = useHistory();
  const [showAdminDashboard, setShowAdminDashboard] = useState<boolean>(false)
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [showClientDashboard, setShowClientDashboard] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [adminCardsData, setAdminCardsData] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>('cl.licenseExpiredAt');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [adminPlanData, setAdminPlanData] = useState<any[]>([]);
  const [adminTopFiveClientData, setAdminTopFiveClientData] = useState<any[]>([])
  const [selectedReqType, setSelectedReqType] = useState<string>('monthly');
  const [adminLicenseRenewal, setAdminLicenseRenewal] = useState<any[]>([]);
  const [hoveredBar, setHoveredBar] = useState<any>('');
  const [migration, setMigration] = useState<any[]>([]);
  const [assessment, setAssessment] = useState<any[]>([]);
  const [planExpiresOn, setPlanExpiresDate] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');
  const [environmentType, setEnvironmentType] = useState<string>('All');
  const [slackCount, setslackCount] = useState<number>(0);
  const [gsuiteCount, setGsuiteCount] = useState<number>(0);
  const [tenantCount, setTenantCount] = useState<number>(0);
  const [userDashboardEnvironment, setuserDashboardEnvironment] = useState<string>('Tenant');
  const [hoveredPlan, setHoveredPlan] = useState<string | null>(null);
  const [tasks, setTasks] = useState<object>({});
  const [noData, setNoData] = useState<boolean>(false);
  let year: number = moment().year()
  const minYear = 2023;
  const [currentYear, setCurrentYear] = useState<number>(year);
  const [selectedYear, setselectedYear] = useState<number>(year);
  const [expenseData, setData] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [offset, setOffset] = useState<number>(5);
  const [cancelTaskId, setCancelTaskId] = useState<number>(0);
  const [cancelFilter, setCancelFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    planType: null,
    fromDate: null,
    toDate: null,
  });
  const [totalAppCount, setTotalAppCount] = useState<number>(0)
  const [inProgressAppCount, setInProgressAppCount] = useState<number>(0)
  const [completedPercentage, setCompletedPercentage] = useState<any[]>([]);
  const [totalParallelCount, setTotalParallelCount] = useState<number>(0)
  const [availableParallelCount, setAvailableParallelCount] = useState<number>(0)
  const [totalLicense, setTotalLicense] = useState<any[]>([]);
  const [gsuitePurchased, setGsuitePurchased] = useState<boolean>(false);
  const [tenantPurchased, setTenantPurchased] = useState<boolean>(false);
  const [lackPurchased, setSlackPurchased] = useState<boolean>(false);
  const firstSixData = totalLicense?.slice(0, 6);
  const [totalCount, setTotalCount] = useState<number>(0);

  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [role, setRole] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEmailAddress, setAdminEmailAddress] = useState('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;


  let [adminrole, setAdminRole] = useState('');
  const years: Array<any> = [];
  for (let year = currentYear; year >= minYear; year--) {
    years.push(year);
  }
  const emptyFilters: Filters = {
    status: '',
    goLiveStatus: '',
    from: '',
    to: '',
    Modifiedfrom: '',
    Modifiedto: '',
    MigrationType: ''
  };
  const [filteredTasks, setFilteredTasks] = useState<Filters>(emptyFilters); // Assuming null as default, replace as neede
  const currentMonth = moment().month() + 1
  //console.log(currentMonth, "cur");
  const filteredDataLastSixMonths: any = adminCardsData?.clientOverview?.filter((item: any) => {
    const monthNumber: any = item.monthNumber
    return monthNumber >= currentMonth - 5 && monthNumber <= currentMonth;
  });
  const colors = ['#78FF9E', '#FEC365', '#FF7A7A', '#9678FF', '#87CEEB'];
  const Clientcolors = ['#78FF9E', '#FEC365', '#FF7A7A', '#9678FF', '#87CEEB', '#EC7AFF', '#EEF157'];
  let adminExpenseMonthly: Array<any> = [];
  let adminExpenseQuarterly: Array<any> = [];
  let adminExpenseHalfYearly: Array<any> = [];
  let adminExpenseYearly: Array<any> = [];

  /** PS_DB_01 to PS_DB_40 All Variable declaration*/
  const getStatusCSS = (status: string) => {
    switch (status) {
      case "failed":
        return "danger";
      case "queued":
        return "draft";
      case "draft":
        return "draft";
      case "cancelled":
        return "draft";
      case "scheduled":
        return "scheduled";
      case "inprogress":
        return "warning";
      case "in progress":
        return "warning";
      case "completed":
        return "success";
      case "paused":
        return "paused";
      default:
        return "";
    }
  };
  const userTaskAction = async (taskId: number, status: string) => {
    const token = await getToken()
    try {
      setLoader(true);
      const payload: ActionInterface = {
        token: token,
        taskId: taskId,
        status: status,
      };

      const data: any = await taskActionApi(payload);
      //console.log(data, "action res")
      if (data.data.statusCode == 200) {
        //console.log("task cancelled successfully");
        fetchData(filteredTasks, true);
      } else {
        //console.log("chhh", data.data)
        setAlert(data.data.statusMessage);

        setLoader(false);
      }
    } catch (error) {
      setAlert(true); // PS_60: Replace with proper error handling
      setAlertMessage(commonErrorMsgs.generalError)
      setLoader(false);
    }

  };


  const createNavigationState = (task: any, stage: number) => {
    return {
      taskId: task.taskId,
      taskName: task.taskName,
      applicationName: task.applicationName,
      stage,
      isEdit: true
    };
  };


  //PS_DB_125 to  PS_DB_127 handlesortChange() function for sorting license renewal grid

  const handleSortChange = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const getLastVisited = (task: any) => {
    setLoader(true); // PS_81

    const page = task.lastVisited; // PS_82
    const taskType = task?.taskType; // PS_83
    // console.log(page, "pageeeeeeeeeeeeeeeeee");
    if (page?.toLowerCase().includes("generalconfigurations")) { // New condition
      history.push('/Configurations', {
        state: {
          taskId: task.taskId,
          taskName: task.taskName,
          taskType: task.taskType,
          applicationName: task.applicationName,
          stage: 4,
          isEdit: true,
          lastVisited: task.lastVisited

        }
      });
      setLoader(false); // PS_93
      return; // Exit the function early
    }
    if (taskType?.toLowerCase() === "assessment") { // PS_84
      switch (page?.toLowerCase()) {
        case "assessmentconfigurations": // PS_85
          history.push("/assessmentConfigurations", {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 2,
              isEdit: true,
              lastVisited: task.lastVisited

            },
          });

          break;
        case "assessment": // PS_86
          history.push('/Assessment', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 3,
              isEdit: true,
              lastVisited: task.lastVisited

            },
          });
          break;
        case "summary": // PS_87
          history.push('/Summary', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited

            },
          });
          break;
        case "inprogress": // PS_87
          history.push('/Inprogress', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited

            },
          });
          break;
        default: // PS_88
          history.push('/assessmentConfigurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 0,
              isEdit: true,
              lastVisited: task.lastVisited

            },
          });
          break;
      }
    } else { // PS_89
      switch (page?.toLowerCase()) {
        case "configurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited

            }
          });
          break;
        case "configurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited
            }
          });
          break;
        case "usermapping": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited
            }
          });
          break;
        case "appspecificconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited
            }
          });
          break;
        case "goliveconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited
            }
          });
          break;
        case "psserviceconfigurations": // PS_90
          history.push('/Configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true,
              lastVisited: task.lastVisited
            }
          });
          break;
        case "selectmigration": // PS_91
          history.push('/migration', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true
            }
          });
          break;
        default: // PS_92
          history.push('/configurations', {
            state: {
              taskId: task.taskId,
              taskName: task.taskName,
              taskType: task.taskType,
              applicationName: task.applicationName,
              stage: 4,
              isEdit: true
            }
          });
          break;
      }
    }
    setLoader(false); // PS_93
  };

  if (tenantPurchased) {
    setuserDashboardEnvironment('Tenant')
  }
  const deltamigration = async (task: any) => {
    // Prepare the payload based on outlookDeltaInterface (PS_76)
    const payload: OutlookDeltaInterface = {
      clientDBName: task.clientDBName,
      taskId: task.taskId,
      applicationName: task.applicationName,
      parallelInstanceCount: task.parallelInstanceCount,
      list: task.list, // Assuming task.list is an array as per your actual task object's structure
      isDelta: true,   // Assuming a true boolean value for delta migration, adjust as necessary
    };

    // Call the API and await the response (PS_77)
    try {
      setLoader(true); // Set loader before API operation
      const response = await outLookDeltaMigration(payload);
      //console.log(response); // Handle the response as needed

      // ... additional logic based on the response
    } catch (error) {
      console.error('Error during delta migration:', error); // Handle error appropriately
    } finally {
      // Set the loader state to false (PS_78)
      setLoader(false);
    }
  };
  const handleSort = (
    field: string,
    currentOrder: "asc" | "desc",
    connectionName: string
  ) => {

    if (field == 'type') {
      // Preserve the existing order when values are equal
      return 0;
    }

    const order = currentOrder === "asc" ? "desc" : "asc";

    // Deep copy the tasks object
    const sortedData = JSON.parse(JSON.stringify(tasks));

    // Find the correct array of tasks based on connectionName
    const tasksArray = sortedData[connectionName][0];

    //console.log(tasksArray, "tasksArray");

    // Sort the tasks array
    tasksArray.sort((a: any, b: any) => {
      const fieldValueA = typeof a[field] === "string" ? a[field].toLowerCase() : a[field];
      const fieldValueB = typeof b[field] === "string" ? b[field].toLowerCase() : b[field];

      //   if (fieldValueA === fieldValueB) {
      //     // Preserve the existing order when values are equal
      //     return 0;
      //   }

      if (order === "asc") {
        //console.log(fieldValueA, "kdkdkd");
        return fieldValueA > fieldValueB ? 1 : -1;
      } else {
        return fieldValueA < fieldValueB ? 1 : -1;
      }
    });

    // Update the original tasks object with the sorted data
    const updatedTasks = {
      ...tasks,
      [connectionName]: [tasksArray]
    };

    // Update state with sort criteria
    setSortCriteria({ field, order, key: connectionName });
    // Set tasks state with original tasks object
    setTasks(updatedTasks);
  };



  //PS_DB_58
  useEffect(() => {
    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
      // resetSessionTimer();
      sessionTokenProperties();
      resetTimer();
      setShowAdminDashboard(true)
      fetchAdminCardsData();
      fetchAdminLicenseRenewal();
      window.addEventListener('keydown', resetTimer);
      window.addEventListener('click', resetTimer);

      return () => {
        window.removeEventListener('click', resetTimer);
        window.removeEventListener('keydown', resetTimer);
      };
      //   return () => {
      //     // window.removeEventListener('click', resetSessionTimer);
      //     // window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
    else {
      sessionTokenProperties()
      setShowClientDashboard(true)
      fetchUserData()
      fetchData(emptyFilters, true)
      //   return () => {
      //     window.removeEventListener('click', resetSessionTimer);
      //     window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
  }, [selectedYear, environmentType, selectedReqType, sortBy, sortOrder])


  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: objClient == undefined ? true : false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };

  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          emailAddress = tokenResponse.adminDetails.Email;
          setEmailAddress(emailAddress);
          setSessionEpiredDateTime(tokenResponse.adminDetails.CreatedAt);
          setAdminRole(tokenResponse.adminDetails.Role);
          setclientName(tokenResponse.adminDetails.Name);
        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {

        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
          setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
          if (tokenResponse.adminDetails) {
            setObjAdmin(tokenResponse.adminDetails)
            setAdminName(tokenResponse.adminDetails.Name);
            setAdminEmailAddress(tokenResponse.adminDetails.Email);
            setAdminRole(tokenResponse.adminDetails.Role);
          }
        }
        else {
          if (tokenResponse.adminDetails) {
            history.push("/Admin/Login")
          }
          else {
            history.push("/Login")
          }

        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const fetchData = async (filter: any, isNew: boolean = false) => {
    // Replace any with the actual type for filter
    // PS_43
    let token = await getToken()
    setLoader(true);

    // PS_44: Assuming role and token are part of the component's state and are set correctly earlier
    const payload: TaskPayloadInterface = {
      token,
      searchValue: '',
      filter,
      offset // assuming component state
    };

    try {
      // PS_45 and PS_46
      let response: any = await fetchTaskData(payload);
      ////console.log(response.data.data?.taskDetails)
      response = response.data
      //console.log("------", response, "  data we need to check")
      // PS_55 to PS_71: Handle response based on statusCode and isNew values
      if (response.statusCode === 200) {

        if (response.statusMessage === 'No content') { // PS_55 to PS_59
          //console.log("no data")
          setTasks([]);
          setLoader(false);
          setNoData(true)

        } else { // PS_60 to PS_71: Include necessary data checks
          //response = response.data
          //console.log(response, "rrrr")
          if (isNew) { // PS_63 to PS_67
            setTasks(response.data?.taskDetails || []);
            setNoData(false);
            // Assume there are other states to set based on response.data
          } else { // PS_68 to PS_71
            let existingTask = tasks;
            existingTask = { ...existingTask, ...response.data.taskDetails };
            setTasks(existingTask)
            setNoData(false);
            // And so on for other relevant states
          }

        }

      }
      else {
        setNoData(true)
      }
      let arr: string[] = [];
      response.data?.taskNames?.map((value: any) => {
        ////console.log(value.taskName.toLowerCase(), "sandy");
        arr.push(value.taskName.toLowerCase());
      });




      setTotalCount(response.data?.taskNames.length)

      setLoader(false);
    } catch (error) {
      // PS_60: Proper error handling here
      setAlert(true); // PS_60: Replace with proper error handling
      setAlertMessage(commonErrorMsgs.generalError)
      setLoader(false);
    }
  };

  const handleImageClick = () => {
    history.push('/Admin/LicenseRenewal');
  };

  //PS_DB_82 to PS_DB_85 for forming expense data
  if (selectedReqType === "monthly") {
    adminExpenseMonthly = expenseData?.map((item: any) => {
      const totalAmountPaid = item?.totalAmountPaid || 0;
      const totalCostUSD = item.totalCostUSD || 0;
      const profitCounts = totalAmountPaid - totalCostUSD

      return {
        ...item,
        profitCounts: profitCounts,
      };
    });

  }
  else if (selectedReqType === "quarterly") {
    adminExpenseQuarterly =
      expenseData?.map((item: any) => {
        const totalAmountPaid = item?.totalAmountPaid || 0;
        const totalCostUSD = item.totalCostUSD || 0;
        const profitCounts = totalAmountPaid - totalCostUSD
        return {
          ...item,
          profitCounts: profitCounts
        };
      });
  }
  else if (selectedReqType === "halfyearly") {
    adminExpenseHalfYearly =
      expenseData?.map((item: any) => {
        const totalAmountPaid = item?.totalAmountPaid || 0;
        const totalCostUSD = item.totalCostUSD || 0;
        const profitCounts = totalAmountPaid - totalCostUSD
        return {
          ...item,
          profitCounts: profitCounts
        };
      });
  }
  else if (selectedReqType === "yearly") {
    adminExpenseYearly = expenseData?.map((item: any) => {
      const totalAmountPaid = item?.TotalAmountPaid || 0;
      const totalCostUSD = item.TotalCostUSD || 0;
      const profitCounts = totalAmountPaid - totalCostUSD
      return {
        ...item,
        profitCounts: profitCounts
      };
    });
  }

  /**PS_DB_69 to PS_DB_81 will be executed for fetchAdmincards to bin dthe data in adminDashboard
   */
  const fetchAdminCardsData = async () => {
    try {

      let token = await getToken()
      setLoader(true)
      let payload = {
        token: token,
        type: selectedReqType,
        environmentType: environmentType,
        year: Number(selectedYear)
      }
      const data: any = await getAdminCardsData(payload);
      if (data?.data?.statusCode === 200) {
        setLoader(false)
        setAdminCardsData(data?.data.data)
        //console.log(data?.data?.data?.expense, "sham");
        setslackCount(data?.data?.data?.environmentCount?.find((count: any) => count?.environmentType.toLowerCase() === 'slack')?.TotalUsers || 0)
        setGsuiteCount(data?.data?.data?.environmentCount?.find((count: any) => count?.environmentType.toLowerCase() === 'g-suite')?.TotalUsers || 0)
        setTenantCount(data?.data?.data?.environmentCount?.find((count: any) => count?.environmentType.toLowerCase() === 'tenant')?.TotalUsers || 0)
        setData(data?.data?.data?.expense?.data)
        const groupedData = data?.data?.data?.planType?.reduce((acc: any, item: any) => {
          // Check if the planType already exists in accumulator
          if (!acc[item.planType]) {
            // If it doesn't exist, initialize with planType and an array containing the first item
            acc[item.planType] = {
              name: item.planType,
              value: item.totalManageClientIdCount,
              environments: [{
                environmentType: item.environmentType,
                environmentManageClientIdCount: item.environmentManageClientIdCount
              }]
            };
          } else {
            // If it exists, push the current environment details to the existing environments array
            acc[item.planType].environments.push({
              environmentType: item.environmentType,
              environmentManageClientIdCount: item.environmentManageClientIdCount
            });
          }
          return acc;
        }, {});
        const initialData = {
          'Free': {
            name: 'Free',
            value: 0,
            environments: []
          },
          'Lite': {
            name: 'Lite',
            value: 0,
            environments: []
          },
          'Plus': {
            name: 'Plus',
            value: 0,
            environments: []
          },
          'Elite': {
            name: 'Elite',
            value: 0,
            environments: []
          },
          'Pro': {
            name: 'Pro',
            value: 0,
            environments: []
          }
        };
        let transformedData = Object.values({ ...initialData, ...groupedData });
        const orderArray = ['Free', 'Lite', 'Plus', 'Elite', 'Pro'];
        transformedData = transformedData?.sort((a: any, b: any) => {
          const indexA = orderArray.indexOf(a.name)
          const indexB = orderArray.indexOf(b.name)
          return indexA - indexB
        })
        //console.log(transformedData, "sham mul");
        setAdminPlanData(transformedData);
        setAdminTopFiveClientData(data?.data?.data?.topClients);
      }
      else {
        setLoader(false)
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }

    } catch (error: any) {
      setLoader(false)
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  };


  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  /** PS_DB_163 to PS_DB_172 will be executed
  */
  const fetchUserData = async () => {

    try {
      setLoader(true)
      let token = await getToken()
      const payload = {
        token: token,
        environmentType: userDashboardEnvironment
      };
      const data: any = await getUserData(payload);


      if (data?.data?.statusCode == 200) {
        setLoader(false)
        setTotalAppCount(data?.data?.data?.AppCounts?.totalApplications)
        setInProgressAppCount(data?.data?.data?.AppCounts?.InProgressApplications)
        setCompletedPercentage(data?.data?.data?.AppCounts?.completionPercentage)
        setAvailableParallelCount(data?.data?.data?.parallelInstanceCount?.AvailableParallelInstance)
        setTotalParallelCount(data?.data?.data?.parallelInstanceCount?.PurchasedParallelInstance)
        const orderArray = ['Groups', 'RoomsAndEquipments', 'OneDrive', 'SharePoint', 'AvailableSharedMailboxLicense', 'Team', 'TeamsChannel', 'TeamsChat', 'UserMailboxLicense'];
        const combinedData = orderArray
          .map(appName => ({
            appName: appName,
            availableLicense: data?.data?.data?.totalLicense[`Available${appName}License`] || 0,
            purchasedLicense: data?.data?.data?.totalLicense[`Purchased${appName}License`] || 0
          })).filter(item => item.availableLicense !== 0 || item.purchasedLicense !== 0);
        setTotalLicense(combinedData)
        setPlanExpiresDate(data?.data?.data?.planExpiry?.formattedLicenseExpiredAt)
        setPlanName(data?.data?.data?.planExpiry?.planType)
        const assessmentArray = data?.data?.data?.taskType?.Assessment;
        const transformedArray = assessmentArray?.map((statusObject: any) => {
          // Convert object keys to an array of { name, value } objects
          return Object.entries(statusObject).map(([key, value]) => ({
            name: key,
            value: value
          }));
        })
        setAssessment(transformedArray)
        const migrationArray = data?.data?.data?.taskType?.Migration;
        debugger
        const transformedArray1 = migrationArray?.map((statusObject: any) => {
          // Convert object keys to an array of { name, value } objects
          return Object.entries(statusObject).map(([key, value]) => ({
            name: key,
            value: value
          }));
        })
        setMigration(transformedArray1)
      }
      else {
        setLoader(false)
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }

    }

    catch (error) {
      setLoader(false)
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  }

  //PS_DB_86 to PS_DB_92 will be executed for getAdminLicenseRenewalData
  const fetchAdminLicenseRenewal = async () => {
    try {
      let token = await getToken()
      const result = await getAdminLicenseRenewalData({
        token: token,
        searchValue: "", // Trim leading spaces from search value
        sortBy,
        sortOrder,
        pageSize,
        ...filter
      });//PS_AD-31
      if (result?.data?.statusCode == 200) {
        setAdminLicenseRenewal(result?.data?.data?.licenseGrid);//PS_AD-32
      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }

    } catch (error) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    }
  };

  const handleClick = (index: any) => {
    //console.log("YESS")
    setHoveredBar(index);
  };

  //PS_DB_71
  const handleMouseEnter = (data: any, index: number) => {
    if (adminPlanData.length !== 0) {
      const hoveredPlanType = data.name; // Assuming name corresponds to planType
      setHoveredPlan(hoveredPlanType);
    }
  };
  //PS_DB_72
  const handleMouseLeave = () => {
    setHoveredPlan(null); // Reset hovered plan when mouse leaves
  };

  // PS_DB_188 Using recharts pacakge, I will be handling all  the pie charts , bar graph using properties like tooltip, datakey , ticketFormatter etc.
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="font-semibold font-12 py-1 px-2 rounded-2" style={{ color: '#f7f7f7', backgroundColor: '#2b1342', height: '25px' }}>
          <p>{`$${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };
  const CustomTooltip1 = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div></div>
      );
    }

    return null;
  };
  const CustomTooltip2: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const profit = payload[1]?.value || 0;
      const expense = payload[0]?.value || 0;
      const revenue = payload[1]?.value ? expense + profit : 0


      return (
        <div className="font-semibold font-12 py-1 px-2 rounded-2" style={{ backgroundColor: '#f7f7f7', border: '1px solid #ccc', height: '100px' }}>
          <p style={{ color: 'skyblue', justifyContent: 'center', display: 'flex', alignContent: 'center' }}> {`Profit : $${profit}`}</p>
          <p style={{ color: '#8884d8', justifyContent: 'center', display: 'flex', alignContent: 'center' }}>{`Expense : $${expense}`}</p>
          <p style={{ color: 'skyblue', justifyContent: 'center', display: 'flex', alignContent: 'center' }}>{`Revenue : $${revenue}`}</p>
        </div>
      );
    }

    return null;
  };
  const CustomTooltip3: any = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      //console.log(payload[2], "payloadlabel");

      const subscribedCount = payload[1]?.value || 0;
      const registeredCount = payload[0]?.value || 0;

      return (
        <div className="font-semibold font-12 py-1 px-2 rounded-2" style={{ backgroundColor: '#f7f7f7', border: '1px solid #ccc', height: '50px' }}>
          <p style={{ color: 'skyblue', justifyContent: 'center', display: 'flex', alignContent: 'center' }}> {`Registered : ${registeredCount}`}</p>
          <p style={{ color: '#8884d8', justifyContent: 'center', display: 'flex', alignContent: 'center' }}>{`Subscribed : ${subscribedCount}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTooltip5: any = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      //console.log(payload, "payloadlabel");

      const subscribedCount = payload[1]?.value || 0;
      const registeredCount = payload[0]?.value || 0;

      return (
        <div className="font-semibold font-12 py-1 px-2 rounded-2" style={{ backgroundColor: '#f7f7f7', border: '1px solid #ccc', height: '50px' }}>
          <p style={{ color: 'skyblue', justifyContent: 'center', display: 'flex', alignContent: 'center' }}> {`Total : ${subscribedCount}`}</p>
          <p style={{ color: '#8884d8', justifyContent: 'center', display: 'flex', alignContent: 'center' }}>{`Remaining : ${registeredCount}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTooltip4: any = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      //console.log(hoveredPlan, "payloadlabel");

      //console.log(payload[0].payload, "payloadlabel");

      //console.log(payload[0].payload.environments, "payloadlabel");
      const hoveredPlanData = adminPlanData.find(item => item.name === hoveredPlan);
      const environmentTypes = ['Slack', 'Tenant', 'G-suite'];

      // Initialize an object to store counts
      const environmentCounts: Record<string, number> = {
        'Slack': 0,
        'Tenant': 0,
        'G-suite': 0
      };
      hoveredPlanData?.environments?.forEach((env: any) => {
        if (environmentTypes.includes(env.environmentType)) {
          environmentCounts[env.environmentType] = env.environmentManageClientIdCount;
        }
      });

      const tooltipContent = environmentTypes
        .map((envType: string) => `${envType}: ${environmentCounts[envType]}`)
        .join('\n');

      return (
        <div className="font-semibold font-12 py-1 px-2 rounded-2" style={{ backgroundColor: '#f7f7f7', border: '1px solid #ccc', height: '60px' }}>
          <p style={{
            color: 'skyblue', justifyContent: 'center', display: 'flex', alignContent: 'center', whiteSpace: 'pre-line' // This will respect newline characters
          }}> {`${tooltipContent}`}</p>
        </div>
      );
    }
    return null;

  }








  //PS_DB_93 to PS_DB_105 will be executed

  const exportExcel = () => {
    // Convert combinedData to XLSX worksheet
    // setLoader(true);
    let expenseData1;
    debugger

    if (selectedReqType === 'monthly') {
      expenseData1 = adminExpenseMonthly;
    } else if (selectedReqType === 'quarterly') {
      expenseData1 = adminExpenseQuarterly;
    } else if (selectedReqType === 'halfyearly') {
      expenseData1 = adminExpenseHalfYearly;
    } else if (selectedReqType === 'yearly') {
      expenseData1 = adminExpenseYearly;

    } else {
      console.error('Invalid expense type');
      setLoader(false);
      return;
    }
    if (!expenseData1 || expenseData1?.length === 0) {
      console.error('No data to export');
      setLoader(false);
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(expenseData1);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `AVAMIGRATRON_Revenue.csv`);
    setLoader(false);
  };



  const bindData = () => {
    const data = adminLicenseRenewal
    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            <div className="pt-4 pb-3 text-center">
              <img
                src="/images/no-data-found-img.svg"
                alt="no-data-found-img"
                className="mb-3"
              />
              <h5 className="font-12 font-bold color-no-rec">No records</h5>
              <p className="font-10 font-regular color-no-rec">
                There is no record to show you right now
              </p>
            </div>
          </td>
        </tr>
      );
    }
    return data?.map((item) => {
      return (
        <tr key={item?.companyName}>
          <td><span
            style={{ cursor: 'pointer' }}
            title={item?.companyName.length > 22 ? `${item.companyName.slice(0, 22)}...` : item.companyName}
          >
            {item?.companyName.length > 22 ? `${item.companyName.slice(0, 22)}...` : item.companyName}
          </span></td>
          <td>{item.productType}</td>
          <td>{item.currentPlan}</td>

          <td>{moment(item.renewalDate).format('MM/DD/YYYY')}</td>
        </tr>
      );
    });
  };





  debugger
  return (
    <>
      {showAdminDashboard && <div className="container-fluid container-height">
        <div className="row ">
          <div className="col-md-12 ">
            <div className="p-3 mt-3 mb-3 bg-white border-radius-8 ">
              <h1 className="font-regular primary-color font-28 mb-4">
                Welcome Back <span className="font-bold">{clientName}</span>
                <span>
                  <img
                    src="/images/Waving-Hand-Emoji.svg"
                    alt="Waving-Hand-Emoji"
                    className="ms-2"
                  />
                </span>
              </h1>
              <div className="row row-cols-1 g-3">
                <div className="col-xl-4 col-lg-12 col-sm-12 col-md-12 mb-3">
                  <div className="p-3 card h-100 border-radius-8">
                    <div className="d-flex justify-content-between">
                      <p className="font-semibold font-16 primary-color">
                        Total Clients
                      </p>
                      <img src="/images/total-clients.svg" alt="Total-clients" />
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p className="font-bold font-40 primary-color mt-2 mb-0">
                        {/* {adminCardsData?.TotalClients ? adminCardsData.TotalClients : 0} */}
                        {slackCount + tenantCount + gsuiteCount}
                      </p>

                      <div className="d-flex justify-content-between align-items-center">
                        <span className="font-regular font-14 color-grey-v1 me-1 me-md-3 ms-1 ms-md-2">Slack</span>
                        <span className="font-bold font-14 pe-1 pe-md-2 cust-dashboard-border">{slackCount}</span>
                        <span className="font-regular font-14 color-grey-v1 me-1 me-md-3 ms-1 ms-md-2"> Tenant</span>
                        <span className="font-bold font-14 pe-1 pe-md-2 cust-dashboard-border">{tenantCount}</span>
                        <span className="font-regular font-14 color-grey-v1 me-1 me-md-3 ms-1 ms-md-2">G-suite</span>
                        <span className="font-bold font-14 pe-1 pe-md-2">{gsuiteCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-sm-12 col-md-12 mb-3">
                  <div className="row g-3">
                    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12">
                      <div className="p-3 card h-100 border border-radius-8 ">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="d-md-flex flex-row justify-content-between align-items-center flex-wrap w-md-100 d-block me-md-3 mb-3">

                              <h3 className="font-semibold font-16 primary-color mb-3 mb-md-0 me-0">
                                Clients Overview
                              </h3>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="d-sm-flex align-items-center d-block">
                                  <div className="d-flex align-items-center my-2 my-md-0 order-sm-1">
                                    <div className="align-items-center d-flex align-items-baseline me-3">
                                      <span className="legend-total-revenue legend-total-profit me-2" />
                                      <span className="font-10 font-regular color-grey-v2">
                                        Registered
                                      </span>
                                    </div>
                                    <div className="align-items-center d-flex align-items-baseline">
                                      <span className="legend-total-revenue legend-total-expense me-2" />
                                      <span className="font-10 font-regular color-grey-v2">
                                        Subscribed
                                      </span>
                                    </div>
                                  </div>
                                  <select className="form-select w-auto custom-form py-2 font-12 me-0 me-sm-3 font-regular" value={selectedYear} onChange={(e: any) => setselectedYear(e.target.value)}>
                                    {years?.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <span className="lh-1 ms-3">
                                  <img
                                    className="cursor-pointer"
                                    src="/images/expand-icon.svg"
                                    alt="expand-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#clientOverviewPopup"
                                  />
                                </span>
                              </div>

                            </div>
                            {/* start of the pop up */}
                            <div
                              className="modal fade"
                              id="clientOverviewPopup"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabIndex={-1}
                              aria-labelledby="clientOverviewPopupLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content p-4">
                                  <div className="modal-header border-0 p-0">
                                    <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
                                      <h3 className="font-semibold font-16 primary-color mb-2 mb-md-0">
                                        Clients Overview
                                      </h3>
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex justify-content-center align-items-center me-4">
                                          <div className="align-items-center d-flex align-items-baseline me-3">
                                            <span className="legend-total-revenue legend-total-profit me-2" />
                                            <span className="font-10 font-regular color-grey-v2">
                                              Registered
                                            </span>
                                          </div>
                                          <div className="align-items-center d-flex align-items-baseline">
                                            <span className="legend-total-revenue legend-total-expense me-2" />
                                            <span className="font-10 font-regular color-grey-v2">
                                              Subscribers
                                            </span>
                                          </div>
                                        </div>
                                        <select className="form-select w-auto custom-form py-2 font-12 me-0 me-sm-3 font-regular" value={selectedYear} onChange={(e: any) => setselectedYear(e.target.value)}>
                                          {years?.map((year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn-close cus-btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body border-0 p-0 pt-2 pb-2">
                                    <div className="custom-scroll cust-dashboard-widget-scroll">
                                      {adminCardsData?.clientOverview?.length > 0 ? (<ResponsiveContainer width="100%" height={100}>
                                        <BarChart
                                          width={500}
                                          height={400}
                                          data={adminCardsData?.clientOverview}
                                          barGap={10}
                                          margin={{
                                            top: 10,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                          }}
                                        >
                                          <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                                          <XAxis dataKey="MonthName" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
                                          <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {


                                            if (value >= 10) {

                                              return `${Math.round(value / 10)}`;
                                            }
                                            return `${Math.round(value)}`;
                                          }} />
                                          <Tooltip content={<CustomTooltip3 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                                          <Bar dataKey="registeredCount" stackId="a" fill="#8884d8" radius={[3, 3, 3, 3]} />
                                          <Bar dataKey="subscribedCount" stackId="a" fill="skyblue" radius={[3, 3, 3, 3]} />
                                        </BarChart>
                                      </ResponsiveContainer>) : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end of the pop up */}
                            {adminCardsData?.clientOverview?.length > 0 ? (<ResponsiveContainer width="100%" height={100}>
                              <BarChart
                                width={100}
                                height={100}
                                data={filteredDataLastSixMonths}
                                barGap={10}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                                <XAxis dataKey="MonthName" axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                                <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {
                                  if (value >= 10) {
                                    return `${Math.round(value / 10)}`;
                                  }
                                  return `${Math.round(value)}`;
                                }} />
                                <Tooltip content={<CustomTooltip3 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                                <Bar dataKey="registeredCount" stackId="a" fill="#8884d8" radius={[3, 3, 3, 3]} />
                                <Bar dataKey="subscribedCount" stackId="a" fill="skyblue" radius={[3, 3, 3, 3]} />
                              </BarChart>
                            </ResponsiveContainer>) : (<div className="custom-scroll cust-dashboard-widget-scroll align-self-center d-flex align-items-center justify-content-center">
                              <div className="pt-4 pb-3 text-center">
                                <img
                                  src="/images/no-data-found-img.svg"
                                  alt="no-data-found-img"
                                  className="mb-3"
                                />
                                <h5 className="font-12 font-bold color-no-rec">No records</h5>
                                <p className="font-10 font-regular color-no-rec">
                                  There is no record to show you right now
                                </p>
                              </div>
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12">
                      <div className="p-3 card h-100 border border-radius-8 ">
                        <p className="font-semibold font-16 primary-color mb-3">
                          Feedback
                        </p>
                        <div className="row mt-0 mx-4">
                          <div className="col-sm-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                              <span className="feedback-img mb-2">
                                <img
                                  src="/images/sad.png"
                                  alt="Sad"
                                  className="w-100"
                                />
                              </span>
                              <div>
                                <span className="font-regular font-14 color-grey-v1 me-2">
                                  Sad
                                </span>
                                <span className="font-bold font-14">{adminCardsData?.feedback?.sadPercentage ? adminCardsData?.feedback?.sadPercentage : 0}%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                              <span className="feedback-img mb-2">
                                <img
                                  src="/images/neutral.png"
                                  alt="neutral"
                                  className="w-100"
                                />
                              </span>
                              <div>
                                <span className="font-regular font-14 color-grey-v1 me-2">
                                  Neutral
                                </span>
                                <span className="font-bold font-14">{adminCardsData?.feedback?.neutralPercentage ? adminCardsData?.feedback?.neutralPercentage : 0}%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                              <span className="feedback-img mb-2">
                                <img
                                  src="/images/happy.png"
                                  alt="happy"
                                  className="w-100"
                                />
                              </span>
                              <div>
                                <span className="font-regular font-14 color-grey-v1 me-2">
                                  Happy
                                </span>
                                <span className="font-bold font-14">{adminCardsData?.feedback?.happyPercentage ? adminCardsData?.feedback?.happyPercentage : 0}%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-sm-12 col-md-12 mb-3 mt-0">
                  <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                    <div className="d-md-flex flex-wrap d-block justify-content-between align-items-center mb-3 mb-md-0">
                      <div className="d-xl-flex justify-content-between align-items-center w-100 mb-3 mb-xl-0">
                        <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-3 mb-xl-0">
                          <h3 className="font-semibold font-16 primary-color text-nowrap mb-0">
                            Total Revenue
                          </h3>
                          <div className="align-items-center d-flex me-0 me-xl-4 mb-0">
                            <span className="lh-1">
                              <img
                                src="/images/money.svg"
                                alt="Money"
                                className="me-2"
                              />
                            </span>
                            <label className="me-2 font-14 font-regular color-grey-v1">
                              Total
                            </label>
                            <span className="font-bold font-16 color-black">
                              {`$${Math.round(adminCardsData?.expense?.Totalrevenue)}` || 0}
                            </span>
                          </div>
                        </div>
                        <div className="align-items-center d-md-flex d-block">
                          <div className="d-flex mb-md-0 mb-3">
                            <select
                              className="form-select dashboard-select w-auto w-md-100 custom-form py-2 font-12 me-0 me-md-2 font-regular order-2 order-md-0"
                              id="reqTypeSelect"
                              value={selectedReqType}
                              onChange={(e) => setSelectedReqType(e.target.value)}
                            >
                              <option value="monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="halfyearly">Half-Yearly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                            <select className="form-select w-auto  custom-form py-2 font-12 me-2 font-regular order-2 order-md-0"
                              value={environmentType} onChange={(e) => setEnvironmentType(e.target.value)}>
                              <option>All</option>
                              <option>Tenant</option>
                              <option>Slack</option>
                              <option>G-Suite</option>
                            </select>
                            <button
                              type="button"
                              className="btn bg-white py-1 cust-excel-border-color me-2 me-md-0 lh-1"
                            >
                              <span className="d-inline-flex justify-content-center align-items-center">
                                <img src="/images/excel.svg" alt="Excel" onClick={() => exportExcel()} />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <h3 class="font-semibold font-16 primary-color mb-md-0 mb-3">Total Revenue</h3> */}
                    </div>
                    <div className="custom-scroll cust-dashboard-widget-scroll">
                      {(adminExpenseMonthly && selectedReqType === "monthly") ||
                        (adminExpenseHalfYearly && selectedReqType === "halfyearly") ||
                        (adminExpenseQuarterly && selectedReqType === "quarterly") ||
                        (adminExpenseYearly && selectedReqType === "yearly") ? (
                        <div className="">
                          {selectedReqType === "quarterly" && (
                            <BarChart
                              width={800}
                              height={200}

                              data={adminExpenseQuarterly}
                              barGap={15}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />

                              <XAxis dataKey="billingQuarter" axisLine={false} tickLine={false} />
                              <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {

                                if (value >= 1000) {

                                  return `${Math.round(value / 1000)}k`;
                                }
                                return value;
                              }} />
                              <Tooltip content={<CustomTooltip2 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />

                              {/* Decrease bar size */}
                              {/* <Bar
                                dataKey="revenue"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}
                                barGap={15}
                              />{" "} */}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="totalCostUSD"
                                stackId="a"
                                fill="#8884d8"
                                barSize={15}
                                radius={[3, 3, 3, 3]}


                              />{" "}

                              {/* Decrease bar size */}
                              <Bar

                                dataKey="profitCounts"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}

                                radius={[3, 3, 3, 3]}
                              />{" "}
                              {/* Decrease bar size */}
                            </BarChart>
                          )}
                          {selectedReqType === "halfyearly" && (
                            <BarChart
                              width={800}
                              height={200}
                              data={adminExpenseHalfYearly}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              {/* <CartesianGrid strokeDasharray="3 3" /> */}
                              <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                              <XAxis dataKey="billingHalfYear" axisLine={false} tickLine={false} />
                              <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {

                                if (value >= 1000) {

                                  return `${Math.round(value / 1000)}k`;
                                }
                                return value;
                              }} />
                              <Tooltip content={<CustomTooltip2 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                              {/* <Bar
                                dataKey="revenue"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}
                                barGap={15}
                              />{" "} */}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="totalCostUSD"
                                stackId="a"
                                radius={[3, 3, 3, 3]}
                                fill="#8884d8"
                                barSize={15}
                              />{" "}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="profitCounts"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}
                                radius={[3, 3, 3, 3]}


                              />{" "}
                              {/* Decrease bar size */}
                            </BarChart>
                          )}
                          {selectedReqType === "yearly" && (
                            <BarChart
                              width={800}
                              height={200}
                              data={adminExpenseYearly}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              {/* <CartesianGrid strokeDasharray="3 3" /> */}
                              <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                              <XAxis dataKey="Year" axisLine={false} tickLine={false} />
                              <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {

                                if (value >= 1000) {

                                  return `${Math.round(value / 1000)}k`;
                                }
                                return value;
                              }} />
                              <Tooltip content={<CustomTooltip2 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />

                              {/* <Bar
  dataKey="billingYear" // Include 'billingYear' as a data key
  stackId="a"
  fill="transparent" // Set fill to transparent if you don't want a bar for billingYear
  barSize={15}
/> */}
                              {/* <Bar
                                dataKey="revenue"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}
                                barGap={15}
                              />{" "} */}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="TotalCostUSD"
                                radius={[3, 3, 3, 3]}
                                stackId="a"
                                fill="#8884d8"
                                barSize={15}
                              />{" "}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="profitCounts"
                                radius={[3, 3, 3, 3]}
                                stackId="a"
                                fill="skyblue"
                                barSize={15}

                              />{" "}
                              {/* Decrease bar size */}
                            </BarChart>
                          )}
                          {selectedReqType === "monthly" && (
                            <BarChart
                              width={800}
                              height={200}
                              data={adminExpenseMonthly}
                              barGap={100}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              {/* <CartesianGrid strokeDasharray="3 3" /> */}
                              <XAxis
                                tickLine={false}
                                axisLine={false}
                                dataKey="paymentMadeMonth" />
                              <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />

                              <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {

                                if (value >= 1000) {

                                  return `${Math.round(value / 1000)}k`;
                                }
                                return value;
                              }} />
                              <Tooltip content={<CustomTooltip2 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />

                              {/* <Bar
                                dataKey="revenue"
                                stackId="a"
                                fill="skyblue"
                                barSize={15}
                                barGap={15}
                              />{" "} */}
                              {/* Decrease bar size */}
                              {/* <Legend /> Remove the Legend component */}
                              <Bar
                                dataKey="totalCostUSD"
                                stackId="a"
                                radius={[3, 3, 3, 3]}
                                fill="#8884d8"
                                barSize={15}
                              />{" "}
                              {/* Decrease bar size */}
                              <Bar
                                dataKey="profitCounts"
                                stackId="a"
                                radius={[3, 3, 3, 3]}
                                fill="skyblue"
                                barSize={15}

                              />{" "}
                              {/* Decrease bar size */}
                            </BarChart>
                          )}
                        </div>
                      ) : (<div className="custom-scroll cust-dashboard-widget-scroll align-self-center d-flex align-items-center justify-content-center">
                        <div className="pt-4 pb-3 text-center">
                          <img
                            src="/images/no-data-found-img.svg"
                            alt="no-data-found-img"
                            className="mb-3"
                          />
                          <h5 className="font-12 font-bold color-no-rec">No records</h5>
                          <p className="font-10 font-regular color-no-rec">
                            There is no record to show you right now
                          </p>
                        </div>
                      </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <div className="align-items-center d-flex align-items-baseline me-3">
                        <span className="legend-total-revenue legend-total-expense me-2" />
                        <span className="font-10 font-regular color-grey-v2">
                          Expense
                        </span>
                      </div>
                      <div className="align-items-center d-flex align-items-baseline">
                        <span className="legend-total-revenue legend-total-profit me-2" />
                        <span className="font-10 font-regular color-grey-v2">
                          Profit Counts
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-sm-12 col-md-12 mb-3 mt-0">
                  <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="font-semibold font-16 primary-color mb-0">
                        Plan-Wise Clients
                      </h3>
                    </div>
                    {adminPlanData?.length > 0 ? (
                      <div className="row align-items-center ">
                        <div className="d-md-flex d-block align-items-center pt-3 pb-2 px-4 ">
                          <div className="col-lg-6 col-sm-12 col-md-6">
                            <div className="user-risk-image align-items-center justify-content-center d-flex">
                              {/* Recharts Pie Chart */}
                              <PieChart width={400} height={180}>
                                <Pie
                                  data={adminPlanData}
                                  dataKey="value"
                                  nameKey="name"
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={105}
                                  outerRadius={130}
                                  fill="#8884d8"
                                  // paddingAngle={5}
                                  // strokeWidth={4}
                                  label={false}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  {adminPlanData.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={colors[index % colors.length]} onClick={() => {
                                        if (adminPlanData.length !== 0) {
                                          history.push("/Admin/ManageClients", { state: { status: entry.name } })
                                        }
                                      }}
                                    />
                                  ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip4 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                              </PieChart>
                            </div>
                          </div>
                          <div className=" col-lg-6 col-sm-12 col-md-6">
                            <div className="mx-5 px-3 custom-scroll cust-progressbar-widget-scroll d-flex flex-column justify-content-center">
                              {adminPlanData.map((item, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mt-1 justify-content-between cursor-pointer"
                                >
                                  <div className="align-items-center d-flex align-items-baseline">
                                    <span
                                      className={`legends legend-cat-${index + 1
                                        } me-2 cursor-pointer`}
                                      style={{
                                        backgroundColor:
                                          colors[index % colors.length],
                                        width: '10px', height: '10px', borderRadius: '3px', marginRight: '5px'
                                      }} // Use the same colors for legend
                                    />
                                    <span className="font-10 font-regular color-grey-v2">
                                      {item.name}
                                    </span>
                                  </div>
                                  <div>
                                    <label className="font-12 font-bold color-black">
                                      {item.value}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (<div className="row align-items-center ">
                      <div className="pt-4 pb-3 text-center">
                        <img
                          src="/images/no-data-found-img.svg"
                          alt="no-data-found-img"
                          className="mb-3"
                        />
                        <h5 className="font-12 font-bold color-no-rec">No records</h5>
                        <p className="font-10 font-regular color-no-rec">
                          There is no record to show you right now
                        </p>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mb-3 mt-0">
                  <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="font-semibold font-16 primary-color mb-0">
                        Top Clients Based on Revenue
                      </h3>
                    </div>
                    <div className="custom-scroll cust-dashboard-widget-scroll">
                      {adminTopFiveClientData?.length > 0 ? (
                        <div className="row align-items-center">
                          <div className="d-md-flex d-block align-items-center">
                            <div className="col-lg-12 col-sm-12 col-md-12">
                              <div className="user-risk-image align-items-center justify-content-center d-flex">
                                <BarChart
                                  width={599}
                                  height={235}
                                  data={adminTopFiveClientData.filter(entry => entry.TotalAmountPaid !== null)} // Filter out entries with null totalAmountPaid
                                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                >
                                  <XAxis dataKey="companyName" axisLine={false} tickLine={false} />
                                  <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => {
                                    if (value >= 1000) {

                                      return `${Math.round(value / 1000)}k`;
                                    }
                                    return value;
                                  }} />
                                  {hoveredBar != null ? <Tooltip content={<CustomTooltip />} cursor={<CustomTooltip1 />} /> : <></>}
                                  <Bar
                                    dataKey="TotalAmountPaid"
                                    fill={"#9678FF4D"}
                                    isAnimationActive={false}
                                    barSize={20}
                                    radius={[10, 10, 10, 10]}
                                    onMouseLeave={() => setHoveredBar(null)}
                                  >
                                    {adminTopFiveClientData.filter(entry => entry.TotalAmountPaid !== null)?.map((entry, index) => {
                                      return <Cell
                                        key={`cell-${index}`}
                                        fill={
                                          hoveredBar === index ? "#9678FF" : "#9678FF4D"
                                        }
                                        id={index.toString()}
                                        onMouseOver={() => { handleClick(index) }}
                                        stroke={
                                          hoveredBar === index ? "#9678FF" : "#9678FF4D"
                                        }
                                        strokeWidth={index === 2 ? 4 : 1}
                                      />
                                    })}
                                    {/* <Cell cursor="pointer" fill={hoveredBar === entry.totalAmountPaid ? '#8884d8' : '#b2afed'} key={`cell-${index}`} /> */}

                                    {/* {hoveredBar === entry.totalAmountPaid && (
                                      // <Cell dataKey="totalAmountPaid" position="top"  />
                                      <Cell key={entry.totalAmountPaid} position="top" fill={hoveredBar === entry.totalAmountPaid ? '#8884d8' : '#b2afed'}/>
                                    )} */}
                                  </Bar>


                                </BarChart>

                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (<div className="custom-scroll cust-dashboard-widget-scroll">
                        <div className="pt-4 pb-3 text-center">
                          <img
                            src="/images/no-data-found-img.svg"
                            alt="no-data-found-img"
                            className="mb-3"
                          />
                          <h5 className="font-12 font-bold color-no-rec">No records</h5>
                          <p className="font-10 font-regular color-no-rec">
                            There is no records to show you right now
                          </p>
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-sm-12 col-md-12 mb-3 mt-0">
                  <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h3 className="font-semibold font-16 primary-color mb-0">
                        License Renewal
                      </h3>
                      <div>
                        <a
                          href=""
                          className="text-decoration-none"
                        >
                          <img
                            className="cursor-pointer"
                            src="/images/expand-icon.svg"
                            alt="expand-icon"
                            onClick={() => {
                              if (adminLicenseRenewal?.length !== 0) {
                                handleImageClick();
                              }
                            }}
                            hidden={adminLicenseRenewal?.length === 0}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive theme-table db-table bg-white shadow-sm custom-scroll cust-dashboard-widget-scroll">
                      <table className="table table-borderless table-shadow mb-0">
                        <thead className="sticky-top"> <tr>
                          <th className="text-nowrap" onClick={() => handleSortChange("mc.companyName")}>
                            Company Name
                            <span className="ms-1 cursor-pointer">
                              <img
                                src={`/images/sort-arrow-${sortBy === "mc.companyName" ? (sortOrder === "asc" ? "down" : "up") : "down"}.svg`}
                                alt={`sort-arrow-${sortOrder === "asc" ? "down" : "up"}`}
                              />
                            </span>
                          </th>
                          <th className="text-nowrap" onClick={() => handleSortChange("ih.environmentType")}>
                            Product Type
                            <span className="ms-1 cursor-pointer">
                              <img
                                src={`/images/sort-arrow-${sortBy === "ih.environmentType" ? (sortOrder === "asc" ? "down" : "up") : "down"}.svg`}
                                alt={`sort-arrow-${sortOrder === "asc" ? "down" : "up"}`}
                              />
                            </span>
                          </th>
                          <th className="text-nowrap" onClick={() => handleSortChange("ih.planType")}>
                            Current Plan
                            <span className="ms-1 cursor-pointer">
                              <img
                                src={`/images/sort-arrow-${sortBy === "ih.planType" ? (sortOrder === "asc" ? "down" : "up") : "down"}.svg`}
                                alt={`sort-arrow-${sortOrder === "asc" ? "down" : "up"}`}
                              />
                            </span>
                          </th>
                          <th className="text-nowrap" onClick={() => handleSortChange("cl.licenseExpiresAt")}>
                            Renewal Date
                            <span className="ms-1 cursor-pointer">
                              <img
                                src={`/images/sort-arrow-${sortBy === "cl.licenseExpiresAt" ? (sortOrder === "asc" ? "down" : "up") : "down"}.svg`}
                                alt={`sort-arrow-${sortOrder === "asc" ? "down" : "up"}`}
                              />
                            </span>
                          </th>
                        </tr>
                        </thead>
                        <tbody>{bindData()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {showClientDashboard &&
        <div className="container-fluid container-height">
          <div className="row ">
            <div className="col-md-12 ">
              <div className="p-3 mt-3 mb-3 bg-white border-radius-8">
                <h1 className="font-regular primary-color font-28 mb-4">
                  Welcome Back <span className="font-bold">{clientName}</span>
                  <span>
                    <img
                      src="/images/Waving-Hand-Emoji.svg"
                      alt="Waving-Hand-Emoji"
                      className="ms-2"
                    />
                  </span>
                </h1>
                <ul
                  className="nav nav-pills mb-4 tab-parent"
                  id="pills-tab"
                  role="tablist"
                >
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link theme-tab active font-16"
                      data-bs-toggle="pill"
                      data-bs-target="#slackTab"
                      type="button"
                      role="tab"
                      aria-selected="true"
                    >
                      {userDashboardEnvironment}
                    </button>
                  </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="slackTab"
                    role="tabpanel"
                  >
                    {/* Slack tab content starts here */}
                    <div className="row row-cols-1 g-3">
                      <div className="col-xl-9 col-lg-12 col-sm-12 col-md-12 mb-xl-3">
                        <div className="row g-3">
                          <div className="col-xl-5 col-lg-12 col-sm-12 col-md-12">
                            <div className="p-3 card h-100 border border-radius-8 ">
                              <div className="row">
                                <div className="col-md-10 col-sm-10 col-10">
                                  <p className="font-semibold font-16 primary-color">
                                    App Migration Progress
                                  </p>
                                  <div className="row">
                                    <div className="col-12 col-md-6">
                                      <div className="mt-0 mt-md-5 cust-dashboard-border d-flex">
                                        <div className="py-2 d-flex align-items-center">
                                          <span className="font-regular font-14 color-grey-v1 me-3">
                                            Apps
                                          </span>
                                          <span className="d-flex align-items-end text-violet">
                                            <span className="font-bold font-20 lh-1" data-bs-toggle="modal" data-bs-target="#UserDashboardPopup" style={{ cursor: 'pointer' }}>
                                              {inProgressAppCount}
                                            </span>
                                            <span className="font-bold font-12 lh-1">
                                              /{totalAppCount}
                                            </span>
                                          </span>
                                        </div>
                                        {/* <div class="ps-4 py-2 d-flex align-items-center"><span class="font-regular font-14 color-grey-v1 me-3">Migration</span><span class="font-bold font-20">75%</span></div> */}
                                        <div
                                          className="modal fade"
                                          id="UserDashboardPopup"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabIndex={-1}
                                          aria-labelledby="MailPopupLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content p-4">
                                              <div className="modal-header border-0 p-0">
                                                <h1
                                                  className="font-20 font-bold custom-header-color m-0"
                                                  id="MailPopupLabel"
                                                >
                                                  Apps
                                                </h1>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                />
                                              </div>
                                              <div className="modal-body border-0 p-0 pt-2 pb-5">
                                                {completedPercentage?.map((item: any, index: number) => (
                                                  <div key={index}>
                                                    <h3 className="font-14 font-semibold label-color w-100 my-2 pt-3">
                                                      {item.applicationName} ({item.CompletedTasks}/{item.TotalTasks})
                                                    </h3>
                                                    <div className="d-flex align-items-center position-relative">
                                                      <div className="progress custom-progress w-100 me-2 p-0">
                                                        <div
                                                          className="progress-bar custom-progress-bar progress-bar-bg position-relative"
                                                          role="progressbar"
                                                          style={{ width: `${item.CompletionPercentage}%` }}
                                                          aria-valuenow={item.CompletionPercentage}
                                                          aria-valuemin={0}
                                                          aria-valuemax={100}
                                                        >
                                                          <span className="p-1 px-2 progress-percentage border-radius-4 dark-violet text-white font-semibold font-8 border-radius-4">
                                                            {item.CompletionPercentage}%
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <span className="font-regular font-10">100%</span>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-6 px-0">
                                      <div className="mt-0 mt-md-5 d-flex">
                                        {/* <div class="pe-4 py-2 border-end d-flex align-items-center"><span class="font-regular font-14 color-grey-v1 me-3">Assessment</span><span class="font-bold font-20">60%</span></div> */}
                                        <div className="ps-0 ps-md-2 py-2 d-flex align-items-center">
                                          <span className="font-regular font-14 color-grey-v1 me-3 text-nowrap">
                                            Parallel Instance
                                          </span>
                                          <span className="d-flex align-items-end">
                                            <span className="font-bold font-20 lh-1">
                                              {availableParallelCount ? availableParallelCount : 0}
                                            </span>
                                            <span className="font-bold font-12 lh-1">
                                              /{totalParallelCount ? totalParallelCount : 0}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-2 ps-0">
                                  <div className="d-flex justify-content-end">
                                    <span className="violet-bg">
                                      <img
                                        src="/images/apps-icon.svg"
                                        alt="Total clients"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7 col-lg-12 col-sm-12 col-md-12">
                            <div className="p-3 card h-100 border border-radius-8 ">
                              <div className="col-md-12 col-sm-12 col-12">
                                <div className="d-md-flex flex-row justify-content-between align-items-center flex-wrap w-md-100 d-block">
                                  <h3 className="font-semibold font-16 primary-color mb-3 mb-md-0 me-0">
                                    Total License
                                  </h3>
                                  <div className="d-flex justify-content-center align-items-center gap-3">
                                    <div className="d-sm-flex align-items-center d-block">
                                      <div className="d-flex align-items-center my-2 my-md-0 order-sm-1">
                                        <div className="align-items-center d-flex align-items-baseline me-3">
                                          <span className="legend-total-revenue legend-total-profit me-2" />
                                          <span className="font-10 font-regular color-grey-v2">
                                            Total
                                          </span>
                                        </div>
                                        <div className="align-items-center d-flex align-items-baseline">
                                          <span className="legend-total-revenue legend-total-expense me-2" />
                                          <span className="font-10 font-regular color-grey-v2">
                                            Remaining
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <img
                                        className="cursor-pointer"
                                        src="/images/expand-icon.svg"
                                        alt="expand-icon"
                                        data-bs-toggle="modal"
                                        data-bs-target="#clientOverviewPopup"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="custom-scroll cust-dashboard-widget-scroll">
                                  {/* start of the pop up */}
                                  <div
                                    className="modal fade"
                                    id="clientOverviewPopup"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="clientOverviewPopupLabel"
                                    aria-hidden="true"
                                  >
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                      <div className="modal-content p-4">
                                        <div className="modal-header border-0 p-0">
                                          <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
                                            <h3 className="font-semibold font-16 primary-color mb-2 mb-md-0">
                                              Total License
                                            </h3>
                                            <div className="d-flex align-items-center">
                                              <div className="d-flex justify-content-center align-items-center me-4">
                                                <div className="align-items-center d-flex align-items-baseline me-3">
                                                  <span className="legend-total-revenue legend-total-profit me-2" />
                                                  <span className="font-10 font-regular color-grey-v2">
                                                    Total
                                                  </span>
                                                </div>
                                                <div className="align-items-center d-flex align-items-baseline">
                                                  <span className="legend-total-revenue legend-total-expense me-2" />
                                                  <span className="font-10 font-regular color-grey-v2">
                                                    Remaining
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <button
                                            type="button"
                                            className="btn-close cus-btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          />
                                        </div>
                                        <div className="modal-body border-0 p-0 pt-2 pb-2">
                                          <div className="custom-scroll cust-dashboard-widget-scroll">
                                            {totalLicense?.length > 0 ? (<ResponsiveContainer width="100%" height={100}>
                                              <BarChart
                                                width={500}
                                                height={300}
                                                data={firstSixData}
                                                barGap={10}
                                                margin={{
                                                  top: 10,
                                                  right: 30,
                                                  left: 20,
                                                  bottom: 5,
                                                }}
                                              >
                                                <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                                                <XAxis dataKey="appName" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
                                                <XAxis dataKey="appName" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
                                                <YAxis 
  axisLine={false} 
  tickLine={false}
  tickFormatter={(value) => Math.round(value / 2).toString()} 
  domain={[0, 'dataMax']}
  allowDecimals={false}
/>
                                                <Tooltip content={<CustomTooltip5 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                                                <Bar dataKey="availableLicense" stackId="a" fill="#8884d8" radius={[3, 3, 3, 3]} />
                                                <Bar dataKey="purchasedLicense" stackId="a" fill="skyblue" radius={[3, 3, 3, 3]} />
                                              </BarChart>
                                            </ResponsiveContainer>) : null
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*end of the pop up */}
                                  {totalLicense?.length > 0 ? (<ResponsiveContainer width="100%" height={100}>
                                    <BarChart
                                      width={500}
                                      height={300}
                                      data={totalLicense}
                                      barGap={10}
                                      margin={{
                                        top: 10,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                      }}
                                    >
                                      <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.3} />
                                      <XAxis dataKey="appName" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
                                      <YAxis 
  axisLine={false} 
  tickLine={false}
  tickFormatter={(value) => Math.round(value / 2).toString()} 
  domain={[0, 'dataMax']}
  allowDecimals={false}
/>
                                      <Tooltip content={<CustomTooltip5 />} contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} labelFormatter={() => ''} cursor={<CustomTooltip1 />} />
                                      <Bar dataKey="availableLicense" stackId="a" fill="#8884d8" radius={[3, 3, 3, 3]} />
                                      <Bar dataKey="purchasedLicense" stackId="a" fill="skyblue" radius={[3, 3, 3, 3]} />
                                    </BarChart>
                                  </ResponsiveContainer>) : (<div className="custom-scroll cust-dashboard-widget-scroll align-self-center d-flex align-items-center justify-content-center">
                                    <div className="pt-4 pb-3 text-center">
                                      <img
                                        src="/images/no-data-found-img.svg"
                                        alt="no-data-found-img"
                                        className="mb-3"
                                      />
                                      <h5 className="font-12 font-bold color-no-rec">No records</h5>
                                      <p className="font-10 font-regular color-no-rec">
                                        There is no record to show you right now
                                      </p>
                                    </div>
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-sm-12 col-md-12 mb-3">
                        <div className="p-3 card h-100 border-radius-8">
                          <div className="d-flex justify-content-between align-items-start">
                            <div>
                              <p className="font-semibold font-16 primary-color mb-0">
                                License Renewal
                              </p>
                              <p className="font-regular font-30 color-black mb-4 pt-2 mb-0">
                                {planName ? planName : <p className="font-regular font-24 color-black mb-3 pt-2 mb-0" >No Records Found</p>}
                              </p>
                              <div className="d-flex align-items-center">
                                <span className="font-regular font-12 color-black me-2">
                                  Plan Expiry:
                                </span>
                                <span className="font-bold font-12"> {planExpiresOn}</span>
                              </div>
                            </div>
                            <span className="violet-bg">
                              <img
                                src="/images/license-renewal.svg"
                                alt="License Renewal"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mb-3 mt-0">
                        <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="font-semibold font-16 primary-color mb-0">
                              Migration
                            </h3>
                          </div>
                          {migration[0]?.length > 0 ? (
                            <div className="row align-items-center">
                              <div className="d-md-flex d-block align-items-center pt-3 pb-2 px-4">
                                <div className="col-lg-6 col-sm-12 col-md-6">
                                  <div className="user-risk-image align-items-center justify-content-center d-flex">
                                    <PieChart width={194} height={194}>
                                      <Pie
                                        data={migration[0]}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={70}
                                        outerRadius={90}
                                        fill="#8884d8"
                                        // paddingAngle={5}
                                        // strokeWidth={4}
                                        label={false}
                                      >
                                        {migration[0]?.map((entry: any, index: any) => (
                                          <Cell
                                            key={`cell-${entry}`}
                                            fill={Clientcolors[index % Clientcolors.length]}
                                            onClick={() => history.push("/Task", {
                                              state: {
                                                status: entry.name.replace(/^\w/, (c: any) => c.toUpperCase()),
                                                type: "Migration"
                                              }
                                            })}
                                          />
                                        ))}
                                      </Pie>
                                      <Tooltip />
                                    </PieChart>
                                  </div>
                                </div>
                                <div className=" col-lg-6 col-sm-12 col-md-6">
                                  <div className="mx-5 px-md-4 custom-scroll cust-progressbar-widget-scroll d-flex flex-column justify-content-center">
                                    {migration[0].map((item: any, index: any) => (
                                      <div
                                        key={index}
                                        className="d-flex align-items-center mt-1 justify-content-between cursor-pointer"
                                      >
                                        <div className="align-items-center d-flex align-items-baseline">
                                          <span
                                            className={`legend legends-cat-${index + 1} me-2 cursor-pointer`}
                                            style={{
                                              backgroundColor: Clientcolors[index % Clientcolors.length],
                                              width: '10px',
                                              height: '10px',
                                              borderRadius: '3px',
                                              marginRight: '5px'
                                            }}
                                          ></span>
                                          <span className="font-10 font-regular color-grey-v2">
                                            {item.name}
                                          </span>
                                        </div>
                                        <div>
                                          <label className="font-12 font-bold color-black">
                                            {item.value}
                                          </label>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row align-items-center">
                              <div className="pt-4 pb-3 text-center">
                                <img
                                  src="/images/no-data-found-img.svg"
                                  alt="no-data-found-img"
                                  className="mb-3"
                                />
                                <h5 className="font-12 font-bold color-no-rec">No Records</h5>
                                <p className="font-10 font-regular color-no-rec">
                                  There is no record to show you right now
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mb-3 mt-0">
                        <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="font-semibold font-16 primary-color mb-0">
                              Assessment
                            </h3>
                          </div>
                          {assessment[0]?.length > 0 ? (
                            <div className="row align-items-center ">
                              <div className="d-md-flex d-block align-items-center pt-3 pb-2 px-4 ">
                                <div className="col-lg-6 col-sm-12 col-md-6">
                                  <div className="user-risk-image align-items-center justify-content-center d-flex">
                                    <PieChart width={194} height={194}>
                                      <Pie
                                        data={assessment[0]}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={70}
                                        outerRadius={90}
                                        fill="#8884d8"
                                        // paddingAngle={5}
                                        // strokeWidth={4}
                                        label={false}
                                      >
                                        {
                                          assessment[0]?.map((entry: any, index: any) => (
                                            <Cell
                                              key={`cell-${entry}`}
                                              fill={Clientcolors[index % Clientcolors.length]} onClick={() => history.push("/task", {
                                                state: {
                                                  status: entry.name?.replace(/^\w/, (c: any) => c.toUpperCase()),
                                                  type: "Assessment"
                                                }
                                              })}
                                            />
                                          ))

                                        }
                                      </Pie>
                                      <Tooltip />
                                    </PieChart>
                                  </div>
                                </div>
                                <div className=" col-lg-6 col-sm-12 col-md-6">
                                  <div className="mx-5 px-md-4 custom-scroll cust-progressbar-widget-scroll d-flex flex-column justify-content-center">
                                    {
                                      assessment[0]?.map((item: any, index: any) => (
                                        <div
                                          key={index}
                                          className="d-flex align-items-center mt-1 justify-content-between cursor-pointer"
                                        >
                                          <div className="align-items-center d-flex align-items-baseline">
                                            <span
                                              className={`legends legend-cat-${index + 1
                                                } me-2 cursor-pointer`}
                                              style={{
                                                backgroundColor:
                                                  Clientcolors[index % Clientcolors.length],
                                                width: '10px', height: '10px', borderRadius: '3px', marginRight: '5px'
                                              }}
                                            />
                                            <span className="font-10 font-regular color-grey-v2">
                                              {item.name}
                                            </span>
                                          </div>
                                          <div>
                                            <label className="font-12 font-bold color-black">
                                              {item.value}
                                            </label>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row align-items-center">
                              <div className="pt-4 pb-3 text-center">
                                <img
                                  src="/images/no-data-found-img.svg"
                                  alt="no-data-found-img"
                                  className="mb-3"
                                />
                                <h5 className="font-12 font-bold color-no-rec">No records</h5>
                                <p className="font-10 font-regular color-no-rec">
                                  There is no record to show you right now
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 mb-3 mt-0">
                        <div className="card h-100 bg-white py-3 px-3 border-radius-8 border ">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h3 className="font-semibold font-16 primary-color mb-0">
                              Tasks
                            </h3>
                            <div>
                              <a className="text-decoration-none">
                                <img
                                  className="cursor-pointer"
                                  src="/images/expand-icon.svg"
                                  alt="expand-icon"
                                  onClick={() => {
                                    history.push('/task')
                                  }}
                                  hidden={noData}
                                />
                              </a>
                            </div>
                          </div>
                          {Object.entries(tasks)
                            .filter(([title, tasksList]) => tasksList[0]?.length > 0)
                            .map(([title, tasksList], index) => (
                              <div
                                key={title}
                                className="table-responsive theme-table bg-white mb-3"
                              >
                                <table className="table table-borderless mb-0">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap table-accordion-head">
                                        <span className="d-flex align-items-center gap-3">
                                          <img
                                            src="/images/table-accordion-arrow.svg"
                                            alt="table-accordion-arrow"
                                            className="cursor-pointer table-accordion-arrow collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#accordion-${index}`} // Unique id for each accordion
                                          />
                                          <span className="ac-heading">{title}</span>{" "}
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        # Task :{" "}
                                        <span className="font-regular">{tasksList[0]?.length}</span>
                                      </th>
                                      <th className="text-nowrap text-end">Application:
                                        {tasksList[0][0]?.environmentType?.toLowerCase() === 'tenant' ?
                                          <img src="/images/tenant.svg" alt="microsoft teams logo" className="ms-1" /> :
                                          <img src="/images/slack-license.svg" alt="slack logo" className="ms-2" />
                                        }

                                        {tasksList[0][0]?.taskType?.toLowerCase() === 'migration' ?
                                          <>
                                            <img src="/images/arrow-left.svg" alt="left arrow" className="ms-1" />
                                            <img
                                              src="/images/tenant.svg"
                                              alt="microsoft teams logo"
                                              className="ms-1"
                                            />
                                          </>
                                          : <></>}

                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="collapse" id={`accordion-${index}`}>
                                      {" "}
                                      {/* Unique id for each accordion */}
                                      <td colSpan={3}>
                                        <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                          <table className="table table-borderless mb-0">
                                            <thead>
                                              <tr>
                                                <th
                                                  className="text-nowrap"
                                                  onClick={() => handleSort("taskName", sortCriteria.order, title)}
                                                >
                                                  Task Name
                                                  <span className="ms-1 cursor-pointer">
                                                    <img
                                                      src={`/images/sort-arrow-${title === sortCriteria.key &&
                                                        sortCriteria.field === "taskName"
                                                        ? sortCriteria.order === "asc"
                                                          ? "up"
                                                          : "down"
                                                        : "down"
                                                        }.svg`}
                                                      alt="sort-arrow"
                                                    />
                                                  </span>
                                                </th>

                                                <th
                                                  className="text-nowrap"
                                                  onClick={() =>
                                                    handleSort("type", sortCriteria.order, title)
                                                  }
                                                >
                                                  Type
                                                  <span className="ms-1 cursor-pointer">
                                                    <img
                                                      src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                        sortCriteria.field === "type"
                                                        ? sortCriteria.order === "asc"
                                                          ? "up"
                                                          : "down"
                                                        : "down"
                                                        }.svg`}
                                                      alt="sort-arrow"
                                                    />
                                                  </span>
                                                </th>
                                                {tasksList[0][0]?.taskType?.toLowerCase() ===
                                                  "migration" && (
                                                    <th
                                                      className="text-nowrap"
                                                      onClick={() =>
                                                        handleSort(
                                                          "application",
                                                          sortCriteria.order,
                                                          title
                                                        )
                                                      }
                                                    >
                                                      Application
                                                      <span className="ms-1 cursor-pointer">
                                                        <img
                                                          src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                            sortCriteria.field === "application"
                                                            ? sortCriteria.order === "asc"
                                                              ? "up"
                                                              : "down"
                                                            : "down"
                                                            }.svg`}
                                                          alt="sort-arrow"
                                                        />
                                                      </span>
                                                    </th>
                                                  )}

                                                <th
                                                  className="text-nowrap"
                                                  onClick={() =>
                                                    handleSort(
                                                      "createdDate",
                                                      sortCriteria.order,
                                                      title
                                                    )
                                                  }
                                                >
                                                  Created Date
                                                  <span className="ms-1 cursor-pointer">
                                                    <img
                                                      src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                        sortCriteria.field === "createdDate"
                                                        ? sortCriteria.order === "asc"
                                                          ? "up"
                                                          : "down"
                                                        : "down"
                                                        }.svg`}
                                                      alt="sort-arrow"
                                                    />
                                                  </span>
                                                </th>
                                                <th
                                                  className="text-nowrap"
                                                  onClick={() =>
                                                    handleSort(
                                                      "modifiedDate",
                                                      sortCriteria.order,
                                                      title
                                                    )
                                                  }
                                                >
                                                  Modified Date
                                                  <span className="ms-1 cursor-pointer">
                                                    <img
                                                      src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                        sortCriteria.field === "modifiedDate"
                                                        ? sortCriteria.order === "asc"
                                                          ? "up"
                                                          : "down"
                                                        : "down"
                                                        }.svg`}
                                                      alt="sort-arrow-down"
                                                    />
                                                  </span>
                                                </th>
                                                <th
                                                  className="text-nowrap"
                                                  onClick={() =>
                                                    handleSort(
                                                      "status",
                                                      sortCriteria.order,
                                                      title
                                                    )
                                                  }
                                                >
                                                  Status
                                                  <span className="ms-1 cursor-pointer">
                                                    <img
                                                      src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                        sortCriteria.field === "status"
                                                        ? sortCriteria.order === "asc"
                                                          ? "up"
                                                          : "down"
                                                        : "down"
                                                        }.svg`}
                                                      alt="sort-arrow-down"
                                                    />
                                                  </span>
                                                </th>

                                                {(tasksList[0][0]?.taskType?.toLowerCase() !==
                                                  "assessment") &&
                                                  <th className="text-nowrap">
                                                    Go-Live Status
                                                    <span className="ms-1 cursor-pointer">
                                                      <img
                                                        src={`/images/sort-arrow-${title == sortCriteria.key &&
                                                          sortCriteria.field === "goLiveStatus"
                                                          ? sortCriteria.order === "asc"
                                                            ? "up"
                                                            : "down"
                                                          : "down"
                                                          }.svg`}
                                                        alt="sort-arrow-down"
                                                      />
                                                    </span>
                                                  </th>}


                                                <th className="text-nowrap text-center">
                                                  Actions
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {tasksList[0].map(
                                                (task: any, index: any) =>
                                                  task && (
                                                    <tr key={index}>
                                                      <td>
                                                        {task.status.toLowerCase() == 'draft' ?
                                                          <span
                                                            className="text-nowrap"
                                                          >
                                                            {(task.taskName?.length > 21) ? (

                                                              <span
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="right"
                                                                data-bs-title="Type Team Site"
                                                                data-bs-original-title=""
                                                                title={task.taskName}
                                                              >
                                                                {task.taskName?.slice(0, 21) + '...'}
                                                              </span>

                                                            ) : task.taskName}
                                                          </span> :
                                                          <a
                                                            href=""
                                                            onClick={() => {
                                                              if (
                                                                task?.taskType?.toLowerCase() ==
                                                                "assessment"
                                                              ) {
                                                                history.push("/Viewassessment", {
                                                                  state: { taskId: task.taskId, taskName: task.taskName },
                                                                });
                                                              }
                                                              if (
                                                                task?.taskType?.toLowerCase() ==
                                                                "migration"
                                                              ) {
                                                                history.push("/ViewMigration", {
                                                                  state: {
                                                                    taskId: task.taskId,
                                                                    taskName: task.taskName
                                                                  },
                                                                });
                                                                //console.log(task.taskId);
                                                              }

                                                            }}
                                                            className="text-nowrap"
                                                          >
                                                            {task.taskName}
                                                          </a>
                                                        }

                                                      </td>
                                                      <td>{task.taskType}</td>
                                                      {task?.taskType?.toLowerCase() ==
                                                        "migration" && (
                                                          <td>{task.applicationName}</td>
                                                        )}
                                                      <td>
                                                        {moment(task.createdDate).format(
                                                          "YYYY/MM/DD HH:mm"
                                                        )}
                                                      </td>
                                                      <td>
                                                        {task.modifiedDate == null ? (
                                                          <span className="align-items-center">
                                                            -
                                                          </span>
                                                        ) : (
                                                          moment(task.modifiedDate).format(
                                                            "YYYY/MM/DD HH:mm"
                                                          )
                                                        )}
                                                      </td>
                                                      <td style={{ textTransform: "capitalize" }}>
                                                        <span className="text-nowrap">
                                                          <span
                                                            className={`table-status ${getStatusCSS(
                                                              task.status.toLowerCase()
                                                            )} d-inline-block me-2 `}
                                                          ></span>
                                                          {task?.status?.toLowerCase()?.includes('progress') ? 'Inprogress' : task?.status}
                                                        </span>
                                                      </td>

                                                      {(task?.taskType?.toLowerCase() !==
                                                        "assessment") &&
                                                        <td>
                                                          <span className="d-flex align-items-center gap-4 thread">
                                                            <span
                                                              className="table-status success completed d-inline-block me-2"
                                                              data-bs-toggle="tooltip"
                                                              title="Member migration"
                                                            />
                                                            <span
                                                              className="table-status danger  d-inline-block me-2"
                                                              data-bs-toggle="tooltip"
                                                              title="Member migration"
                                                            />
                                                          </span>
                                                        </td>
                                                      }

                                                      <td className="text-center" >
                                                        <span className="action-drop-down "
                                                        >
                                                          <img
                                                            src="/images/keb-menu-icon.svg"
                                                            alt="keb-menu-icon"
                                                            data-bs-toggle="dropdown"
                                                            className="p-2 action-icon rounded-3"

                                                            aria-disabled={task.status.toLowerCase() == 'cancelled' || (task?.taskType?.toLowerCase() ==
                                                              "assessment" && task.status.toLowerCase() == 'completed')}
                                                          />
                                                          <span className="dropdown-menu font-regular font-14 p-1">
                                                            {(task.status.replace(/\s+/g, '').toLowerCase() == 'inprogress' || task.status.toLowerCase() == 'draft' || task.status.toLowerCase() == 'paused') &&
                                                              <span
                                                                // style={role.toLowerCase() !== "viewer" ? {border: "none"} :{
                                                                //   border: "none",
                                                                //   cursor:"default",                                                    
                                                                //   backgroundColor:"#F2F2EE"
                                                                // }}

                                                                onClick={() => {

                                                                  // if (task.status === "inprogress") {
                                                                  //   navigate("/table");
                                                                  // } else if (task.status === "draft") {
                                                                  //if(role.toLowerCase() !== "viewer")
                                                                  getLastVisited(task);
                                                                  // }
                                                                }}
                                                                className="d-flex list"
                                                              >
                                                                <span
                                                                  aria-disabled={task.status !== "draft" && task.status.replace(/\s+/g, '').toLowerCase() !== "inprogress"}
                                                                  style={{ border: "none" }}
                                                                  className="d-flex gap-2 px-3 py-2"
                                                                >
                                                                  <img src="/images/edit-icon.svg" alt="Edit" />
                                                                  Edit
                                                                </span>
                                                              </span>

                                                            }


                                                            {task?.taskType?.toLowerCase() !==
                                                              "assessment" ? (
                                                              <>
                                                                {task.status.toLowerCase() ==
                                                                  "completed" && task.isDeltamigrationCompleted ? (
                                                                  <>
                                                                    <span
                                                                      className="d-flex gap-2 px-3 py-2 list"
                                                                    // style={{
                                                                    //   cursor: "default",

                                                                    //   backgroundColor: "#F2F2EE",
                                                                    // }}
                                                                    >
                                                                      <span
                                                                      // aria-disabled={task.status.toLowerCase() !==
                                                                      //   "completed" ||
                                                                      //   role == "viewer"}
                                                                      // style={{
                                                                      //   cursor: "default",

                                                                      //   backgroundColor: "#F2F2EE",
                                                                      // }}
                                                                      >
                                                                        <img src="/images/go-live-action-icon.svg" />
                                                                        Go-Live
                                                                      </span>
                                                                    </span>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <span
                                                                      className="d-flex gap-2 px-3 py-2 list"
                                                                      style={{
                                                                        cursor: "default",

                                                                        backgroundColor: "#F2F2EE",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        aria-disabled={true}
                                                                        style={{
                                                                          cursor: "default",

                                                                          backgroundColor: "#F2F2EE",
                                                                        }}
                                                                      >
                                                                        <img src="/images/go-live-action-icon.svg" />
                                                                        Go-Live
                                                                      </span>
                                                                    </span>
                                                                  </>
                                                                )}

                                                              </>) : <></>}


                                                            {task?.taskType?.toLowerCase() !==
                                                              "assessment" ? (
                                                              <>
                                                                {task.status.toLowerCase() !==
                                                                  "completed" || role !== 'viewer' ? (
                                                                  <span
                                                                    className="d-flex gap-2 px-3 py-2 list"
                                                                    style={{
                                                                      border: "none",
                                                                      cursor:
                                                                        task.status.toLowerCase() !==
                                                                          "completed" ||
                                                                          role == "viewer"
                                                                          ? "default"
                                                                          : "pointer",
                                                                      backgroundColor:
                                                                        task.status.toLowerCase() !==
                                                                          "completed" ||
                                                                          role === "viewer"
                                                                          ? "#F2F2EE"
                                                                          : "white",
                                                                    }}
                                                                  >
                                                                    <img src="/images/delta-mig-icon.svg" />
                                                                    Delta migration
                                                                  </span>
                                                                ) : (
                                                                  <span className="d-flex gap-2 px-3 py-2 list">
                                                                    <span
                                                                      onClick={() => {
                                                                        if (task.status.toLowerCase() !== "completed" || role === 'viewer') {
                                                                          return; // Do nothing if task is not completed or role is viewer
                                                                        }

                                                                        else if (task?.taskType?.toLowerCase() === "migration") {
                                                                          deltamigration(task);

                                                                        }
                                                                      }}

                                                                    >
                                                                      <img src="/images/delta-mig-icon.svg" />
                                                                      Delta migration
                                                                    </span>
                                                                  </span>
                                                                )}

                                                              </>) : <></>}

                                                            {(task?.taskType?.toLowerCase() ==
                                                              "assessment" && (task?.status?.toLowerCase() == "paused" || task.status.replace(/\s+/g, '').toLowerCase() == "inprogress")) &&
                                                              <>
                                                                {task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" ? (
                                                                  <span className="d-flex gap-2 px-3 py-2 list"
                                                                    style={role.toLowerCase() !== "viewer" ? { border: "none" } : {
                                                                      border: "none",
                                                                      cursor: "default",
                                                                      backgroundColor: "#F2F2EE"
                                                                    }}

                                                                  >
                                                                    <span

                                                                      onClick={() => {
                                                                        if (role.toLowerCase() !== "viewer")
                                                                          userTaskAction(task.taskId, 'Paused')
                                                                      }
                                                                      }
                                                                    >
                                                                      <img
                                                                        src="/images/pause-icon.svg"
                                                                        alt="pause"
                                                                        style={{ marginRight: "10px" }}
                                                                      />
                                                                      Pause
                                                                    </span>
                                                                  </span>
                                                                ) : (
                                                                  <span className="d-flex gap-2 px-3 py-2 list"
                                                                    style={role.toLowerCase() !== "viewer" ? { border: "none" } : {
                                                                      border: "none",
                                                                      cursor: "default",
                                                                      backgroundColor: "#F2F2EE"
                                                                    }}

                                                                  >
                                                                    <span

                                                                      onClick={() => {
                                                                        if (role.toLowerCase() !== "viewer")
                                                                          userTaskAction(task.taskId, 'Inprogress');
                                                                      }}
                                                                    >
                                                                      <img src="/images/pause-icon-2.svg"
                                                                        alt="in-progress" />
                                                                      Resume
                                                                    </span>
                                                                  </span>

                                                                )}
                                                              </>
                                                            }

                                                            {!(task.status.replace(/\s+/g, '').toLowerCase() == "inprogress" || task.status.toLowerCase() == "paused") || role.toLowerCase() == "viewer" ?
                                                              <></>
                                                              : (
                                                                <span
                                                                  onClick={() => {
                                                                    setCancelFilter(true);
                                                                    setCancelTaskId(task.taskId);
                                                                  }}
                                                                  className="d-flex gap-2 px-3 py-2 list"
                                                                >
                                                                  <img src="/images/cancel-action-icon.svg" />
                                                                  Cancel
                                                                </span>
                                                              )}

                                                            {task?.taskType?.toLowerCase() ==
                                                              "assessment" && task?.status?.toLowerCase() !== 'draft' ? (
                                                              <span className="d-flex gap-2 px-3 py-2 list">
                                                                <span
                                                                  onClick={() => {

                                                                    history.push("/AssessmentSummary", {
                                                                      state: { taskId: task.taskId, taskName: task.taskName, taskType: task.taskType },
                                                                    });

                                                                  }
                                                                  }

                                                                >
                                                                  <img src="/images/delta-mig-icon.svg" />
                                                                  Summary
                                                                </span>
                                                              </span>)
                                                              : <></>}

                                                          </span>
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  )
                                              )}
                                            </tbody>
                                          </table>
                                        </span>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            ))}
                          {noData ? (
                            <div className="pt-4 pb-3 text-center">
                              <img
                                src="/images/no-data-found-img.svg"
                                alt="no-data-found-img"
                                className="mb-5"
                              />
                              <h5 className="font-12 font-bold color-no-rec">{totalCount > 0 ? 'No Record' : 'No Data'}</h5>
                              <p className="font-10 font-regular color-no-rec">
                                {totalCount > 0 ? 'There is no Record to show you right now' : 'There is no data to show you right now'}
                              </p>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Slack tab content ends here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      }
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                {alertMessage}
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }

                  setAlert(false)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {loader && (
        <div className="overlay">
          <div className="position-absolute top-50 start-50 translate-middle">
            <div
              className="spinner-border Loader loader-color align-center "
              role="status"
            >
              <span>
                <span className="loader-inner-circle" />
              </span>
            </div>
            <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
              Loading..
            </p>
          </div>
        </div>
      )}
      {cancelFilter ? (
        <div
          className="modal fade show"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
          id="Alert-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="Alert-popupLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img
                    src="/images/failure-popup-icon.svg"
                    alt="Alert-popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Alert-popupLabel"
                >
                  Alert
                </h5>
                <p className="primary-data font-16">
                  The progress initiated in migration will not be
                  saved. Are you sure you want to cancel
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  onClick={() => {
                    setCancelFilter(false);
                  }}
                  type="button"
                  className="btn secondary-btn font-14 px-4 font-semibold me-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    setCancelFilter(false);
                    userTaskAction(cancelTaskId, 'Cancelled');
                  }}
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

    </>

  )
}