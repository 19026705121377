export let BE_URL = process.env.REACT_APP_BE_URL;
// export let BE_URL = "http://localhost:8080";



export const ADMIN_BE_URL = process.env.REACT_APP_BE_URL;

//export let BE_URL = 'http://localhost:8080';

export const Onlymail = (value: any) => {
    let emailValid = value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    let checkValid = emailValid ? true : false
    return !checkValid
}

export const isValidPhoneNumber = (phoneNumber: any) => {
    // Define the universal minimum length for phone numbers
    const minPhoneNumberLength: number = 5;
    const maxPhoneNumberLength: number = 15;
    const isLongEnough = phoneNumber.length >= minPhoneNumberLength;
    const isShortEnough = phoneNumber.length <= maxPhoneNumberLength;
    return isLongEnough && isShortEnough;

};


export const taskGrid = {
    getTaskData: "/task/tasks/taskDetails",
    updateTaskAction: "/task/tasks/updateTaskAction",
    updateLastVisitedPage: "/task/tasks/lastVisited",
    updateModifiedDate: "/task/tasks/updateModifiedDate",
    deltaMigration: '/tasks/deltaMigration',
    clientTaskCOunt: '/task/tasks/getTaskCount'
};

export const overallAssessment = {
    getAssessmentSummary: "/task/assessment/getAssessmentSummary",

}

export const appSpecificConfig = {
    postOutlookCustomization: "/configuration/appSpecificConfig/postOutlookCustomization",
    fetchUserOptionsFromDatabase: "/configuration/appSpecificConfig/fetchUserOptionsFromDatabase",
}


export const assessmentGrid = {
    fetchApplicationNames: "/task/viewAssesment/fetchApplicationNames",
    getUserMailboxData: "/task/viewAssesment/getUserMailboxData",
    getUserAppDetailsData: "/task/viewAssesment/getUserAppDetailsData",
    getSummary: "/task/viewAssesment/getSummary",
    cancelAction: "/task/viewAssesment/cancelAction",
    retryAction: "/task/viewAssesment/retryAsssessment",
    
};

export const viewMigration = {
    getlook: '/task/viewMigration/getMigrationTypeForOutlookUser',
    userActions: '/task/Migration/UpdateUserActionMailbox',
    fetchoutlook: '/task/viewMigration/fetchoutlookData',
    getReport: '/task/viewMigration/generateOutlookMigrationReport'
}


// Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const PasswordRegex = (value: any) => {
    let passwordValid: any = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/)
    let checkValid: boolean = passwordValid ? true : false
    return !checkValid
}

export const profile: any = {
    getClientData: '/mcr-svc-coll/profile/getClientData',
    updateProfile: '/mcr-svc-coll/profile/updateprofile',
    updatePassword: '/mcr-svc-coll/profile/updatepassword',
    handleuser: '/mcr-svc-coll/profile/handlesecondaryuser',
    sendmail: '/mcr-svc-coll/profile/sendInviteMail'
}



export const dashboard = {
    getAdminCards: "/mcr-svc-coll/dashboard/getAdminCards",
    getLicensRenewal: "/mcr-svc-coll/dashboard/getAdminLicenseRenewal",
    getClientDashboard: "/mcr-svc-coll/dashboard/fetchUserDashBoard",
};

export const contactus = {
    getCategory: "/mcr-svc-coll/contactus/getCategory",
    getAllFaq: "/mcr-svc-coll/contactus/getAllFaq",
    addComments: "/mcr-svc-coll/contactus/addComments",
    addQuestions: "/mcr-svc-coll/contactus/addQuestions",
    deleteQuestions: "/mcr-svc-coll/contactus/deleteQuestions",
};


// export const HelpAndDocumentation = {
//     getGuideAndVideoUrl: "/helpAndDocumentation/getGuideAndVideoData",
//     deleteGuideOrVideoUrl: "/helpAndDocumentation/deleteGuideOrVideo",
//     getCategoryUrl: "/helpAndDocumentation/getCategory",
//     uploadGuideToBlobUrl: "/helpAndDocumentation/uploadGuideToBlob",
//     blobTokenUrl: "/helpAndDocumentation/getBlobAccessToken",
//     addVideoOrEditVideoUrl: "/helpAndDocumentation/addVideoOrEditVideo",
// };

export const HelpAndDocumentation = {
    getGuideAndVideoUrl: "/mcr-svc-coll/helpAndDocumentation/getGuideAndVideoData",
    deleteGuideOrVideoUrl: "/mcr-svc-coll/helpAndDocumentation/deleteGuideOrVideo",
    getCategoryUrl: "/mcr-svc-coll/helpAndDocumentation/getCategory",
    uploadGuideToBlobUrl: "/mcr-svc-coll/helpAndDocumentation/uploadGuideToBlob",
    addVideoOrEditVideoUrl: "/mcr-svc-coll/helpAndDocumentation/addVideoOrEditVideo",
    blobTokenUrl: "/mcr-svc-coll/helpAndDocumentation/getBlobAccessToken",
};

export const commonErrorMsgs = {
    serverDownError: "Server is down",
    invalidTokenError: "User token is invalid",
    invalidPayloadError: "Invalid payload provided",
    generalError: "Something went wrong! Please try again or reach AVAMIGRATRON Support.",
    noRecords: "No Records Found",
    noData: "No Data Found"
};

export const fileErrors = {
    formatError: "Please upload a PDF file",
    duplicateError: "File already exist",
    sizeError: "Please ensure that the file size is less than 5 MB.",
};

export const videoErrors = {
    invalidUrlFormat: "Enter valid URL",
    mandatory: "This field is mandatory",
    maxLength: "This field accepts only 255 characters",
    characterLimit: "Character limit shouldn't exceed 256 characters",
    duplicateError: "Video already exist",
};




// export const HelpAndDocumentation = {
//     getGuideAndVideoUrl: "/common/helpAndDocumentation/getGuideAndVideoData",
//     deleteGuideOrVideoUrl: "/common/helpAndDocumentation/deleteGuideOrVideo",
//     getCategoryUrl: "/common/helpAndDocumentation/getCategory",
//     uploadGuideToBlobUrl: "/common/helpAndDocumentation/uploadGuideToBlob",
//     addVideoOrEditVideoUrl: "/common/helpAndDocumentation/addVideoOrEditVideo",
// };
export const feedbackEndpoint = {
    getFeedbacks: "/mcr-svc-coll/feedback/getFeedback",
    postFeedback: "/mcr-svc-coll/feedback/postFeedback",
};



// PS_EX_02: Declare a property for SelectApps called getAppsUrl with interface string and assign it with the value "/task/SelectApps/getApps"
// PS_EX_03: Declare a property for SelectApps called getSelectedAppsUrl with interface string and assign it with the value "/task/SelectApps/getSelectedApps"
// PS_EX_04: Declare a property for SelectApps called startAssessmentUrl with interface string and assign it with the value "/task/SelectApps/startAssessment"
// PS_EX_05: Declare a property for SelectApps called customExportAndImportUrl with interface string and assign it with the value "/task/SelectApps/customExportAndImport"
// export const SelectAppsUrls = {
//     getAppsUrl: "/SelectApps/getApps",
//     getSelectedAppsUrl: "/SelectApps/getSelectedAppsForAssessment",
//     startAssessmentUrl: "/SelectApps/startAssessment",
//     customExportAndImportUrl: "/SelectApps/customExportAndImport",
// };

export const SelectAppsUrls = {
    getAppsUrl: "/mcr-svc-coll/SelectApps/getApps",
    getSelectedAppsUrl: "/mcr-svc-coll/SelectApps/getSelectedAppsForAssessment",
    startAssessmentUrl: "/mcr-svc-coll/SelectApps/startAssessment",
    customExportAndImportUrl: "/mcr-svc-coll/SelectApps/customExportAndImport",
};


// PS_EX_06: Declare a variable called outlookExportTemplate with properties like {"User Name": "", "User Email": ""}
export const outlookExportTemplate: { [key: string]: string } = {
    "User Name": "",
    "User Email": "",
};

export const userMappingExportTemplate: { [key: string]: string } = {
    'Source Username': "",
    'Source Email Address': "",
    'Source Type': "",
    'Target Username': "",
    'Target Email Address': "",
    'Target Type': ""
};

// PS_EX_07: Declare a variable called importMsgs and have the following properties:
// invalidColumns: "Please enter vaild column details",
// duplicateValues: "Contains duplicate data",
// emptyValues: "Empty values are not accepted"
export const importMsgs = {
    invalidColumns: "Please enter vaild column details",
    duplicateValues: "Contains duplicate data",
    emptyValues: "Empty values are not accepted",
    invalidFile: "Please Upload a valid csv file"
};



export const connection = {
    getConnections: "/task/tasks/getConnections",
    getConnectionEdit: "/task/tasks/getConnectionEdit",
    checkAndCreateTask: "/task/tasks/checkAndCreateTask"
};

export const Admin: object | any = {
    getAdminClientpath: '/adminbe/admin/getAdminClientData',
    handleClient: '/adminbe/admin/handleClient',
    sendMail: '/adminbe/admin/sendMail',
    update: '/adminbe/admin/updateSeverdown',
    getSession: '/adminbe/admin/redirecttoClient',
    updateServerdown: '/adminbe/admin/updateServerdown'

}

export const defaultAddress = "Avasoft, Beta Block, Alpha City It Park, Navalur, Tamil Nadu 600130"

export const appDescripions: any = {
    groups: 'The groups application in Microsoft 365 allows users to manage, and collaborate within groups, enabling streamlined communication, file sharing and task management across teams and projects.',
    outlook: 'User Mailbox in Microsoft 365 is an email associated with an individual user, allowing them to send, receive, and manage emails using Exchange Online Services.',
    sharedmailbox: 'Microsoft 365 shared mailbox enables multiple users to collectively access and manage emails for collaborative work or specialized tasks like customer support.',
    roomsandequipments: 'Rooms & Equipment in Microsoft 365 are resources for booking meetings or events, like conference rooms or projectors , aiding efficient resource management and scheduling.',
    teamschannel: 'A Teams channel is a dedicated space within a Microsoft Teams workspace where team members can collaborate, communicate and share files on specific topics or projects.',
    teamschat: 'Real-time messaging for one-on-one or group conversations within Microsoft Teams, facilitating text-based communication, file sharing and collaboration.',
    onedrive: 'Cloud storage service by Microsoft for storing, sharing and collaborating on files and documents securely from anywhere.',
    sharepoint: 'Collaboration platform by Microsoft for creating websites, managing content and sharing information with organization.'
}


export const helpAndDocumentation = {
    saasToken: "/admin/adminHelpAndDocumentation/getAccessData",
}

export function isvalidSecret(inputString: any) {
    let regexItem = /^[a-zA-Z0-9~._-]*$/;
    return regexItem.test(inputString);
}

export function isValidTenantID(id: any) {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(id);
}

export function isValidEmail(email: any) {
    let emailValid = email.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    let checkValid = emailValid ? true : false
    return !checkValid
}