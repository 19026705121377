//PS_03 PS_04 PS_05 PS_06 PS_07 PS_08 PS_09 PS_10 PS_11 PS_12 PS_13 PS_14 
// adminClient.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getAdminClient, handleClient } from '../../Services/manageClientAPI';
import { getClientData } from '../../Services/profileService';
import { ClientData, ErrorMessages, MyState, OptionType, sessionResponse } from '../../Common/Interface';
import objCryptoJS from "crypto-js";
import { decodeSessionDetails } from '../../Services/CommonServices';
import { Country } from 'country-state-city';
import { countries, TCountries } from 'countries-list';
import Select from "react-select"
import Loader from '../Common/Loader';
// import { Country, State, City } from 'country-state-city';
// import Select from 'react-select';
// import Tippy, { followCursor } from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
// import { ClientResponseType, AdminResponseType } from './interface';
// import Loader from './LoaderComponent';
// import CryptoJS from 'crypto-js';
// import { client as clientFunction } from './client';
// import { adminEndpoints as admin, BEURL } from './constants';



const AdminClient: React.FC = () => {

  //PS_16 PS_17 PS_18 PS_19 PS_20 PS_21 PS_22 PS_23 PS_24 PS_25 PS_26 PS_27 PS_28 PS_29 
  //PS_30 PS_31 PS_32 PS_33 PS_34 PS_35 PS_36 PS_37 	PS38 	PS39 	PS40 

  const history = useHistory();
  const location: any = useLocation()?.state
  const [ctoken, setCToken] = useState<null | string>(null);
  const [clientName, setClientName] = useState("");
  const [mailId, setMailId] = useState("");
  const [role, setRole] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState(new Date());
  const [action, setAction] = useState("");

  const [atoken, setAToken] = useState<null | string>(null);
  const [adminName, setAdminName] = useState("");
  const [adminMailId, setAdminMailId] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [successPopupType, setSuccessPopupType] = useState('')

  let ClientObj = {
    userName: '',
    companyName: '',
    phone: '',
    registrationType: 'SSO',
    email: '',
    authenticationType: 'Tenant',
    status: 'InActive',
    phonecode: "+91"
  }

  let errClientObj: ErrorMessages = {
    errUsername: '',
    errCompanyName: '',
    errPhone: '',
    errRegistrationType: '',
    errEmail: '',
    errAuthenticationType: '',
    status: ''

  };
  let erreditClientObj: ErrorMessages = {
    errUsername: '',
    errCompanyName: '',
    errPhone: '',
    errRegistrationType: '',
    errEmail: '',
    errAuthenticationType: '',
    status: ''

  };
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [clientData, setClientData] = useState(ClientObj);
  const [editClientData, setEditClientData] = useState(ClientObj);
  const [erroreditMessage, seterroreditMessage] = useState(erreditClientObj)
  const [basicCountryCodes, setBasicCountryCodes] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>(errClientObj);
  const [popupmessage, setpopupmessage] = useState("");
  const [sortValue, setSortValue] = useState("timeStamp");
  const [sortHide, setSortHide] = useState("");
  const [sortType, setSortType] = useState("desc");

  const [Invoice, setInvoice] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [invSearchValue, setInvSearchValue] = useState('');
  const [auditSearchValue, setAuditSearchValue] = useState('');
  const [enable, setEnablebutton] = useState(false)
  const [loader, setLoader] = useState(false)
  const [alertPopup, setAlertPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);



  //PS_41 PS_42
  useEffect(() => {
    // Assume resetSessionTimer() and addEventListener() are defined in CommonServices.ts
    // window.addEventListener('event-name', resetSessionTimer);
    // return () => window.removeEventListener('event-name', resetSessionTimer);

    fetchBasicCountryCodes()
    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
      // resetSessionTimer();
      sessionTokenProperties()
      getClient()
      //   return () => {
      // window.removeEventListener('click', resetSessionTimer);
      // window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
    else {
      sessionTokenProperties()
      getClient();

      //   return () => {
      //     window.removeEventListener('click', resetSessionTimer);
      //     window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
  }, []); // Empty dependency array means it only runs once on component mount



  //CommonFunctionS:
  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminMailId(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
          setRole(tokenResponse.adminDetails.Role)
          setAdminMailId(tokenResponse.adminDetails.Email)

        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setClientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          setRole(tokenResponse.clientDetails.Role);
          setMailId(tokenResponse.clientDetails.Email)
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    // debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(clientToken)
          token = {
            clientToken: clientToken
          }
        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(adminToken, 'sssssssssssssssssss');
          setAToken(adminToken)
          token = {
            adminToken: adminToken
          }
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  const fetchBasicCountryCodes = async () => {
    const allCountries = await Country.getAllCountries();
    const basicCodes: any = allCountries.filter(country => {
      // Define the criteria for basic country codes.
      // For example, you might consider countries with phone codes less than 100.
      return parseInt(country.phonecode) < 100;
    });
    // Sort the basic country codes alphabetically
    basicCodes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setBasicCountryCodes(basicCodes);
  };
  const countryOptions: { value: string, label: string }[] = Object.keys(countries).map((key) => ({
    value: '+' + countries[key as keyof TCountries].phone,
    label: `${countries[key as keyof TCountries].name} (+${countries[key as keyof TCountries].phone})`,
  }));


  //PS_95 PS_96 PS_97 PS_98 PS_99 PS_100 PS_101 PS_102 PS_103 PS_104 PS_105 PS_106 
  //	PS107 	PS108 	PS109 	PS110 	PS111 	PS112 	PS113 	PS114 	PS115 	PS116  
  const getClient = async () => {
    try {
      setLoader(true)
      let payload = {
        clientId: location?.state?.id,
        token: await getToken()
      };
      const response: any = await getClientData(payload);
      //console.log(response);
      setpopupmessage(response?.data?.statusMessage);
      if (response?.data?.statusCode !== 200) {
        setLoader(false)
        setAlertPopup(true)
      } else {
        setLoader(false)
        // setClientData(response?.data?.data?.ClientData);
        setEditClientData((prevState) => ({
          ...prevState,
          userName: response?.data?.data?.ClientData[0]?.userName,
          email: response?.data?.data?.ClientData[0]?.mailId,
          companyName: response?.data?.data?.ClientData[0]?.company || "Avasoft",
          registrationType:
            response?.data?.data?.ClientData[0].isSSOAuthentication === true
              ? "SSO"
              : "Email",
          phone: response?.data?.data?.ClientData[0]?.phoneNumber

        }));
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  //PS_64 PS_65 PS_66 PS_67 PS_68 PS_69 PS_70 PS_71 PS_72 PS_73 PS_74 PS_75 PS_76 PS_77 PS_78 	PS79 	PS80 	PS81 	
  //PS82 	PS83 	PS84 	PS85 	PS86 	PS87 	PS88 	PS89 	PS90  	PS91  	PS92  	PS93  	PS94 
  const handleFieldValidation = (id: string, value: string, userAction: string) => {
    debugger
    if (userAction === 'new') {
      let result = true
      if (id === 'user-name') {
        setClientData((prevState) => ({ ...prevState, userName: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errUsername: 'Please enter the value' });
          result = false
        } else if (value.length > 80) {
          setErrorMessages({ ...errorMessages, errUsername: 'Maximum value count exceeded' });
          result = false
        }
        else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
          setErrorMessages((prevState) => ({
            ...prevState,
            erruserName: "Username cannot contain special characters.",
          }));
          result = false;
        }
        else {
          setErrorMessages({ ...errorMessages, errUsername: '' });
     
        }
      } else if (id === 'company-name') {
        setClientData((prevState) => ({ ...prevState, companyName: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errCompanyName: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        } else if (value.length > 80) {
          setErrorMessages({ ...errorMessages, errCompanyName: 'Maximum value count exceeded' });
          result = false
          return 'Maximum value count exceeded';
        }
        else {
          setErrorMessages({ ...errorMessages, errCompanyName: '' });
        
        }
      } else if (id === 'ph-no') {
        setClientData((prevState) => ({ ...prevState, phone: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errPhone: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        } else if (value.length > 15) {
          setErrorMessages({ ...errorMessages, errPhone: 'Maximum value count exceeded' });
          result = false
          return 'Maximum value count exceeded';
        } else if (!/^[0-9]{5,15}$/.test(value)) {
          setErrorMessages({ ...errorMessages, errPhone: 'Provide valid phone number' });
          result = false
          return 'Provide valid phone number';
        }
        else {
          setErrorMessages({ ...errorMessages, errPhone: '' });
          
        }
      } else if (id === 'registrationType') {
        setClientData((prevState) => ({ ...prevState, registrationType: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errRegistrationType: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        }
        else {
          setErrorMessages({ ...errorMessages, errRegistrationType: '' });
         
        }
      } else if (id === 'email-address') {
        setClientData((prevState) => ({ ...prevState, email: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errEmail: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        } else if (value.length > 320) {
          setErrorMessages({ ...errorMessages, errEmail: 'Maximum value count exceeded' });
          result = false
          return 'Maximum value count exceeded';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          setErrorMessages({ ...errorMessages, errEmail: 'Provide valid email' });
          result = false
          return 'Provide valid email';
        }
        else {
          setErrorMessages({ ...errorMessages, errEmail: '' });
       
        }
      } else if (id === "environmenttype") {
        setClientData((prevState) => ({ ...prevState, authenticationType: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errAuthenticationType: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        }
        else {
          setErrorMessages({ ...errorMessages, errAuthenticationType: '' });
         
        }
      }
      else if (id === "status") {
        setClientData((prevState) => ({ ...prevState, status: value }));
        if (!value) {
          setErrorMessages({ ...errorMessages, errAuthenticationType: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        }
        else {
          setErrorMessages({ ...errorMessages, errAuthenticationType: '' });
         
        }
      }

      
      setEnablebutton(result)

    } else if (userAction === 'edit') {
      let result = true
      if (id === 'user-name') {
        setEditClientData((prevState) => ({ ...prevState, userName: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errUsername: 'Please enter the value' });
          result = false
        } else if (value.length > 80) {
          seterroreditMessage({ ...errorMessages, errUsername: 'Maximum value count exceeded' });
          result = false
        }
        else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
          seterroreditMessage((prevState) => ({
            ...prevState,
            erruserName: "Username cannot contain special characters.",
          }));
          result = false;
        }
        else {
          seterroreditMessage({ ...errorMessages, errUsername: '' });
        }
      } else if (id === 'company-name') {
        setEditClientData((prevState) => ({ ...prevState, companyName: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errCompanyName: 'Please enter the value' });
          result = false
        } else if (value.length > 80) {
          seterroreditMessage({ ...errorMessages, errCompanyName: 'Maximum value count exceeded' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errCompanyName: '' });
        }
      } else if (id === 'ph-no') {
        setEditClientData((prevState) => ({ ...prevState, phone: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errPhone: 'Please enter the value' });
          result = false
        } else if (value.length > 15) {
          seterroreditMessage({ ...errorMessages, errPhone: 'Maximum value count exceeded' });
          result = false
        } else if (!/^[0-9]{5,15}$/.test(value)) {
          seterroreditMessage({ ...errorMessages, errPhone: 'Provide valid phone number' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errPhone: '' });
        }
      } else if (id === 'registrationType') {
        setEditClientData((prevState) => ({ ...prevState, registrationType: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errRegistrationType: 'Please enter the value' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errRegistrationType: '' });
        }
      } else if (id === 'email-address') {
        setEditClientData((prevState) => ({ ...prevState, email: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errEmail: 'Please enter the value' });
          result = false
        } else if (value.length > 320) {
          seterroreditMessage({ ...errorMessages, errEmail: 'Maximum value count exceeded' });
          result = false
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          seterroreditMessage({ ...errorMessages, errEmail: 'Provide valid email' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errEmail: '' });
        }
      } else if (id === 'authenticationType') {
        setEditClientData((prevState) => ({ ...prevState, authenticationType: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errAuthenticationType: 'Please enter the value' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errAuthenticationType: '' });
        }
      } else if (id === "status") {
        setEditClientData((prevState) => ({ ...prevState, status: value }));
        if (!value) {
          seterroreditMessage({ ...errorMessages, errAuthenticationType: 'Please enter the value' });
          result = false
        }
        else {
          seterroreditMessage({ ...errorMessages, errAuthenticationType: '' });
        }
      }
      setEnablebutton(result)

    }
  };

  const handleCountryChange = (selectedOption: any) => {
    debugger
    const countryCode = selectedOption.value;
    setClientData((prevDetails) => ({
      ...prevDetails,
      phonecode: countryCode,
    }));
    setEditClientData((prevDetails) => ({
      ...prevDetails,
      phonecode: countryCode,
    }));
  };


  //PS_95 PS_96 PS_97 PS_98 PS_99 PS_100 PS_101 PS_102 PS_103 PS_104 PS_105 PS_106 	PS107 	PS108 	PS109 	PS110 	PS111 	PS112 	PS113 	PS114 	PS115 	PS116  	PS117  	
  //PS118  	PS119  	PS120  	PS121  	PS122  	PS123  	PS124  	PS125   	
  const handleClientdata = async (e: any, userAction: string) => {
    debugger
    try {
      let payload: object;
    //  handleFieldValidation(e.target.id, e.target.value, userAction)
      // Depending on the action, we prepare payload and use different endpoints
      if (errClientObj.errUsername === '' && errClientObj.errAuthenticationType === '' && errClientObj.errCompanyName === '' &&
        errClientObj.errEmail === '' && errClientObj.errPhone === '' && errClientObj.errRegistrationType === '' && errClientObj.status === ''
      ) {
        if (userAction === 'new') {

          try {
            debugger;
            setLoader(true)
            payload = {
              token: await getToken(),
              userName: clientData.userName,
              companyName: clientData.companyName,
              email: clientData.email,
              phonenumber: clientData.phone,
              phonecode: clientData.phonecode,
              status: clientData.status,
              registrationType: clientData.registrationType,
              authenticationType: clientData.authenticationType,
              userAction: userAction
            };

            const response: any = await handleClient(payload);
            setpopupmessage(response?.data?.statusMessage);

            //console.log(response);
            if (response?.data?.data?.recordset[0].ErrorMessage) {
              setLoader(false)
              setErrorMessages((prevState) => ({
                ...prevState,
                errEmail: "Email already Exist",
              }));
            }
            if (response?.data?.data.statusCode !== 200) {
              setLoader(false)
              setAlertPopup(true)
            } 
            else {
              setClientData({userName :'',companyName:'',registrationType:'',authenticationType:'',status:'',phone:'',phonecode:'',email:''})
              setLoader(false)
              setSuccessPopup(true)
              setSuccessPopupType('insertuser')
              setClientData(response.data.data.clientData);
            }
          } catch (error: any) {
            console.error(error);
            setLoader(false)
            setAlertPopup(true)
          }
        } else if (userAction === 'edit') {
          try {
            debugger;
            setLoader(true)
            payload = {
              token: await getToken(),
              userName: editClientData.userName,
              companyName: editClientData.companyName,
              email: editClientData.email,
              phonenumber: editClientData.phone,
              phonecode: editClientData.phonecode,
              status: editClientData.status,
              registrationType: editClientData.registrationType,
              authenticationType: editClientData.authenticationType,
              userAction: userAction
            };
            const response: any = await handleClient(payload);
            //console.log(response);
            setpopupmessage(response?.data?.statusMessage)
            if (response?.data?.data?.recordset[0].ErrorMessage) {
              setLoader(false)
              seterroreditMessage((prevState) => ({
                ...prevState,
                errmail: "Email already Exist",
              }));
            }
            if (response?.data?.statusCode !== 200) {
              setLoader(false)
              setAlertPopup(true)
            } else {
              setEditClientData(response.data.data.clientData);
              getClient()
              setLoader(false)
              setSuccessPopup(true)
            }
          } catch (error: any) {
            console.error(error);
            setLoader(false)
            setAlertPopup(true)
          }
        }
      }
      else {
        setLoader(false)
        setAlertPopup(true)
      }
    }
    catch (error: any) {
      setLoader(false)
      setAlertPopup(true)
    }
  }


  return (
    <>

      <div className="container-fluid container-height">
        {location.state.action == 'NewClient' ?
          <div className="row">
            <div className="col-md-12">
              <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                {/* bread crumb starts here */}
                <ul className="breadcrumb-item mb-4 ps-0">
                  <li>
                    <a onClick={() => history.push('/manageClient')} className="pe-0">
                      Manage Clients
                    </a>
                  </li>
                  <li className="pe-2">New Client</li>
                </ul>
                {/* bread crumb ends here */}
                <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                  <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">
                    <a onClick={() => history.push('/manageClient')}>
                      <img
                        src="/images/head-back-arrow.svg"
                        alt="back-arrow"
                        width={19}
                        className="me-2"
                      />
                    </a>
                    New Client
                  </h5>
                </div>
                {/*forms starts here*/}
                <div className="row mb-3">
                  <div className="col-md-12 mt-3 mb-2">
                    <h5 className="font-bold font-20">Account Information</h5>
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="user-name"
                    >
                      User Name <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="user-name"
                      name='userName'
                      placeholder="Enter User Name"
                      value={clientData?.userName}
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errUsername && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 "> {errorMessages.errUsername}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="company-name"
                    >
                      Company Name <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="company-name"
                      name='companyName'
                      placeholder="Enter Company Name"
                      value={clientData?.companyName}
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errCompanyName && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errCompanyName}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="email-address"
                    >
                      Email Address <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="email-address"
                      placeholder="Enter Email Address"
                      value={clientData?.email}
                      name='email'
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errEmail && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errEmail}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="ph-no"
                    >
                      Phone Number <span className="mandatory-color">*</span>
                    </label>
                    <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
                      <Select
                        options={countryOptions}
                        onChange={handleCountryChange}
                        value={countryOptions.find((option: OptionType) => option.value === clientData.phonecode)}
                      />
                      <input
                        type="text"
                        className="form-control custom-form font-14 font-regular py-2"
                        id="ph-no"
                        onChange={(e) =>
                          handleFieldValidation(
                            e.target.id,
                            e.target.value,
                            "new"
                          )
                        }
                        value={clientData?.phone}
                      />
                      {errorMessages.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errPhone}</span>}

                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Status <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="status"
                        id="Active"
                        defaultChecked
                        checked={clientData.status === 'Active'}
                        onChange={(e) => handleFieldValidation("status", e.target.value, "new")}
                        value='Active'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Active">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="status"
                        id="InActive"
                        checked={clientData.status === 'InActive'}
                        onChange={(e) => handleFieldValidation('status', e.target.value, "new")}
                        value='InActive'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="InActive">
                        Inactive
                      </label>
                    </div>
                    {errorMessages.status && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.status}</span>}
                  </div>

                  <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Registration Type <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="regtype"
                        id="SSO"
                        defaultChecked
                        checked={clientData?.registrationType.toLowerCase().trim() === 'sso'}
                        onChange={(e) => handleFieldValidation('registrationType', e.target.value, "new")}
                        value='SSO'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="SSO">
                        SSO
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="regtype"
                        id="Email"
                        checked={clientData?.registrationType.toLowerCase().trim() === 'email'}
                        onChange={(e) => handleFieldValidation('registrationType', e.target.value, "new")}
                        value='Email'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Email">
                        Email
                      </label>
                    </div>
                    {errorMessages.errRegistrationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errRegistrationType}</span>}
                  </div>

                  <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Environment Type <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="envtype"
                        id="Tenant"
                        checked={clientData?.authenticationType === 'Tenant'}
                        onChange={(e) => handleFieldValidation('environmenttype', e.target.value, "new")}
                        value='Tenant'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Tenant">
                        Tenant
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="envtype"
                        id="g-suite"
                        disabled={true}
                        checked={clientData?.authenticationType === 'g-suite'}
                        onChange={(e) => handleFieldValidation("environmenttype", e.target.value, "new")}
                        value='g-suite'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="g-suite">
                        Gsuite
                      </label>
                    </div>
                    {errorMessages.errAuthenticationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errAuthenticationType}</span>}
                  </div>

                  <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center py-4">
                      <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
                        Cancel
                      </button>
                      <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={(e) => handleClientdata(clientData, 'new')} disabled={!enable}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/*forms ends here*/}
              </div>
            </div>
          </div> :
          <div className="row">
            <div className="col-md-12">
              <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                {/* bread crumb starts here */}
                <ul className="breadcrumb-item mb-4 ps-0">
                  <li>
                    <a onClick={() => history.push('/manageClient')} className="pe-0">
                      Manage Clients
                    </a>
                  </li>
                  <li className="pe-2">Zeb</li>
                </ul>
                {/* bread crumb ends here */}
                <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                  <h5 className="primary-header font-20 font-20 mb-0 d-flex align-items-center">
                    <a onClick={() => history.push('/manageClient')}>
                      <img
                        src="/images/head-back-arrow.svg"
                        alt="back-arrow"
                        width={19}
                        className="me-2"
                      />
                    </a>
                    {editClientData.companyName}
                  </h5>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <h5 className="font-bold font-18 primary-color">
                      Available License
                    </h5>
                  </div>
                  <div className="d-md-flex align-items-center mb-3">
                    <p className="font-14 font-regular me-4 color-black">
                      User Count
                      <span className="font-16 font-bold primary-color ms-2">
                        07/30
                      </span>
                    </p>
                    <p className="font-14 font-regular me-4 color-black">
                      Tenant Size
                      <span className="font-16 font-bold primary-color ms-2">
                        2.15 GB/4 GB
                      </span>
                    </p>
                    <p className="font-14 font-regular me-4 color-black">
                      Parallel Instance Count
                      <span className="font-16 font-bold primary-color ms-2">
                        3/5
                      </span>
                    </p>
                    <p className="font-14 font-regular me-4 color-black">
                      Assessment Count
                      <span className="font-16 font-bold primary-color ms-2">
                        3/5
                      </span>
                    </p>
                    <p className="font-14 font-regular me-4 color-black">
                      Plan Expires On
                      <span className="font-16 font-bold primary-color ms-2">
                        May 15, 2019
                      </span>
                    </p>
                  </div>
                </div>
                {/*Tabs Starts here*/}
                <div className="tab-scroll d-flex">
                  <ul
                    className="nav nav-pills mb-4 pb-md-2 d-flex custom-mobile-tab-list"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom-bg rounded-start"
                      role="presentation"
                    >
                      <button
                        className="nav-link theme-tab active font-16"
                        data-bs-toggle="pill"
                        data-bs-target="#accountInformation"
                        type="button"
                        role="tab"
                        aria-selected="true"
                      >
                        Account Information
                      </button>
                    </li>
                    <li className="nav-item custom-bg" role="presentation">
                      <button
                        className="nav-link theme-tab font-16"
                        data-bs-toggle="pill"
                        data-bs-target="#license-assignment"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        License Assignment
                      </button>
                    </li>
                    <li className="nav-item custom-bg" role="presentation">
                      <button
                        className="nav-link theme-tab font-16"
                        data-bs-toggle="pill"
                        data-bs-target="#invoice-details"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        Invoice Details
                      </button>
                    </li>
                    <li
                      className="nav-item custom-bg rounded-end"
                      role="presentation"
                    >
                      <button
                        className="nav-link theme-tab font-16"
                        data-bs-toggle="pill"
                        data-bs-target="#audit-history"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        Audit History
                      </button>
                    </li>
                  </ul>
                </div>
                {/*Tabs ends here*/}
                {/*Tab Inner starts here*/}
                <div className="tab-content" id="pills-tabContent">
                  {/*Account Information Tab starts here*/}
                  <div
                    className="tab-pane fade show active"
                    id="accountInformation"
                    role="tabpanel"
                  >
                    <div className="row mb-3">
                      <div className="col-md-12 mt-3 mb-2">
                        <h5 className="font-bold font-20">Account Information</h5>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="user-name"
                        >
                          User Name <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="user-name"
                          name='userName'
                          placeholder="Enter User Name"
                          value={editClientData?.userName}
                          onChange={(e) =>
                            handleFieldValidation(
                              e.target.id,
                              e.target.value,
                              "edit"
                            )
                          }
                        />
                        {erroreditMessage.errUsername && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 "> {erroreditMessage.errUsername}</span>}

                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="company-name"
                        >
                          Company Name <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="company-name"
                          name='companyName'
                          placeholder="Enter Company Name"
                          value={editClientData?.companyName}
                          onChange={(e) =>
                            handleFieldValidation(
                              e.target.id,
                              e.target.value,
                              "edit"
                            )
                          }
                        />
                        {erroreditMessage.errCompanyName && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errCompanyName}</span>}

                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="email-address"
                        >
                          Email Address <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="email-address"
                          placeholder="Enter Email Address"
                          value={editClientData?.email}
                          name='email'
                          onChange={(e) =>
                            handleFieldValidation(
                              e.target.id,
                              e.target.value,
                              "edit"
                            )
                          }
                        />
                        {erroreditMessage.errEmail && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errEmail}</span>}

                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="ph-no"
                        >
                          Phone Number <span className="mandatory-color">*</span>
                        </label>
                        <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
                          <Select
                            options={countryOptions}
                            onChange={handleCountryChange}
                            value={countryOptions.find((option: OptionType) => option.value === clientData.phonecode)}
                          />
                          <input
                            type="text"
                            className="form-control custom-form font-14 font-regular py-2"
                            id="ph-no"
                            onChange={(e) =>
                              handleFieldValidation(
                                e.target.id,
                                e.target.value,
                                "edit"
                              )
                            }
                            value={editClientData?.phone}
                          />
                          {erroreditMessage.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errPhone}</span>}

                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label className="form-label font-14 font-semibold label-color label-color w-100">
                          Status <span className="mandatory-color">*</span>
                        </label>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="status"
                            id="Active"
                            defaultChecked
                            checked={editClientData.status == 'Active'}
                            onChange={(e) =>
                              handleFieldValidation(
                                "status",
                                e.target.value,
                                "edit"
                              )
                            }
                            value='Active'
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="Active"
                          >
                            Active
                          </label>

                        </div>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="status"
                            id="InActive"
                            checked={editClientData.status === 'InActive'}
                            onChange={(e) =>
                              handleFieldValidation(
                                "status",
                                e.target.value,
                                "edit"
                              )
                            }
                            value='InActive'

                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="InActive"
                          >
                            Inactive
                          </label>
                        </div>
                        {erroreditMessage.status && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.status}</span>}
                      </div>
                      <div className="col-md-4 mb-4">
                        <label className="form-label font-14 font-semibold label-color label-color w-100">
                          Registration Type <span className="mandatory-color">*</span>
                        </label>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="regtype"
                            id="SSO"
                            checked={editClientData.registrationType == 'SSO'}
                            onChange={(e) =>
                              handleFieldValidation(
                                'registrationType',
                                e.target.value,
                                "edit"
                              )
                            }
                            value='SSO'
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="SSO"
                          >
                            SSO
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="regtype"
                            id="Email"
                            defaultValue="option3"
                            checked={editClientData.registrationType == 'Email'}
                            onChange={(e) =>
                              handleFieldValidation(
                                'registrationType',
                                e.target.value,
                                "edit"
                              )
                            }
                            value='Email'
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="Email"
                          >
                            Email
                          </label>
                        </div>
                        {erroreditMessage.errRegistrationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errRegistrationType}</span>}

                      </div>
                      {/* <div className="col-md-4 mb-4">
                        <label className="form-label font-14 font-semibold label-color label-color w-100">
                          Environment Type <span className="mandatory-color">*</span>
                        </label>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="envtype"
                            id="Tenant"
                            checked={editClientData.authenticationType == 'Tenant'}
                            onChange={(e) =>
                              handleFieldValidation(
                                'environmenttype',
                                e.target.value,
                                "edit"
                              )
                            }
                            value='Tenant'
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="Tenant"

                          >
                            Tenant
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-2">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="envtype"
                            id="g-suite"
                            checked={editClientData.authenticationType == 'g-suite'}
                            onChange={(e) =>
                              handleFieldValidation(
                                "environmenttype",
                                e.target.value,
                                "edit"
                              )
                            }
                            disabled={true}
                            value='g-suite'
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="g-suite"
                          >
                            Gsuite
                          </label>
                        </div>
                        {erroreditMessage.errAuthenticationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errAuthenticationType}</span>}

                      </div> */}
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center py-4">
                          <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
                            Cancel
                          </button>
                          <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={(e) => handleClientdata(e, 'edit')} disabled={!enable}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Account Information Tab ends here*/}
                  {/*License Assignment Tab starts here*/}
                  <div
                    className="tab-pane fade"
                    id="license-assignment"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-md-12 mt-3 mb-2">
                        <h5 className="font-bold font-18 color-black">
                          License Assignment
                        </h5>
                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="user-count"
                        >
                          User Count <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="user-count"
                          placeholder="Enter User Count"
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="tenant-size"
                        >
                          Tenant Size <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="tenant-size"
                          placeholder="Enter Tenant Size"
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="instance-count"
                        >
                          Parallel Instance Count{" "}
                          <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="instance-count"
                          placeholder="Enter Parallel Instance Count"
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <label
                          className="form-label font-14 font-semibold label-color label-color"
                          htmlFor="assessment-count"
                        >
                          Assessment Count <span className="mandatory-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular py-2"
                          id="assessment-count"
                          placeholder="Enter Assessment Count"
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-4">
                          <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 px-4">
                            Cancel
                          </button>
                          <button className="btn primary-outline-btn w-md-100 font-14 font-semibold text-nowrap">
                            Revoke License
                          </button>
                          <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4">
                            Assign License
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*License Assignment Tab ends here*/}
                  {/*Invoice Details Tab starts here*/}
                  <div className="tab-pane fade" id="invoice-details" role="tabpanel">
                    <div className="row">
                      <div className="col-md-12 mt-3 mb-2">
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                          <h5 className="font-bold font-18 color-black">
                            Invoice History (5)
                          </h5>
                          <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                            <div className="d-flex gap-3 mb-md-0 mb-3 align-items-center">
                              <div className="input-group input-search flex-nowrap">
                                <input
                                  type="search"
                                  className="form-control border-end-0 font-14 font-regular w-auto"
                                  placeholder="Search"
                                />
                                <button
                                  className="btn border-start-0 d-flex align-items-center"
                                  type="button"
                                >
                                  <img
                                    src="/images/search.svg"
                                    alt="search"
                                    className="d-block "
                                  />
                                </button>
                              </div>
                            </div>
                            <button className="btn mb-md-0 mb-3 primary-outline-btn w-md-100 font-14 font-semibold text-nowrap">
                              Generate Payment Link
                            </button>
                            <button className="btn mb-md-0 mb-3 primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
                              <img
                                src="/images/add-plus-icon.svg"
                                alt="add-plus-icon"
                              />
                              New Invoice
                            </button>
                          </div>
                          {/*Grid starts here*/}
                          <div className="table-responsive theme-table bg-white d-inline-block w-100">
                            <table className="table table-borderless table-shadow mb-0">
                              <thead>
                                <tr>
                                  <th className="text-center pe-1" />
                                  <th className="text-nowrap">
                                    Invoice #
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap">
                                    Payment Status
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap">
                                    Environment Type
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-up.svg"
                                        alt="sort-arrow-up"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap">
                                    Plan Type
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap">
                                    Purchase Type
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap">
                                    Payment Date
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap text-end">
                                    Amount
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                  <th className="text-nowrap text-center">
                                    Actions
                                    <span className="ms-1 cursor-pointer">
                                      <img
                                        src="/images/sort-arrow-down.svg"
                                        alt="sort-arrow-down"
                                      />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center pe-1">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                    />
                                  </td>
                                  <td className="text-nowrap">20220810-023-024</td>
                                  <td>
                                    <span className="text-nowrap">
                                      <span className="table-status success d-inline-block me-2 " />
                                      Success
                                    </span>
                                  </td>
                                  <td>Tenant</td>
                                  <td>Premium</td>
                                  <td>User Based</td>
                                  <td>July 01, 2021</td>
                                  <td className="text-end">$2,700</td>
                                  <td className="text-center">
                                    <span className="action-drop-down ">
                                      <img
                                        src="/images/keb-menu-icon.svg"
                                        alt="keb-menu-icon"
                                        data-bs-toggle="dropdown"
                                        className="p-2 action-icon rounded-3"
                                      />
                                      <span className="dropdown-menu font-regular font-14 p-1">
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img src="/images/edit-icon.svg" alt="edi" />
                                          Edit
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/download-icon.svg"
                                            alt="dowload"
                                          />
                                          Download
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/red-delete-icon.svg"
                                            alt="Delete"
                                          />
                                          Delete
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center pe-1">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                    />
                                  </td>
                                  <td className="text-nowrap">20220810-023-024</td>
                                  <td>
                                    <span className="text-nowrap">
                                      <span className="table-status danger d-inline-block me-2 " />
                                      Failure
                                    </span>
                                  </td>
                                  <td>Tenant</td>
                                  <td>Pro</td>
                                  <td>Resource Utilization &amp; User License</td>
                                  <td>Nov 16, 2020</td>
                                  <td className="text-end">$10,040</td>
                                  <td className="text-center">
                                    <span className="action-drop-down ">
                                      <img
                                        src="/images/keb-menu-icon.svg"
                                        alt="keb-menu-icon"
                                        data-bs-toggle="dropdown"
                                        className="p-2 action-icon rounded-3"
                                      />
                                      <span className="dropdown-menu font-regular font-14 p-1">
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img src="/images/edit-icon.svg" alt="edi" />
                                          Edit
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/download-icon.svg"
                                            alt="dowload"
                                          />
                                          Download
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/red-delete-icon.svg"
                                            alt="Delete"
                                          />
                                          Delete
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center pe-1">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                    />
                                  </td>
                                  <td className="text-nowrap">20220810-023-024</td>
                                  <td>
                                    <span className="text-nowrap">
                                      <span className="table-status draft d-inline-block me-2 " />
                                      Queue
                                    </span>
                                  </td>
                                  <td>Tenant</td>
                                  <td>Elite</td>
                                  <td>User Based</td>
                                  <td>Jan 05, 2020</td>
                                  <td className="text-end">$4,500</td>
                                  <td className="text-center">
                                    <span className="action-drop-down ">
                                      <img
                                        src="/images/keb-menu-icon.svg"
                                        alt="keb-menu-icon"
                                        data-bs-toggle="dropdown"
                                        className="p-2 action-icon rounded-3"
                                      />
                                      <span className="dropdown-menu font-regular font-14 p-1">
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img src="/images/edit-icon.svg" alt="edi" />
                                          Edit
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/download-icon.svg"
                                            alt="dowload"
                                          />
                                          Download
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/red-delete-icon.svg"
                                            alt="Delete"
                                          />
                                          Delete
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center pe-1">
                                    <input
                                      className="form-check-input cus-check-input"
                                      type="checkbox"
                                    />
                                  </td>
                                  <td className="text-nowrap">20220810-023-024</td>
                                  <td>
                                    <span className="text-nowrap">
                                      <span className="table-status success d-inline-block me-2 " />
                                      Success
                                    </span>
                                  </td>
                                  <td>Tenant</td>
                                  <td>Premium</td>
                                  <td>User Based</td>
                                  <td>July 01, 2021</td>
                                  <td className="text-end">$2,700</td>
                                  <td className="text-center">
                                    <span className="action-drop-down ">
                                      <img
                                        src="/images/keb-menu-icon.svg"
                                        alt="keb-menu-icon"
                                        data-bs-toggle="dropdown"
                                        className="p-2 action-icon rounded-3"
                                      />
                                      <span className="dropdown-menu font-regular font-14 p-1">
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img src="/images/edit-icon.svg" alt="edi" />
                                          Edit
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/download-icon.svg"
                                            alt="dowload"
                                          />
                                          Download
                                        </span>
                                        <span className="d-flex gap-2 px-3 py-2 list">
                                          <img
                                            src="/images/red-delete-icon.svg"
                                            alt="Delete"
                                          />
                                          Delete
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*Grid ends here*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Invoice Details Tab ends here*/}
                  {/*Audit History Tab starts here*/}
                  <div className="tab-pane fade" id="audit-history" role="tabpanel">
                    <div className="row">
                      <div className="col-md-12 mt-3 mb-2">
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                          <h5 className="font-bold font-18 color-black">
                            Audit History (4)
                          </h5>
                          <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                            <div className="d-flex gap-3 mb-md-0 mb-2 align-items-center">
                              <div className="input-group input-search flex-nowrap">
                                <input
                                  type="search"
                                  className="form-control border-end-0 font-14 font-regular w-auto"
                                  placeholder="Search"
                                />
                                <button
                                  className="btn border-start-0 d-flex align-items-center"
                                  type="button"
                                >
                                  <img
                                    src="/images/search.svg"
                                    alt="search"
                                    className="d-block "
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Grid starts here*/}
                        <div className="table-responsive theme-table bg-white ">
                          <table className="table table-borderless mb-0">
                            <thead>
                              <tr>
                                <th className="text-nowrap">
                                  Name
                                  <span className="ms-1 cursor-pointer">
                                    <img
                                      src="/images/sort-arrow-down.svg"
                                      alt="sort-arrow-down"
                                    />
                                  </span>
                                </th>
                                <th className="text-nowrap">
                                  Time Stamp
                                  <span className="ms-1 cursor-pointer">
                                    <img
                                      src="/images/sort-arrow-up.svg"
                                      alt="sort-arrow-up"
                                    />
                                  </span>
                                </th>
                                <th className="text-nowrap">
                                  Log
                                  <span className="ms-1 cursor-pointer">
                                    <img
                                      src="/images/sort-arrow-down.svg"
                                      alt="sort-arrow-down"
                                    />
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-nowrap">Luke Domnic</td>
                                <td className="text-nowrap">06/03/2023 7:30AM</td>
                                <td className="text-nowrap">Visited User Mapping</td>
                              </tr>
                              <tr>
                                <td className="text-nowrap">Dwyane Wade</td>
                                <td className="text-nowrap">06/01/2023 9:45AM</td>
                                <td className="text-nowrap">Done Delta Migration</td>
                              </tr>
                              <tr>
                                <td className="text-nowrap">James Junior</td>
                                <td className="text-nowrap">05/27/2023 4:20PM</td>
                                <td className="text-nowrap">
                                  Purchased Resource Utilization
                                </td>
                              </tr>
                              <tr>
                                <td className="text-nowrap">Robert Brown</td>
                                <td className="text-nowrap">05/23/2023 12:55AM</td>
                                <td className="text-nowrap">Done Delta Migration</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/*Grid ends here*/}
                      </div>
                    </div>
                  </div>
                  {/*Audit History Tab ends here*/}
                </div>
                {/*Tab Inner ends here*/}
              </div>
            </div>
          </div>
        }
      </div>
      {alertPopup === true ? (
        <div
          className="modal fade show"
          id="Alert-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="Alert-popupLabel"
          aria-hidden="true"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img
                    src="/images/failure-popup-icon.svg"
                    alt="Alert-popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Alert-popupLabel"
                >
                  Alert
                </h5>
                <p className="primary-data font-16">{popupmessage || 'Something went wrong , Please try again later !'}</p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                        <button
                          type="button"
                          className="btn primary-btn font-14 px-4 font-semibold"
                          data-bs-dismiss="modal"
                          onClick={ () => setAlertPopup(false)}
                        >
                          Ok
                        </button>
                      </div>
            </div>
          </div>
        </div>
      ) : null}

      {successPopup === true ? (
        <div className="modal fade show " id="Success-popup"
          aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="images/success-popup-icon.svg" alt="success-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                  Success !
                </h5>
                <p className="primary-data">{successPopupType == 'passwordUpdate' ? 'Password Updated Successfully' : successPopupType == 'insertuser' ? 'User Added Successfully' : 'Account Information Updated Successfully'}</p>
              </div>
              <div className="modal-footer border-0 justify-content-center pb-4">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    if (successPopupType == 'passwordUpdate') {
                      sessionStorage.clear();
                      setSuccessPopup(false);

                    }
                    setSuccessPopup(false);
                  }}                            >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {loader && <Loader />}

    </>

  );

};

export default AdminClient;


