import { useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

export default function ServerDown(): JSX.Element {

    useEffect(() => {
    }, [])

    return (
        <Router>
        < div className="container-fluid p-2 bg-white card-bg-img" >
        <img src='/Images/brand_logo.svg' className="brand-logo icon-responsive mt-4 ms-5" alt="logo"></img>
            <div className='centerallign  text-center mt-5 server-down-page-height server-down-brand-logo-div'>
                {/* <img className="my-4 server-down-logo server-down-brand-logo-position" src="/Images/brand_logo.svg" />  */}
                <img className="my-4 server-down-img-width" src="/Images/server_down.png" />
                <h4 className="text-center contact-us font-bold font-24 mb-4 server-down-content-padding">AVAMIGRATRON is currently down for maintenance. We expect to be back in a couple of hours. Thanks for your patience.</h4>
            </div>
        </div >
        </Router>
    )
}   
