import { useHistory, useLocation } from 'react-router';
import React, { useState, useEffect } from 'react';
import { ExportData, ImportData, Popup, Toaster, Task, sessionResponse, Apps, selectedApps, ValidateImport, outlookTemplate } from '../../Common/Interface';
import { appDescripions, commonErrorMsgs, importMsgs, outlookExportTemplate } from '../../Common/Constant';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { getSession } from '../../Services/manageClientAPI';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
import cryptoJs from 'crypto-js';
import { customExportAndImportService, getAppsService, getSelectedAppsForAssessmentService, startAssessmentService } from '../../Services/SelectAppsService';
// import { getAppsService } from '../Services/SelectAppsService';
import { AxiosResponse } from 'axios';
import objCryptoJS from "crypto-js";
import { saveAs } from 'file-saver';
import jwt from 'jwt-decode'

export const SelectApps: React.FC = () => {
    const tokenExpiryCheckInterval = 20 * 60 * 1000; // 20 minutes
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let history = useHistory()
    const location: any = useLocation().state
    const [fileInputValue, setFileInputValue] = useState("")
    const [apps, setApps] = useState<Apps[]>([]);
    const [selectedApps, setSelectedApps] = useState<selectedApps[]>([]);
    const [importData, setImportData] = useState<ImportData[]>([]);
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    const [currentTask, setTask] = useState<Task>(() => {
        const taskName: string = location?.state?.taskName || "Task Migration"
        const taskType: any = location?.state?.taskType || "assessment";
        const taskId: any = location?.state?.taskId 
        return { taskType, taskId, taskName } as Task; // Assuming task has taskType and taskId properties
    });

    const [pageType, setPageType] = useState<string>(location?.state?.pageType || "export");
    const [chosenApp, setChosenApp] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [validateImport, setValidateImport] = useState<ValidateImport>({
        message: "",
        status: false
    });
    const [invalidData, setInvalidData] = useState([])
    const [loader, setLoader] = useState<boolean>(true);
    const [popup, setPopup] = useState<Popup>({
        errorMessage: "",
        successMessage: ""
    });
    // const [toasterState, setToaster] = useState<Toaster>({
    //     success: false,
    //     failure: false,
    //     message: ""
    // });

    // Client session variables
    const [ctoken, setCToken] = useState<null | string>(null);
    const [tokenExpiration, setTokenExpiration] = useState<Date>(new Date());

    // Admin session variables
    const [atoken, setAToken] = useState<null | string>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;

    const pageTypee = location?.state?.taskType;
    let stage = 4;

    // Set stage based on pageType
    // if (pageTypee === 'migration') {
    //     stage = 4; // Set stage to 0 if pageType is 'selectapps'
    // } else {
    //     stage = location?.state?.stage; // Otherwise, use the existing stage
    // }
    // console.log(stage, "idhe ra stage ante");

    useEffect(() => {
        debugger
        // window.addEventListener('keydown', resetSessionTimer);
        // window.addEventListener('click', resetSessionTimer);
        // debugger
        sessionTokenProperties();
        if (location?.state?.taskId & location?.state?.isEdit) {
            getSelectedAppsForAssessment()
        }
        resetTimer();
        if (pageType == "selectapps") {
            getApps()
        }
        else {
            getSelectedAppsForAssessment()
        }
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };

    }, []);

    let resetTimer = async () => {
        try {
            // debugger;
            //console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            //console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            //console.log("Reset Timer End");
        }
        catch (error) {
            //console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoader(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            //console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                //console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    //console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    //console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                //console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                //console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                //console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    //console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                //console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            //console.log("Reset Session Timer Catch");
            throw ex
        }
    };

    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            // debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString()?.toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        // debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    // PC_EX_23 - 40
    // THis is to the get the apps from DB
    const getApps = async () => {
        try {
            debugger
            setLoader(true);
            let token = await getToken()
            const appData: AxiosResponse | undefined = await getAppsService({ token: token });
            if (appData?.data.statusCode == 200) {
                //console.log(appData.data.data[0], "this is the app list");

                setApps(appData?.data.data[0]);
            }
            else {
                setPopup({ ...popup, errorMessage: appData?.data.statusMessage });

            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };

    const getSelectedAppsForAssessment = async () => {
        setLoader(true);
        try {
            debugger
            let token = await getToken()
            const response: AxiosResponse = await getSelectedAppsForAssessmentService(token, currentTask.taskId); // Replace with actual API call
            if (response.data.statusCode == 200) {
                if (location?.state?.taskId & location?.state?.isEdit) {
                     setSelectedItems([response.data?.data[0][0].applicationId]);
                }
                setSelectedApps(response.data?.data[0]);
            }
            else {
                setPopup({ ...popup, errorMessage: response.data.statusMessage });
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };
    const handleErrorMessage = (message: any) => {
        if (message === "You don't have enough license, Please upgrade plan to proceed further") {
            return (
                <span>
                    You don't have enough license, Please{' '}
                    <a href="">upgrade plan</a>
                    {' '}to proceed further
                </span>
            );
        }
        return message;
    };    // PC_EX_204 - 235
    // This handler is used to handle Select and Select All
    const handleButtons = async (e: any) => {
        try {
            debugger
            let applicationName: string[] = []
            let applicationId: number[] = []
            switch (e.currentTarget.id) {
                case 'assessmentnext':
                    applicationName = apps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => item.applicationName)
                    applicationId = selectedItems
                    history.push('/connectors', { state: { applicationName, applicationId, ...location?.state } });
                    break;
                case 'assessmentcancel':
                    history.push('/task');
                    break
                case 'assessmentback':
                    history.push('/task');
                    break
                case 'migrationcancel':
                    history.push('/task');
                    break
                case 'assessmentexportimportcancel':
                    // Navigate to task grid
                    history.push('/task');
                    break;
                case 'assessmentexportimportback':
                    // Navigate to task grid
                    history.push('/AssessmentConfigurations', { state: { applicationName, applicationId, selectedApps, ...location?.state } });
                    break;
                case 'importbacknavigationarrow':
                    // Navigate to task grid
                    history.push('/task');
                    break;
                case 'backnavigationarrow':
                    // Navigate to task grid
                    history.push('/task');
                    break;
                case 'migrationnext':
                    // Navigate to Connectors with selectedItems, task.taskType in location
                    applicationId = selectedItems
                    applicationName = apps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => item.applicationName)
                    history.push('/connectors', { state: { applicationName, applicationId, selectedApps, ...location?.state } });
                    break;
                case 'startassessment':
                    // Set the loader to true
                    debugger
                    setLoader(true);
                    // Simulate the assessment process and check statusCode
                    let token: object = await getToken()
                    let appsAssessment = selectedApps.filter((element) => selectedItems.includes(element.applicationId)).map((item) => {
                        let data = {
                            applicationName: item.applicationName,
                            applicationId: item.applicationId
                        }
                        return data
                    })
                    //console.log({ token: token, taskId: currentTask.taskId, apps: appsAssessment });

                    let result: AxiosResponse = await startAssessmentService({ token: token, taskId: currentTask.taskId, apps: appsAssessment })
                    if (result.data.statusCode == 200) {
                        setLoader(false);
                        history.push('/Inprogress', { ...location });
                    } else {
                        setLoader(false);
                        setPopup({ ...popup, errorMessage: result.data.statusMessage });
                        if (result.data.statusMessage == "You don't have enough license, Please upgrade plan to proceed further") {
                            let message = await handleErrorMessage(result.data.statusMessage)
                            setPopup({ ...popup, errorMessage: message });
                        }
                    }
                    break;

            }
        } catch (error) {
            // If an error occurs during button handling, Show Error popup
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };


    const insertLastVisitedPageValue = async (page: string) => {
        try {
            console.log(page, "This is the last inserted pageeeee");

            let token = await getToken()

            const payload = {
                token: token,
                taskId: currentTask.taskId,
                lastVisitedValue: page
            };

            //console.log(payload, 'last visited');

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);

            if (!response) {
                //console.log('The current page value failed to insert');
            } else {
                //console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };

    // PC_EX_190 - 202
    // This handler is used to handle Select and Select All
    const handleSelectAndSelectAll = (e: any) => {
        debugger
        try {
            if (currentTask.taskType?.toLowerCase() == "migration") {
                setSelectedItems([parseInt(e.currentTarget?.id?.split('?')[1])])
                // //console.log("Pushed Item : ",items);

                //setSelectedItems([...selectedItems]);
            }
            else {
                if (e.target.id.startsWith("all")) {
                    if (!e.currentTarget.checked) {
                        setSelectedItems([])
                    }
                    else {
                        if (pageType == "selectapps") {
                            let appId: number[] = apps.map((item) => item.applicationId)
                            setSelectedItems(appId)

                        }
                        else {
                            let appId: number[] = selectedApps.map((item) => item.applicationId)
                            setSelectedItems(appId)
                        }
                    }
                }
                else {
                    if (e.currentTarget.checked) {
                        debugger
                        // let appId = apps.filter((element)=> element.applicationId == e.currentTarget?.id?.split('?')[1])
                        // let items = selectedItems
                        // items.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // setSelectedItems(items)
                        // selectedItems.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // setSelectedItems(selectedItems)
                        //console.log(selectedItems);
                        //console.log(e.currentTarget?.id?.split('?')[1]);
                        //console.log(parseInt(e.currentTarget?.id?.split('?')[1]));
                        // let items = selectedItems
                        selectedItems.push(parseInt(e.currentTarget?.id?.split('?')[1]))
                        // //console.log("Pushed Item : ",items);

                        setSelectedItems([...selectedItems]);
                        //console.log(parseInt(e?.currentTarget?.id?.split('?')[1]));
                        //console.log(parseInt(e?.currentTarget?.id?.split('?')[1]));

                    }
                    else {
                        let appId = selectedItems
                        appId = appId.filter((item) => item != e.currentTarget?.id?.split('?')[1])
                        setSelectedItems(appId)
                    }
                }
                // setSelectedItems((pre) => [e.target.id, ...pre])
            }
        } catch (error) {
            // Show the Error popup
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    };


    // PC_EX_124 - 157
    // This handler is used to handle Download and upload of expor import file
    const handleDownloadUpload = async (e: any) => {
        debugger
        setLoader(true);
        //console.log(e.target.id, chosenApp);

        try {
            if (e.currentTarget.id === 'download' && chosenApp === 'outlook') {
                // Code to handle the download process
                setLoader(true)
                const template: any = [outlookExportTemplate];
                const headers = Object.keys(template[0]);
                const rows = template.map((obj: any) => headers.map(header => obj[header] || ''));
                rows.unshift(headers);
                let finalExportData = rows.map((row: any) => row.join(',')).join('\n');
                let csvData = new Blob([finalExportData], { type: 'text/csv;charset=utf-8;' });
                saveAs(csvData, `UserMailbox_Template.csv`);
                setLoader(false)
            }
            else if (e.currentTarget.id === 'upload' && chosenApp === 'outlook') {
                // Code to handle the upload process
                if (!e.target.files?.[0].name.endsWith('.csv')) {
                    setImportData([])
                    setFileInputValue("")
                    setValidateImport({ status: true, message: importMsgs.invalidFile })
                    return
                }
                const file = e.target.files?.[0]; // Assuming single file upload
                debugger
                try {
                    const reader = new FileReader();
                    reader.onload = async (event) => {
                        try {
                            const text = event.target?.result as string;
                            const lines = text.split('\r\n');
                            const headers = lines[0].split(',');
                            let result = []
                            for (let i = 1; i < lines.length; i++) {
                                const currentLine = lines[i].split(',');
                                const obj: any = {};
                                for (let j = 0; j < headers.length; j++) {
                                    obj[headers[j]] = currentLine[j];
                                }
                                result.push(obj);
                            }
                            let template = [outlookExportTemplate]
                            const templateHeaders = Object.keys(template[0]);
                            if (!result[result.length - 1]['User Name'] || !result[result.length - 1]['User Email']) result.pop()
                            let validTemplate = true;
                            templateHeaders.forEach((header) => {
                                if (!headers.includes(header)) {
                                    validTemplate = false;
                                }
                            });
                            if (!validTemplate) {
                                //console.log("!validTemplate");
                                setImportData([])
                                setFileInputValue("")
                                setValidateImport({ status: true, message: importMsgs.invalidColumns })
                                return; // Exit if a template header is not found
                            }
                            const props = ['User Name', 'User Email'];
                            const emptyValidation = result.every(obj =>
                                props.every(prop => obj[prop] !== '') // PS_EX_143
                            );
                            if (!emptyValidation) {
                                setValidateImport({ status: true, message: importMsgs.emptyValues })
                                return; // PS_EX_144, PS_EX_145
                            }
                            else {
                                let data = result.map(item => {
                                    let data1 = {
                                        mailId: item['User Email'],
                                        displayName: item['User Name']
                                    }
                                    return data1
                                })
                                const uniqueMap = new Map();
                                for (const obj of data) {
                                    const key = `${obj.mailId}-${obj.displayName}`;
                                    if (!uniqueMap.has(key)) {
                                        uniqueMap.set(key, obj);
                                    }
                                }
                                data = Array.from(uniqueMap.values());
                                setValidateImport({ status: false, message: "" })
                                setFileInputValue(e.target.files[0].name)
                                setImportData(data)
                            }
                        } catch (error) {
                            setImportData([])
                            setFileInputValue("")
                            setValidateImport({ status: true, message: importMsgs.invalidFile })
                        }
                    }
                    reader.readAsText(file);
                } catch (error) {

                }
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };

    // PC_EX_159- 187
    // This handler is used to handle import of csv file and provide with the invalid data if failed
    const handleImport = async (e: any) => {
        debugger
        setChosenApp("")
        setFileInputValue("")
        setImportData([])
        setLoader(true);
        try {
            let token = await getToken()
            const response: AxiosResponse = await customExportAndImportService({ token: token, taskId: currentTask.taskId, type: `${chosenApp}Import`, users: importData });
            //console.log(response.data, 'response.dataresponse.data');

            if (response.data.statusCode == 200) {
                // Toaster.message = response.statusMessage;
                // Toaster.success = true;
                setChosenApp("")
                //console.log("HEREEEEEEEEEEE");

            } else {
                // Toaster.message = response.statusMessage;
                // Toaster.failure = true;
                setInvalidData(response.data.data)
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        } finally {
            setLoader(false);
        }
    };


    // PC_EX_93- 123
    // This handler is used to handle export of csv file and provide with the export data 
    const handleExport = async (appName: string) => {
        setLoader(true); // PS_EX_95

        try {
            if (appName === 'outlook') { // PS_EX_96
                // Replace this with the actual export data fetching logic
                let token = await getToken()
                const exportDataResponse = await customExportAndImportService({ token: token, taskId: currentTask.taskId, type: `${appName}Export` });
                debugger
                if (exportDataResponse.data.statusCode === 200) { // PS_EX_109
                    debugger
                    let exportData = exportDataResponse.data.data[0]; // PS_EX_111
                    exportData = exportData.map((item: any) => {
                        let obj = {
                            "User Name": item.displayName,
                            "User Email": item.mailId
                        }
                        return obj
                    })
                    const headers = Object.keys(exportData[0]); // PS_EX_112
                    let rows = exportData.map((obj: any) => // PS_EX_113
                        headers.map(header => obj[header] || '')
                    );
                    rows.unshift(headers); // PS_EX_114
                    let finalExportData = rows.map((row: any) => row.join(',')).join('\n'); // PS_EX_115
                    let csvData = new Blob([finalExportData], { type: 'text/csv;charset=utf-8;' }); // PS_EX_116

                    saveAs(csvData, `User Mailbox.csv`); // PS_EX_117

                    // Toaster.success = true; // PS_EX_118
                } else {
                    // Toaster.message = exportDataResponse.data; // PS_EX_120
                    // Toaster.failure = true;
                }
            }
        } catch (error) {
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
            // setPopup({ errorMessage: commonErrorMsgs.generalError }); // PS_EX_123
        } finally {
            setLoader(false); // PS_EX_119, PS_EX_121
        }
    };

    function handleInvalidDataDownload() {
        try {
            debugger
            const headers = Object.keys(invalidData[0]);
            let rows = invalidData.map((obj: any) => headers.map(header => obj[header] || ''));
            rows.unshift(headers);
            let invalid = rows.map((row: any) => row.join(',')).join('\n');
            let csvData = new Blob([invalid], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvData, `Invalid_Data.csv`);
            setInvalidData([])

        } catch (error) {
            setInvalidData([])
            setPopup({ ...popup, errorMessage: commonErrorMsgs.generalError });
        }
    }

    //console.log(location, "    loaction")

    //console.log(selectedItems, " state")

    //console.log(selectedItems.includes(2), "test")
    return (
        <>
            {currentTask.taskType?.toLowerCase() == "migration" && <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a onClick={() => history.push('/task')} className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h2 className="primary-header mb-0">
                                    <a onClick={() => history.push('/task')} className="pe-0">
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {currentTask.taskName} - Migration
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            {/* Wizard structure starts here */}
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.stage && location?.state?.stage >= 2) {

                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            ...location?.state,
                                                                            isEdit: true,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                            onClick={() => {
                                                                if (location?.state?.stage && location?.state?.stage >= 2) {

                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            isEdit: true,
                                                                            ...location?.state,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"

                                                        onClick={() => {
                                                            if (location?.state?.stage && location?.state?.stage >= 3) {
                                                                history.push("/Configurations", {
                                                                    state: {
                                                                        ...location?.state
                                                                    },
                                                                })
                                                            }


                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                                                            Configurations
                                                        </span>
                                                    </div>

                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onClick={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 4) {
                                                                location?.state?.taskType?.toLowerCase() == "assessment" ?
                                                                    history.push("/Assessment", {
                                                                        state: {
                                                                            ...location.state,
                                                                            taskId: currentTask.taskId,
                                                                            taskName: location?.state?.taskName,
                                                                            applicationName: location?.state?.applicationName,
                                                                            isEdit: true,
                                                                            stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                        },
                                                                    })
                                                                    :
                                                                    history.push("/Migration", {
                                                                        state: {
                                                                            ...location.state,
                                                                            taskId: currentTask.taskId,
                                                                            taskName: location?.state?.taskName,
                                                                            applicationName: location?.state?.applicationName,
                                                                            isEdit: true,
                                                                            stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                        },
                                                                    })
                                                            }
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Migration
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Wizard structure ends here */}
                                            <div className="col-md-9 mt-4 mt-md-5">
                                                <h3 className="m-0 font-22 font-bold mb-3">Select App</h3>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img src="/images/tooltip.svg" alt="tooltip" width={11} />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose any one specific app to proceed with migration
                                                    </span>
                                                </div>
                                                <div className="row" style={{
          cursor: location.state?.taskId ? 'not-allowed' : 'pointer',
          pointerEvents: location.state?.taskId ? 'none' : 'auto', // Prevent interactions
        }}>
                                                    {/* Cards */}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 position-relative app-card card-hover cursor-pointer card-selected">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/user-migration-icon.svg"
                                                                alt="Group migration"
                                                                width={39}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                Groups
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                This allows users to connect with like-minded
                                                                individuals, creating a sense of community ...
                                                            </p>
                                                            <img
                                                                src="/images/selected-icon.svg"
                                                                alt="selected"
                                                                className="position-absolute app-card-selected"
                                                            />
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {apps?.map((item: Apps) => {
                                                        return (
                                                            <>
                                                                <div className="col-md-3 mb-4 mb-md-5">
                                                                    <div className={selectedItems.includes(item.applicationId) ? "app-card card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer card-selected" : "app-card card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer"} onClick={(e) => { handleSelectAndSelectAll(e) }} id={`${item.applicationName}?${item.applicationId}`}>
                                                                        <img
                                                                            className="mb-2"
                                                                            src={`/images/${item.applicationName}.svg`}
                                                                            alt={item.applicationName}
                                                                            width={46}
                                                                        />
                                                                        <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                            {item.applicationName == "outlook" ? "User Mailbox" : item.applicationName == "groups" ? "Groups" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "One Drive" : item.applicationName == "sharepoint" ? "SharePoint" : null}
                                                                        </h4>
                                                                        <p
                                                                            className="font-regular label-color font-12 m-0 mt-2"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            data-bs-title="App Description"
                                                                            title={appDescripions[`${item.applicationName}`]}
                                                                        >
                                                                            {appDescripions[`${item.applicationName}`]}
                                                                        </p>
                                                                        <img src="/images/selected-icon.svg" alt="selected" className="position-absolute app-card-selected" />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/shared-mailbox-icon.svg"
                                                                alt="Shared Mailbox"
                                                                width={38}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                Shared Mailbox
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                It is a is a collaborative email inbox in Microsoft
                                                                365 or Office 365 that multiple users can ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/rooms-n-equipment-icon.svg"
                                                                alt="Rooms & Equipment's"
                                                                width={39}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                Rooms &amp; Equipment's
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                Ts is streamline booking and management of meeting
                                                                spaces and resources within ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/teams-logo.svg"
                                                                alt="Teams Channel"
                                                                width={43}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                Teams Channel
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                It is a collaborative space within Microsoft Teams
                                                                where team members can ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/teams-logo.svg"
                                                                alt="Teams Chat"
                                                                width={43}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                Teams Chat
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                This allows users to connect with like-minded
                                                                individuals, creating a sense of community ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/Microsoft_Office_OneDrive-logo.svg"
                                                                alt="One Drive"
                                                                width={56}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                One Drive
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                It is a cloud storage service that allows users to
                                                                securely store, share, and access ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                    {/*Cards*/}
                                                    {/* <div className="col-md-3 mb-4 mb-md-5">
                                                        <div className="card h-100 py-4 ps-4 pe-3 border-radius-8 card-hover cursor-pointer">
                                                            <img
                                                                className="mb-2"
                                                                src="/images/Microsoft-SharePoint-Logo.svg"
                                                                alt="SharePoint"
                                                                width={42}
                                                            />
                                                            <h4 className="font-14 font-semibold label-color m-0 mt-3">
                                                                SharePoint
                                                            </h4>
                                                            <p
                                                                className="font-regular label-color font-12 m-0 mt-2"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Tooltip content"
                                                            >
                                                                It is a s a web-based platform by Microsoft that
                                                                enables organizations to create ...
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                    {/*Cards*/}
                                                </div>
                                                <div className="d-md-flex justify-content-end">
                                                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 mb-3 mb-md-0" id='migrationcancel' onClick={(e) => { handleButtons(e) }}>
                                                        Cancel
                                                    </button>
                                                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center mb-3 mb-md-0" id='migrationnext' disabled={selectedItems.length == 0} onClick={(e) => { handleButtons(e) }}>
                                                        Next
                                                        <img src="/images/right-arw.svg" alt="next" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(currentTask.taskType?.toLowerCase() == "assessment" && pageType == "selectapps") && <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">

                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a onClick={() => { history.push('/task') }} className="pe-0">
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a className="pe-0" onClick={() => { history.push('/task') }}>
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"

                                        />
                                    </a>
                                    {currentTask.taskName} - Assessment
                                    {/* User Migration icon */}
                                    {/* <img src="/images/user-migration-icon.svg" alt="User migration" width="23" class="ms-2"> */}
                                    {/* Team logo */}
                                    {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                                    {/* Sharepoint logo */}
                                    {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                                    {/* Outlook logo */}
                                    {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 1) {
                                                                    history.push('/Connectors', {
                                                                        state: {
                                                                            ...location?.state,
                                                                            isEdit: true,
                                                                            stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                        }
                                                                    });
                                                                }
                                                            }}>
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 2) {
                                                                    location?.state?.taskType?.toLowerCase() == "assessment" ?
                                                                        history.push("/AssessmentConfigurations", {
                                                                            state: {
                                                                                ...location?.state
                                                                            },
                                                                        })
                                                                        :
                                                                        history.push("/Configurations", {
                                                                            state: {
                                                                                ...location?.state
                                                                            },
                                                                        })

                                                                }
                                                            }}>
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text "

                                                        >
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 3) {
                                                                    if (location?.state?.stage === 3) {
                                                                        history.push('/selectassessmentapp', {
                                                                            state: {
                                                                                ...location.state,
                                                                                isEdit: true,

                                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                            }
                                                                        });
                                                                    } else {
                                                                        history.push('/Inprogress', {
                                                                            state: {
                                                                                ...location.state,
                                                                                isEdit: true,

                                                                                stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            }

                                                            }>
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                        >
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 mt-4 mt-md-5">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <h3 className="m-0 font-22 font-bold pb-2">Assessment</h3>
                                                </div>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Tooltip content"
                                                        data-bs-original-title=""
                                                        title=""
                                                    />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose apps that you need to proceed with assessment
                                                    </span>
                                                </div>
                                                <div className="form-check form-check-inline mb-4">
                                                    <input
                                                        className="form-check-input cus-check-input"
                                                        type="checkbox"
                                                        name="inlineRadioOptions"
                                                        id="all"
                                                        defaultValue="option1"
                                                        checked={selectedItems.length == 8}
                                                        disabled={location?.state?.isEdit}
                                                        onChange={(e) => { handleSelectAndSelectAll(e) }}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="active"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    {apps?.map((item: any) => {
                                                        return (
                                                            <div className="col-md-3 cust-assessment-col mb-4">
                                                                <div className="card-hover border-radius-8 border p-3 cust-assessment-cards h-100">
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input cus-check-input"
                                                                            type="checkbox"
                                                                            name="inlineRadioOptions"
                                                                            id={`${item.applicationName}?${item.applicationId}`}
                                                                            checked={selectedItems.includes(parseInt(item.applicationId)) || selectedApps.map((item1) => item1.applicationId).includes(parseInt(item.applicationId))}
                                                                            onChange={(e) => handleSelectAndSelectAll(e)}
                                                                            disabled={location?.state?.isEdit}
                                                                        />
                                                                    </div>
                                                                    <div className="d-flex justify-content-center align-content-center flex-column text-center height-40">
                                                                        <div>
                                                                            <img
                                                                                src={`/images/${item.applicationName}.svg`}
                                                                                alt="user-migration"
                                                                                className="mb-3 cust-card-img"
                                                                            />
                                                                        </div>
                                                                        <p className="font-regular font-14 mb-3 color-black">
                                                                            {item.applicationName == "outlook" ? "User Mailbox" : item.applicationName == "groups" ? "Groups" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "One Drive" : item.applicationName == "sharepoint" ? "SharePoint" : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-4 ">
                                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0" id='assessmentback' onClick={(e) => { handleButtons(e) }}>
                                                        <img
                                                            src="/images/left-arw-blue.svg"
                                                            alt="back"
                                                            width={12}
                                                        />
                                                        <span className="ms-1">Back</span>
                                                    </button>
                                                    <div className="d-md-flex d-grid gap-3">
                                                        <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0" id='assessmentcancel' onClick={(e) => { handleButtons(e) }}>
                                                            Cancel
                                                        </button>
                                                        <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" id='assessmentnext' disabled={selectedItems.length == 0 && !location?.state?.isEdit} onClick={(e) => { handleButtons(e) }}>
                                                            Next
                                                            <img src="/images/right-arw.svg" alt="next" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {(currentTask.taskType?.toLowerCase() == "assessment" && pageType == "export") && <div className="container-fluid container-height">
                <div className="row">
                    <div className="col-md-12">
                        {/* {toasterState.failure != '' && <div className="toast-container">
                            <div
                                className="toast show custom-toast-failure"
                                role="alert"
                                aria-live="assertive"
                                aria-atomic="true"
                                style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
                            >
                                <div className="d-flex p-3 pb-0 border-0 align-items-center">
                                    <img
                                        src="/images/failure-toast-icon.svg"
                                        className="rounded me-2"
                                        alt="..."
                                    />
                                    <h1 className="me-auto font-14 font-semibold toast-header-color mb-0">
                                        Failure
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="toast"
                                        aria-label="Close"
                                        onClick={() => setfailure(false)}

                                    />
                                </div>
                                <div className="toast-body px-5 pt-0">
                                    {importexport == 'export' ? ` ${appName} data not has been exported`
                                        : importexport == 'importcheck' ? `Documents template does not match ` : ` ${appName} data has not been imported`}

                                </div>
                            </div>
                        </div>} */}
                        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                            {/* bread crumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li>
                                    <a className="pe-0" onClick={() => { history.push('/task') }}>
                                        Task
                                    </a>
                                </li>
                                <li className="pe-2">{currentTask.taskName}</li>
                            </ul>
                            {/* bread crumb ends here */}
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                                <h5 className="primary-header mb-0 d-flex align-items-center">
                                    <a onClick={() => { history.push('/task') }}>
                                        <img
                                            src="/images/head-back-arrow.svg"
                                            alt="back-arrow"
                                            width={19}
                                            className="me-2"
                                        />
                                    </a>
                                    {currentTask.taskName} - Assessment
                                    {/* User Migration icon */}
                                    {/* <img src="/images/user-migration-icon.svg" alt="User migration" width="23" class="ms-2"> */}
                                    {/* Team logo */}
                                    {/* <img src="/images/teams-logo.svg" alt="teams-logo" width="29" class="ms-2"> */}
                                    {/* Sharepoint logo */}
                                    {/* <img src="/images/Microsoft-SharePoint-Logo.svg" alt="Sharepoint-logo" width="28" class="ms-2"> */}
                                    {/* Outlook logo */}
                                    {/* <img src="/images/ms-outlook-logo.svg" alt="outlook-logo" width="28" class="ms-2"> */}
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card p-md-4 border-0 rounded-3">
                                        <div className="row justify-content-center">
                                            <div className="col-md-7">
                                                <div className="d-flex justify-content-between align-items-center step-wizard">
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {
                                                            if (location?.state?.taskId && location?.state?.isEdit) {
                                                                return history.push('/SelectApps', {
                                                                    state: {
                                                                        ...location?.state,
                                                                        pageType: 'selectapps',
                                                                        isEdit: true,
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    }
                                                                })

                                                            }
                                                            else {

                                                                history.push("/SelectApps", {
                                                                    state: {
                                                                        // applicationName: location?.state?.applicationName,
                                                                        // applicationId: location?.state?.applicationId,
                                                                        ...location?.state,
                                                                        isEdit: true,
                                                                        pageType: 'selectapps',
                                                                        stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                                                    },
                                                                });
                                                            }
                                                        }
                                                        }>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                        >
                                                            1
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                                                            Select App
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        isEdit: true,
                                                                        ...location?.state,
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            2
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text"
                                                            onClick={() => {
                                                                history.push('/Connectors', {
                                                                    state: {
                                                                        isEdit: true,
                                                                        ...location?.state,
                                                                        stage: (location?.state?.stage && location?.state?.stage > stage) ? location?.state?.stage : stage
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            Connectors
                                                        </span>
                                                    </div>

                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                                                        onBlur={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={() => {

                                                            history.push("/AssessmentConfigurations", {
                                                                state: {
                                                                    ...location?.state
                                                                },
                                                            }

                                                            )
                                                        }}>
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                                                            3
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                                                            Configurations
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                                                        <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                                                            4
                                                        </div>
                                                        <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                                                            Assessment
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-9 mt-4 mt-md-5">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <h3 className="m-0 font-22 font-bold pb-2">Assessment</h3>
                                                </div>
                                                <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                                                    <img
                                                        src="/images/tooltip.svg"
                                                        alt="tooltip"
                                                        width={11}
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-title="Tooltip content"
                                                        data-bs-original-title=""
                                                        title=""
                                                    />
                                                    <span className="lh-1 font-12 cust-role-color">
                                                        Choose apps that you need to proceed with assessment
                                                    </span>
                                                </div>
                                                <div className="form-check form-check-inline mb-4">
                                                    <input
                                                        className="form-check-input cus-check-input"
                                                        type="checkbox"
                                                        name="inlineRadioOptions"
                                                        id="allassess"
                                                        defaultValue="option1"
                                                        onChange={(e) => { handleSelectAndSelectAll(e) }}
                                                        checked={selectedItems.length == selectedApps?.length}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-regular"
                                                        htmlFor="active"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    {selectedApps?.map((item) => {
                                                        debugger
                                                        if (selectedApps) {
                                                            //console.log("Inside the gb");

                                                        }
                                                        return (
                                                            <>
                                                                <div className="col-md-3 cust-assessment-col mb-4">
                                                                    <div className="card-hover border-radius-8 border p-3 cust-assessment-cards h-100">
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                className="form-check-input cus-check-input"
                                                                                type="checkbox"
                                                                                name="inlineRadioOptions"
                                                                                id={`1?${item.applicationId}`}
                                                                                defaultValue="option1"
                                                                                onChange={(e) => { handleSelectAndSelectAll(e) }}
                                                                                checked={selectedItems.length == selectedApps?.length}
                                                                            />
                                                                        </div>
                                                                        <div className="d-flex justify-content-center align-content-center flex-column text-center height-40">
                                                                            <div>
                                                                                <img
                                                                                    src={`/images/${item.applicationName}.svg`}
                                                                                    alt="user-migration"
                                                                                    className="mb-3 cust-card-img"
                                                                                />
                                                                            </div>
                                                                            <p className="font-regular font-14 mb-3 color-black">
                                                                                {item.applicationName == "outlook" ? "User Mailbox" : item.applicationName == "groups" ? "Groups" : item.applicationName == "sharedmailbox" ? "Shared Mailbox" : item.applicationName == "roomsandequipments" ? "Rooms & Equipment's" : item.applicationName == "teamschannel" ? "Teams Channel" : item.applicationName == "teamschat" ? "Teams Chat" : item.applicationName == "onedrive" ? "One Drive" : item.applicationName == "sharepoint" ? "SharePoint" : null}
                                                                            </p>
                                                                            <div className="d-flex justify-content-center gap-3 task-assess-btn">
                                                                                <button className="btn task-assessment-btn primary-outline-btn py-0 px-3 d-flex align-items-center justify-content-center" onClick={() => { handleExport(item.applicationName) }}>
                                                                                    <img
                                                                                        src="/images/excel-export-icon.svg"
                                                                                        alt="Export"
                                                                                    />
                                                                                </button>
                                                                                <button className="btn task-assessment-btn primary-outline-btn py-0 px-3 d-flex align-items-center justify-content-center" onClick={() => { setChosenApp("outlook") }}>
                                                                                    <img src="/images/share-icon.svg" alt="Share" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-4 ">
                                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0" id='assessmentexportimportback' onClick={(e) => { handleButtons(e) }}>
                                                        <img
                                                            src="/images/left-arw-blue.svg"
                                                            alt="back"
                                                            width={12}
                                                        />
                                                        <span className="ms-1">Back</span>
                                                    </button>
                                                    <div className="d-md-flex d-grid gap-3">
                                                        <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0" id='assessmentexportimportcancel' onClick={(e) => { handleButtons(e) }}>
                                                            Cancel
                                                        </button>
                                                        <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" id='startassessment' disabled={selectedItems.length == 0} onClick={(e) => { handleButtons(e); insertLastVisitedPageValue("Inprogress") }}>
                                                            Start Assessment
                                                            <img src="/images/right-arw.svg" alt="next" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {loader && <div className="container-fluid">
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            </div>}
            {chosenApp && <div
                className="modal fade show "
                id="TeamsChannelCreation"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="TeamsChannelCreationLabel"
                aria-hidden="true"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                hidden={false}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-4">
                        <div className="modal-header border-0 p-0">
                            <h1
                                className="font-20 font-bold custom-header-color m-0"
                                id="TeamsChannelCreationLabel"
                            >Import {chosenApp == "outlook" ? "User Mailbox" : ""} Details

                            </h1>
                            <button onClick={() => { setChosenApp(""); setImportData([]); setFileInputValue(""); setValidateImport({ status: false, message: "" }) }}
                                type="button"
                                className="btn-close shadow-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>

                        <div className="modal-body border-0 p-0 pb-2">
                            <div className="row">

                                <div className="col-md-12 mt-3">
                                    <div className="mb-1">
                                        <label className="font-15 font-semibold label-color w-100 mb-2 pb-1">
                                            Import {chosenApp == "outlook" ? "User Mailbox" : ""} Details <span className="mandatory-color">*</span>
                                        </label>
                                        <div className="upload-btn-wrapper me-2 d-block cursor-pointer">
                                            <div className="input-group mb-3">
                                                <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">
                                                    Browse
                                                    <input className="form-control" type="file" id="upload" onChange={(e: any) => { handleDownloadUpload(e) }} />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control file-input-field"
                                                    placeholder="No file chosen"
                                                    aria-label="Example text with button addon"
                                                    value={fileInputValue}
                                                    readOnly

                                                />
                                            </div>
                                            {validateImport.status && <span className="mandatory-color">{validateImport.message}</span>}
                                        </div>
                                    </div>
                                    <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0" id='download' onClick={(e) => { handleDownloadUpload(e) }}>
                                        <img src="/images/download-icon.svg" alt="back" width={12} />
                                        <span className="ms-1">Download Template</span>
                                    </button>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="button" className="btn primary-btn" disabled={importData.length == 0} onClick={(e) => { handleImport(e) }}>
                                            Import
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {(invalidData.length != 0) && <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                Invalid Data provided for Import
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { handleInvalidDataDownload() }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>}

            {(popup.errorMessage || popup.successMessage) && <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {popup.errorMessage || popup.successMessage}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { setPopup({ successMessage: '', errorMessage: '' }) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                { }
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => {
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                    }
                                    setAlert('')
                                }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {/*Failure Popup ends here */}

        </>

    )
};
