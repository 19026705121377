import { client } from "../Client/Client";
import { BE_URL } from "../Common/Constant";
import { ResponseInterface } from "../Common/Interface";
export async function getAllDropDownValue(data:object) {
 
    const response = await client({
      method: 'POST',
      url: BE_URL + '/configuration/userMapping/getUserMappingNames',
      //url: 'http://localhost:8080/userMapping/getUserMappingNames',
    //   url: BE_URL + appSpecificConfig.fetchUserOptionsFromDatabase,
      data: data
    });
    return response;
  };
  export async function insertMappingUser(data:object) {
 
    const response = await client({
      method: 'POST',
      url: BE_URL+'/configuration/userMapping/createUserMapping',
      //url: 'http://localhost:8080/userMapping/createUserMapping',
      data: data
    });
    return response;
  };


  export async function insertSingleMappingUser(data:object) {
 
    const response = await client({
      method: 'POST',
      url: BE_URL+'/configuration/userMapping/insertSingleMappedUser',
      //url: 'http://localhost:8080/userMapping/insertSingleMappedUser',
      data: data
    });
    return response;
  };

  export async function getMappedUsers(mappedUsersPayload: any): Promise<ResponseInterface> {
    let config = {
        method: 'POST',
        url:BE_URL+'/configuration/userMapping/getMappedUsers',
        //url: 'http://localhost:8080/userMapping/getMappedUsers',
        headers: {
            'Content-Type': 'application/json'
        },
        data: mappedUsersPayload
    }
    const response: any = await client(config);
    //console.log(config,"uyhgfdcv");
    return response;
};


export async function getUsers(usersPayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/getUsers',
      //url: 'http://localhost:8080/userMapping/getUsers',
      headers: {
          'Content-Type': 'application/json'
      },
      data: usersPayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};

export async function deleteMappedUser(deletePayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/deleteMappedUser',
      //url: 'http://localhost:8080/userMapping/deleteMappedUser',
      headers: {
          'Content-Type': 'application/json'
      },
      data: deletePayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};


export async function updateUserMapping(deletePayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/updateUserMappingStatus',
      //url: 'http://localhost:8080/userMapping/updateUserMappingStatus',
      headers: {
          'Content-Type': 'application/json'
      },
      data: deletePayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};


export async function insertNewMappingWithEdit(deletePayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/userMappingActions',
      //url: 'http://localhost:8080/userMapping/userMappingActions',
      headers: {
          'Content-Type': 'application/json'
      },
      data: deletePayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};


export async function importMappedUsers(mappedPayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/importMappedUsers',
      // url: 'http://localhost:8080/userMapping/importMappedUsers',
      headers: {
          'Content-Type': 'application/json'
      },
      data: mappedPayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};

export async function checkUserMappingName(mappedPayload: any): Promise<ResponseInterface> {
  let config = {
      method: 'POST',
      url:BE_URL+'/configuration/userMapping/getUserMappingNames',
      //url: 'http://localhost:8080/userMapping/getUserMappingNames',
      headers: {
          'Content-Type': 'application/json'
      },
      data: mappedPayload
  }
  const response: any = await client(config);
  //console.log(config,"uyhgfdcv");
  return response;
};


export async function insertUserMappingForTask(data:object) {
 
  const response = await client({
    method: 'POST',
    url: BE_URL + '/configuration/userMapping/insertUserMappingForTask',
    //url: 'http://localhost:8080/userMapping/insertUserMappingForTask',
  //   url: BE_URL + appSpecificConfig.fetchUserOptionsFromDatabase,
    data: data
  });
  return response;
};