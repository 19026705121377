import { useEffect, useState } from "react";
import { OutlookData, sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getAssessmentSummary } from "../../Services/AssessmentSummaryServices";
import { useHistory, useLocation } from "react-router";
import objCryptoJS from "crypto-js";
import { commonErrorMsgs } from "../../Common/Constant";
import * as XLSX from 'xlsx';



export default function AssessmentSummary() {
    /**PS_01 to PS_14 will be executed
 */
    const [alert, setAlert] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    let location: any = useLocation().state;
    let taskName = location?.state?.taskName;

    const history = useHistory()
    const [isCustomized, setIsCustomized] = useState<boolean>(true);
    // PS_03
    const [connectionName, setConnectionName] = useState<string>('');
    // PS_04
    const [assessmentTimeTaken, setAssessmentTimeTaken] = useState<Date | null>(null);
    // PS_05
    const [assessmentStartDate, setAssessmentStartDate] = useState<Date | null>(null);
    // PS_06
    const [assessmentEndDate, setAssessmentEndDate] = useState<Date | null>(null);
    // PS_07
    const [customizedStartDate, setCustomizedStartDate] = useState<Date | null>(null);
    // PS_08
    const [customizedEndDate, setCustomizedEndDate] = useState<Date | null>(null);

    // PS_11
    const [status, setStatus] = useState<string>('');
    const [totalCount, settotalCount] = useState<number>(0);
    const [folderCount, setFolderCount] = useState<number>(0);
    const [progressCount, setProgressCount] = useState<number>(0);
    const [Completion, setCompletion] = useState<number>(0);
    const [Size, setSize] = useState<string>('0 GB');
    // PS_12

    // PS_13
    const [exportData, setExportData] = useState<any[]>([]);

    // PS_14


    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [clientName, setclientName] = useState<string>('');
    let [companyName, setCompanyName] = useState<string>('');
    let [emailAddress, setEmailAddress] = useState<string>('');
    let [role, setRole] = useState<string>('');
    let [isAdmin, setIsAdmin] = useState<boolean>(false);
    let [adminName, setAdminName] = useState<string>('');
    let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
    let [adminrole, setAdminRole] = useState<string>('');

    useEffect(() => {
        sessionTokenProperties()
        fetchData()

    }, []);
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    history.push("/Admin/Login")
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    setEmailAddress(tokenResponse.clientDetails.Email);
                    setRole(tokenResponse.clientDetails.Role);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getStatusCSS = (status: string) => {
        switch (status) {
            case "failed":
                return "danger";
            case "queued":
                return "draft";
            case "draft":
                return "draft";
            case "cancelled":
                return "draft";
            case "scheduled":
                return "scheduled";
            case "inprogress":
                return "warning";
            case "in progress":
                return "warning";
            case "completed":
                return "success";
            case "paused":
                return "paused";
            default:
                return "";
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    /**PS_32 to PS_45 will be executed
     */
    const fetchData = async () => {
        try {
            setLoader(true)
            let token = await getToken()
            
            let payload = {
                token: token,
                taskId: location?.state?.taskId
            }
            // Simulated API fetch call
            /**PS_46 to PS_55 will be executed
 */
            const response: any = await getAssessmentSummary(payload)
            //console.log(response.data.data, "shankar");

            if (response?.data?.statusCode) {
                setLoader(false)
                // Update states with the data received from the response object
                setConnectionName(response?.data?.data?.connectionName);
                setAssessmentStartDate(response?.data?.data?.AssessmentDateAndTime?.assessmentInitatedDate)
                setAssessmentTimeTaken(response?.data?.data?.AssessmentDateAndTime?.timeTakenFormatted);
                setAssessmentEndDate(response?.data?.data?.AssessmentDateAndTime?.modifiedDate);
                setCustomizedStartDate(response?.data?.data?.customizedStartDate)
                setCustomizedEndDate(response?.data?.data?.customizedEndDate)
                settotalCount(response?.data?.data?.count?.SourceUserCount)
                setProgressCount(response?.data?.data?.count?.OutlookMasterInventoryCount)
                setExportData(response?.data?.data?.exportData)
                setSize(response?.data?.data?.totalSize)
                setFolderCount(response?.data?.data?.folderCount)
                setCompletion(Math.round((response?.data?.data?.percentage) * 100)/100)
                setStatus(response?.data?.data?.status)
                if (response?.data?.data?.nonCustomizedApp?.length > 0) {
                    setIsCustomized(false)
                }
            }
            else {
                setLoader(false)
                setAlert(true)
                setAlertMessage(commonErrorMsgs.generalError)
            }

        } catch (error: any) {
            setAlert(true)
            setLoader(false)
            setAlertMessage(commonErrorMsgs.generalError)
        }
    };

    /**PS_56 to PS_66 will be executed
     */
    const exportExcel = async () => {
        try {
            setLoader(true)
            // Construct JSON object for the report
            let customizedStart = customizedStartDate != null ? customizedStartDate : ""
            let customizedEnd = customizedEndDate != null ? customizedEndDate : ""
            let customizedDate = `${customizedStart}-${customizedEnd}`
            const reportData = {
                ConnectionName: connectionName,
                AssessmentStartDateTime: assessmentStartDate,
                AssessmentEndDateTime: assessmentEndDate,
                CustmizationDates: customizedDate,
                TotalSize: Size,
                FolderCount: folderCount,
                totalTimeTaken: assessmentTimeTaken
            };

            const workbook = XLSX.utils.book_new();
            // Convert JSON object to worksheet
            const worksheet = XLSX.utils.json_to_sheet([reportData]);


            XLSX.utils.sheet_add_aoa(worksheet, [[''], ['']], { origin: -1 });

            const allData = {
                "Customized/NonCustomized": isCustomized ? 'Customized' : 'Non-Customized',
                "Application": "outlook",
                "size": Size,
                "AssessmentCompletedCount": progressCount,
                "TotalCount": totalCount,
                "status": status
            };

            //   // Append the 'allData' object to the worksheet after the new line
            XLSX.utils.sheet_add_json(worksheet, [allData], { origin: -1 });
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Overall Summary');
            const filteredData = exportData?.map((user: any) => ({
                "User Name": user.UserDisplayName,
                "Mails- count": user.TotalMailCount,
                "Mailbox- Size": user.TotalItemSizeInGB,
                "Contacts-count": user.TotalContactCount,
                "Todo-count": user.TotalTodoTaskCount,
                " Calendar-count ": user.TotalCalendarEventCount,
                "Status": user.inventoryStatus,
                "Error Log": user.customError?user.customError:""
            }));
            const ws1 = XLSX.utils.json_to_sheet(filteredData);
            XLSX.utils.book_append_sheet(workbook, ws1, 'User MailBox');
            XLSX.writeFile(workbook, 'OverAllAssessmentSummary.xlsx');
            setLoader(false)


        } catch (error: any) {
            setLoader(false)
            setAlert(true)
            setAlertMessage(commonErrorMsgs.generalError)
        }
    };

    return (<>
        {loader && (
            <div className="overlay">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div
                        className="spinner-border Loader loader-color align-center "
                        role="status"
                    >
                        <span>
                            <span className="loader-inner-circle" />
                        </span>
                    </div>
                    <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                        Loading..
                    </p>
                </div>
            </div>
        )}
        <div className="container-fluid container-height">
            <div className="row">
                <div className="col-md-12">
                    <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                        {/* bread crumb starts here */}
                        <ul className="breadcrumb-item mb-4 ps-0">
                            <li>
                                <a href="/Task" className="pe-0">
                                    Task
                                </a>
                            </li>
                            <li className="pe-2">{taskName}</li>
                        </ul>
                        {/* bread crumb ends here */}
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
                            <h5 className="primary-header mb-0 d-flex align-items-center">
                                <a href="/Task">
                                    <img
                                        src="/images/head-back-arrow.svg"
                                        alt="back-arrow"
                                        width={19}
                                        className="me-2"
                                    />
                                </a>
                                {taskName} - Assessment
                            </h5>
                            <button className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => exportExcel()}>
                                <img src="/images/excel-icon-white.svg" alt="excel" width={13} />{" "}
                                Export
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card p-md-4 border-0 rounded-3">
                                    <div className="row justify-content-center">
                                        <div className="row float-start div-border">
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Connection Name
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {connectionName}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Assessment Start Date & Time
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentStartDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Assessment End Date & Time
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentEndDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 float-start mt-4 mb-2">
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Customization Dates
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {customizedStartDate} -  {customizedEndDate}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="FileSize"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title ">
                                                        Total size
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {Size}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="MessageCount"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title">
                                                        Folder Count
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {folderCount}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="FileCount"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title ">
                                                        File Count{" "}
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        0
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                id="FileCount"
                                                className="col-md-4 float-start mt-4 mb-2"
                                            >
                                                <div className="float-start">
                                                    <span className="float-start w-100 font-14 font-regular widget-title ">
                                                        Total Time Taken
                                                    </span>
                                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">
                                                        {assessmentTimeTaken}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5">
                                            <h3 className="mb-2 font-22 font-bold pb-2">
                                                {isCustomized ? 'Customized Apps' : 'Non-Customized Apps'}
                                            </h3>
                                            <div className="row">
                                                {/*Second card starts here*/}
                                                <div className="col-md-4 col-lg-4 col-xl-3 mb-4">
                                                    <div className="border-radius-8 border p-3 h-100">
                                                        <div className="d-flex justify-content-between">
                                                            <span>
                                                                <img
                                                                    src="/images/user-mailbox.svg"
                                                                    alt="user-migration"
                                                                    className="mb-2 me-2 cust-card-img"
                                                                />
                                                                <span className="font-semibold font-14 mb-3 color-black">
                                                                    User Mailbox
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="row mb-2 pb-2">
                                                            <div className="col-5 cust-count-line position-relative pe-0">
                                                                <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                    Count
                                                                </p>
                                                                <span className="font-semibold font-10">{progressCount} out of {totalCount}</span>
                                                            </div>
                                                            <div className="col-6 pe-0">
                                                                <p className="mb-0 font-10 font-regular cust-gray-color">
                                                                    Size
                                                                </p>
                                                                <span className="font-semibold font-20">
                                                                    {Size}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className="font-regular font-12 form-label-color mb-1">
                                                            <span
                                                                className={`table-status ${getStatusCSS(
                                                                    status?.toLowerCase()
                                                                )} d-inline-block me-2 `}
                                                            ></span>
                                                            {status} {Completion}%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {alert ? <div
            className="modal fade show"
            tabIndex={-1}
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content popup-brd-radius">
                    <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                        <span className="position-absolute">
                            <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                        </span>
                    </div>
                    <div className="modal-body border-0 text-center">
                        <h5
                            className="modal-title mb-3 primary-header"
                            id="Failure-popupLabel"
                        >
                            {alertMessage}
                        </h5>
                        <p className="primary-data">
                            {alert}
                        </p>
                    </div>
                    <div className="modal-footer border-0 pb-4 justify-content-center">
                        <button
                            type="button"
                            className="btn primary-btn font-14 px-4 font-semibold"
                            onClick={() => { setAlert(false) }}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div> : null}
    </>
    )

}


