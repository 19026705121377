import { BE_URL } from '../../Common/Constant';

import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import { fetchApplicationNames, getUserMailboxData } from '../../Services/assessmentGridService';

import moment from "moment";

import { useHistory } from 'react-router-dom';

import Loader from "../Common/Loader";

import { decodeSessionDetails } from "../../Services/CommonServices";

import objCryptoJS from "crypto-js";

import { DataType, AppSpecificGridProps, SelectedAppValue, SelectedAppValues, sessionResponse } from '../../Common/Interface'

import { w3cwebsocket as WebSocket } from 'websocket';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

// Assuming a placeholder definition of DataType, modify as needed


// Define other attributes (PS_12 to PS_18) potentially as a separate type or within the component itself

const AppSpecificGrid: React.FC<AppSpecificGridProps> = ({ setLoaderFalse, taskId, applicationName, searchQuery }) => {
  // Initialize useState (PS_2 to PS_9, SQ_EX_10)
  const defaultPageSize = 10; // Placeholder value

  ///const location: any = useLocation().state

  const history = useHistory();

  const location: any = useLocation().state

  // Initialize selectedappValues with selectedAppInterface configuration (PS_11)
  const selectedappValues: SelectedAppValues = {
    outlook: [{
      header: "User Name",
      value: "userName",
      sort: "su.displayName",
      className: "text-left",
      isSize: false
    },
    {
      header: "Mails",
      value: "outlookMailCount",
      sort: "outlookMailCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Mailbox",
      value: "outlookMailStorageUsed",
      sort: "outlookMailStorageUsed",
      className: "text-end",
      isSize: true
    }, {
      header: "Contacts",
      value: "outlookContactCount",
      sort: "outlookContactCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Todo",
      value: "outlookTodoTaskCount",
      sort: "outlookTodoTaskCount",
      className: "text-end",
      isSize: false
    }, {
      header: "Calendar",
      value: "outlookCalendarEventCount",
      sort: "outlookCalendarEventCount",
      className: "text-end",
      isSize: false
    },
    {
      header: "Status",
      value: "inventoryStatus",
      sort: "inventoryStatus",
      className: "text-end",
      isSize: false,
      isStatus: true
    }],
  }

  const [data, setData] = useState<DataType[]>([]);
  const [renderapplicationName, setRenderApplicationName] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [loading, setLoading] = useState<boolean>(false);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [sortHide, setSortHide] = useState<string>('');

  // Initialize token (PS_10)
  //   PS_1 PS_2 PS_3 PS_4 PS_5 PS_6 PS_7 PS_8 PS_9 PS_10 
  // PS_11 PS_12 PS_13 PS_14 PS_15 PS_16 PS_17 PS_18 PS_19 
  //const [token, setToken] = useState({"clientToken":"U2FsdGVkX19MvwmIUmulsSQUii83m/ICs7Mg/0qD/0WAi4Qi0PvP2g8nV3zz41Ha+9qx/inRTQ0MuOv3N708dclk86udu0QKmXSPLEDlonrBQMNCUH+gi0358kH4XP2Ap7wb9R0/BPwCXIMJpS5/VmH76XPx3l4Mc8oMCk7DlT+Qhdek8Z17JWTCmHhikhzTtiiQfpuXmrUwaBKegiLYLD81i04h930XrM/4LQZ5u95wOVZLSwf9B51AQB0ABg0JUzT0k4D4H18WwBLHy4kEdTxr63awpVrPEoJzeoWbMMyCYpDnd8p7h9g9EkCCkKeMtdZ6iIfVyE8/hFo9Qi3zwwcfbrSWkMGK3Y5u+dA6iIKZmdUbxemwpmxOAoo1/JWjgoniJDatndOUfLuNcBCy60KvClIGxEC+oFei5qxe2wEt79/t89q+uE3xNyc5Vzf5vvd+0Fu9WJkyf8pysxNVpJE1kJir0jm4tz/HFRhOMa4TtwBy7HE/ELbUgD6AckeQV26GlJtzrEYVuBw2blWJdzeuguMUorYtUtmn9zfG3RIvMvujzEgypcehlHhn+XJ2SpZ4bniylQXLtQfrNSAZRaflhiCBJ+K26Up471sfKPh0nqmhT/ycCqu5wKrZtxi74jWa8hSqkXb6yaa33KyBSDzLNaGp3E0Bfbctip3uLlF5e8+Kk7yJU+ThKSnaXbYF08gqvV6EsziAJHky3W410Kn2xUjzyOCvCs9VBsiEee4GQULZzEgq4jsxeabz6KXNtrsVD0k7IdwZDYTQWugcYwPhsXj3aNlTlu4was1KHFsjXJL3isiC9IxXVLR4v41iEbDJqBa+bguKed8z189EshPvuwbrfCL5x2r1NzySILk="}); // Gets token from session assuming there's some getSessionToken method

  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  const [role, setRole] = useState<string>('');
  let client: any = ''
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;
  const [isFetching, setIsFetching] = useState(false)
  // useEffect(() => {


  //   // Cleanup function
  // return () => {
  //   client.close();
  // };
  // }, []);


  // PS_21 PS_22 PS_23 PS_24 PS_25 PS_26 PS_27 PS_28 PS_29 PS_30
  useEffect(() => {
    // Fetch user data when component mounts 
    // if(client){
    //   client.close();
    // }

    sessionTokenProperties();
    resetTimer();
    setSortOrder("asc")
    setTotalDataCount(0)
    setPageSize(defaultPageSize)
    setSortBy(applicationName.toLocaleLowerCase().trim() == 'outlook' ? 'su.displayName' : '')
    setRenderApplicationName(applicationName)

    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

    // return () => {
    //   client.close();
    // };
  }, [applicationName]);

  useEffect(() => {
    // Fetch user data when component mounts
    // if(client){
    //   client.close();
    // }
    if (!isFetching) {
      const intervalId = setInterval(() => {
        fetchDataToBind("initiaload").catch(console.error); // Handle any errors from the async call
      }, 5000);
      //const intervalId = setInterval(fetchDataToBind("initiaload"), 5000); // Call every 5 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount

    }

  }, [pageSize, sortBy, sortHide, searchQuery, renderapplicationName, isFetching]);

  useEffect(() => {
    // Reset pageSize to default when searchQuery changes
    if (!searchQuery) {
      setPageSize(defaultPageSize);
    }
  }, [searchQuery]);


  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
      }
      else {
        history.push("/Login")
      }
      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);
      }
      // else {
      //     history.push("/Admin/Login")
      // }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }


  // Component rendering logic and interactions would be defined here.
  // PS_32 PS_33 PS_34 PS_35 PS_36 PS_37 PS_38 PS_39 PS_40 PS_41 
  // PS_42 PS_43 PS_44 PS_45 PS_46 PS_47 PS_48 PS_49 PS_50 PS_51 
  // PS_52 PS_53 PS_54 PS_55  and 	Ps56 
  const fetchDataToBind = async (type: string) => {
    // setLoading(true);

    try {

      let token = await getToken()
      // if (applicationName.toLocaleLowerCase().trim() === 'outlook') {

      //   const client = new WebSocket(BE_URL+'/task');

      //   client.onopen = () => {
      //     debugger;
      //     //console.log('WebSocket connection opened');
      //     let payload = {
      //       searchValue: searchQuery.trim().toLocaleLowerCase(),
      //       pageSize: pageSize,
      //       sortBy: sortBy,
      //       sortOrder: sortOrder,
      //       Action: "data",
      //       taskId: taskId,
      //       token: token,
      //       applicationName: applicationName,
      //       page:'assessmentGrid'
      //     }

      //     if (client.readyState === WebSocket.OPEN) {
      //       type == "initiaload" ? setLoading(true) : setLoading(false)
      //       client.send(JSON.stringify(payload));
      //     }
      //   };

      //   // Send the payload immediately if the WebSocket connection is already open
      //   if (client.readyState === WebSocket.OPEN) {
      //     client.onopen();
      //   }

      //   client.onmessage = async (event: any) => {
      //     //console.log(event, 'sssssssssssssssssssssss');
      //     const response = JSON.parse(event.data);
      //     if (response.statusCode !== 200) {
      //       setAlert(response.data.statusMessage);
      //       setLoading(false);
      //       return;
      //     }
      //     //console.log(response.data.data, "bind data")
      //     setData(response.data.data);
      //     setTotalDataCount(response.data.totalData);
      //     type == "initiaload" ? setLoading(false) : setLoading(false)
      //     type = '';
      //   };

      //   client.onerror = (error: any) => {
      //     console.error('WebSocket error:', error);
      //   };

      //   client.onclose = () => {
      //     //console.log('WebSocket connection closed');
      //   };

      //   return () => {
      //     client.close();
      //   };

      // }

      if (applicationName.toLocaleLowerCase().trim() === 'outlook') {
        let payload = {
          searchValue: searchQuery.trim().toLocaleLowerCase(),
          pageSize: pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
          Action: "data",
          taskId: taskId,
          isApi: true,
          token: token,
          applicationName: applicationName,
          page: 'assessmentGrid'
        }


        const response: any = await getUserMailboxData(payload);

        if (response.data.statusCode !== 200) {
          setAlert(response.data.statusMessage);
          setLoading(false);
          return;
        }
        //console.log(response.data.data, "bind data")
        setData(response.data.data.data);
        setTotalDataCount(response.data.data.totalData);

        if(setLoaderFalse){
          setLoaderFalse()
        }
      }

    }
    catch (error: any) {
      console.log("this is error in assessment", error.message);

      setAlert("An error occurred during data fetching");
    }
    finally {
      setLoading(false);
    }
  };

  // PS_57
  const handleLoadMore = () => {
    setPageSize(pageSize + 10);
  };

  // PS_58 PS_59 PS_60 PS_61 PS_62 PS_63 
  const handleSort = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    try {
      let sortID: string = e.currentTarget.id;
      let sortval: Array<string> = sortID.split("@");
      setSortBy(sortval[0]);
      setSortOrder(sortval[1]);
      setSortHide(sortID);
    } catch (error) {
      //console.log(error);
    }
  };


  const getStatusCSS = (status: string) => {
    switch (status) {
      case "failed":
        return "danger";
      case "queued":
        return "draft";
      case "draft":
        return "draft";
      case "cancelled":
        return "draft";
      case "scheduled":
        return "scheduled";
      case "inprogress":
        return "warning";
      case "in progress":
        return "warning";
      case "completed":
        return "success";
      case "paused":
        return "paused";
      default:
        return "";
    }
  };

  // PS_64 PS_65 PS_66 PS_67 PS_68 PS_69 PS_70 PS_71 PS_72 PS_73 PS_74 PS_75 
  function formatBytes(size: number): string {
    const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex: number = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(2) + ' ' + units[unitIndex];
  }

  //console.log(pageSize, "  ", totalDataCount)
  return (
    <div>
      <div className="table-responsive theme-table bg-white d-inline-block w-100 table-y-scroll">
        <table className="table table-borderless mb-0">
          <thead className="sticky-top">
            <tr>
              {


                selectedappValues[applicationName.toLocaleLowerCase().trim()]?.map(({ header, value, sort, className }, index) => {

                  return (<>
                    <th
                      className={`text-nowrap ${className}`}
                    >
                      {header}
                      <span
                        className="ms-1 cursor-pointer"

                      >
                        <img
                          src={`/images/sort-arrow-up.svg`}
                          alt="sort-arrow"
                          style={{ cursor: 'pointer' }}
                          id={`${sort}@DESC`} hidden={
                            sortHide == `${sort}@ASC` || ""
                              ? false
                              : true
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}

                        />
                        <img
                          src={`/images/sort-arrow-down.svg`}
                          alt="sort-arrow"
                          id={`${sort}@ASC`}
                          style={{ cursor: 'pointer' }}
                          hidden={
                            sortHide == `${sort}@ASC` || ""
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            handleSort(e);
                          }}
                        />
                      </span>
                    </th>
                  </>)
                })
              }

            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((data, index) => (
                <tr key={index}>
                  {
                    selectedappValues[applicationName.toLocaleLowerCase().trim()].map(({ header, value, className, isSize, isStatus }, index) => {

                      return (
                        <>
                          {(isSize)
                            ? <td className={className} style={{ textTransform: header?.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>{formatBytes(Number(data[value]))}</td>
                            :
                            (isStatus) ? <td className={className} style={{ textTransform: "capitalize" }}>
                              <span className="text-nowrap text-start">
                                <span
                                  className={`table-status ${getStatusCSS(
                                    data[value]?.toLowerCase()
                                  )} d-inline-block me-2 `}
                                ></span>
                                {data[value]}
                              </span>
                            </td>
                              :
                              (data[value]?.length > 21) ? (
                                <td className={`${className}`} style={{ textTransform: header?.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    data-bs-title="Type Team Site"
                                    // className=""
                                    data-bs-original-title=""
                                    title={data[value]}
                                  >
                                    {data[value]?.slice(0, 21) + '...'}
                                  </span>
                                </td>
                              ) : (data[value]?.toString()?.length > 0) ?

                                (

                                  <td className={className} style={{ textTransform: header?.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>{data[value]}</td>
                                ) : (
                                  <td className={className} style={{ textTransform: header?.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>-</td>
                                )}
                        </>
                      );
                    })
                  }
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={Number(`${selectedappValues[applicationName.toLocaleLowerCase().trim()].length}`)}>
                  <div className="text-center">
                    <div className="pt-4 pb-3 text-center">
                      <img
                        src="/images/no-data-found-img.svg"
                        alt="no-data-found-img"
                        className="mb-3"
                      />
                      <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                      <p className="font-10 font-regular color-no-rec">
                        There is no data to show you right now
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn primary-outline-btn font-14 font-semibold"
          onClick={() => handleLoadMore()}
          hidden={pageSize >= totalDataCount}
        >
          Load More
        </button>
        {loading && (
          <div className="container-fluid">
            <div className="overlay">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div
                  className="spinner-border Loader loader-color align-center "
                  role="status"
                >
                  <span>
                    <span className="loader-inner-circle"></span>
                  </span>
                </div>
                <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                  Loading..
                </p>
              </div>
            </div>
          </div>
        )}

        {/*Failure Popup starts here */}
        {alert ? <div
          className="modal fade show"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content popup-brd-radius">
              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                <span className="position-absolute">
                  <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                </span>
              </div>
              <div className="modal-body border-0 text-center">
                <h5
                  className="modal-title mb-3 primary-header"
                  id="Failure-popupLabel"
                >
                  { }
                </h5>
                <p className="primary-data">
                  {alert}
                </p>
              </div>
              <div className="modal-footer border-0 pb-4 justify-content-center">
                <button
                  type="button"
                  className="btn primary-btn font-14 px-4 font-semibold"
                  onClick={() => {
                    if (isSessionEpired) {
                      history.push('/Login')
                    }
                    setAlert('')
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div> : null}
        {/*Failure Popup ends here */}
      </div>
    </div>
  );
};

export default AppSpecificGrid;