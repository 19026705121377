import { client } from "../Client/Client";
import { appSpecificConfig, BE_URL, dashboard } from "../Common/Constant";
 
export async function postOutlookCustomization(selectedOptions:object) {
 
    const response = await client({
      method: 'POST',
    // url: 'http://localhost:8080/appSpecificConfig/postOutlookCustomization',
      url: BE_URL + appSpecificConfig.postOutlookCustomization,
      data: selectedOptions
    });
    return response;
  };
  export async function fetchUserOptionsFromDatabase(data:object) {
 
    const response = await client({
      method: 'POST',
     // url: 'http://localhost:8080/appSpecificConfig/fetchUserOptionsFromDatabase',
      url: BE_URL + appSpecificConfig.fetchUserOptionsFromDatabase,
      data: data
    });
    return response;
  };