// PS_173, PS_174, PS_175, PS_176,
import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
// import { fetchApplicationNames, insertLastVisitedPageValueApi } from '../../Services/GeneralConfigurationService/appSpecificConfigurationsApi';
import objCryptoJS from "crypto-js";
import moment from 'moment';
import { decodeSessionDetails } from "../../Services/CommonServices";
import { getMappedMailboxes, updateMailUserAction, insertLastVisitedPageValueApi } from "../../Services/MigrationAndManageMigrationServices";
import { sessionResponse } from "../../Common/Interface";
import jwt from 'jwt-decode'
import { getSession } from "../../Services/manageClientAPI";

interface ManageMigrationProps {
    taskId: any;
    clientId: any;
    applicationName: any;
    taskName: any;
    isOpen: boolean;
    onToggle: () => void;
    onManageMigrationSave: () => void;
    reloadMigration: boolean;
    fetchDataInMigration: () => void;
}

const ManageMigration: React.FC<ManageMigrationProps> = ({ isOpen, onToggle, taskId, clientId, applicationName, onManageMigrationSave, reloadMigration, taskName, fetchDataInMigration }) => {
    // PS_94
    // PS_95
    let history = useHistory();
    let location = useLocation();

    // PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [totalDataCount, settotalDataCount] = useState<number>(0);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filterValueForDropDown, setFilterValueForDropDown] = useState<string>("")

    const [sortHide, setSortHide] = useState<string>("");
    const [statusSortHide, setStatusSortHide] = useState<string>("");

    const [sortBy, setSortBy] = useState<string>("su.modifiedDate");
    const [sortOrder, setSortOrder] = useState<string>("DESC");

    const [alertpopup, setAlertpopup] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [alert, setAlert] = useState<string>('');
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    // //debugger
    let InActiveTimeout: NodeJS.Timeout;

    const [totalRecords, setTotalRecords] = useState<number>(0);

    const obj: any = {
        outlook: [
            {
                header: "Source User Name",
                value: "sourceDisplayName",
                sort: "su.sourceDisplayName"
            },
            {
                header: "Source Email",
                value: "sourceMailId",
                sort: "su.sourceMailId"
            },
            {
                header: "Target User Name",
                value: "targetDisplayName",
                sort: "tu.targetDisplayName"
            },
            {
                header: "Target Email",
                value: "targetMailId",
                sort: "tu.targetMailId"
            },
            {
                header: "Scheduled At",
                value: "scheduledDateTime",
                sort: "scheduledDateTime",
            },
            {
                header: "Status",
                value: "Status",
                sort: "um.statusText"
            },
            {
                header: "Action",
                value: "Status",
                sort: ""
            },
        ],
    }

    // // PS_177,
    useEffect(() => {
        sessionTokenProperties();
        resetTimer();
        fetchData();
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);

        return () => {
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };


    }, [sortBy, sortHide, sortOrder, filterValueForDropDown, reloadMigration]);


    let resetTimer = async () => {
        try {
            // debugger;
            //console.log("Reset Timer Start");

            let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
            //console.log("sessionResponse value :", sessionResponse);

            // //debugger
            if (sessionResponse == 'expired') {
                debugger
                sessionStorage.removeItem("c_at");
                setisSessionEpired(true)
                setAlert('Your Session has Expired please login again to continue');
            }
            else if (sessionResponse == 'updateToken') {
                if (emailAddress == "" || emailAddress == undefined) {
                    await sessionTokenProperties();
                }
                debugger
                let token = await getToken()
                let payload = {
                    mailId: emailAddress,
                    isAdmin: false,
                    token: token
                };
                let getsessionToken = await getSession(payload);
                const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
                const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

                const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
                const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
                let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
                await sessionStorage.removeItem("c_at");
                await sessionStorage.setItem('c_at', updatedToken);
                let updatedobjClient: any = jwt(c_decryptedValue);
                SessionEpiredDateTime = updatedobjClient.CreatedAt;
                setSessionEpiredDateTime(SessionEpiredDateTime);
            }
            //console.log("Reset Timer End");
        }
        catch (error) {
            //console.log("Reset Timer Catch");
            setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
            setLoading(false);
        }
    }

    const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
            // debugger
            //console.log("Reset Session Timer Start");
            // Assuming InActiveTimeout is a number representing the ID of the current timeout

            if (typeof window !== 'undefined') {
                //console.log("Reset Session Timer Inside");
                clearTimeout(InActiveTimeout);
                const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

                InActiveTimeout = setTimeout(() => {
                    //console.log("Expired");
                    sessionStorage.removeItem("c_at");
                    //console.log("removed session");
                    setisSessionEpired(true)
                    setAlert('Your Session has Expired please login again to continue');
                    // return 'expired'
                }, tokenExpiryCheckInterval);

                //console.log("Reset Session Timer checking");

                const currentDateTime = new Date().toISOString();
                const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
                //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

                const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
                //console.log("diffInMilliSeconds values:", diffInMilliseconds);

                const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                //console.log("minutes : ", minutes);

                const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
                //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

                if (minutes > sessionUpdateTimeGap) {

                    //console.log("Reset Session Timer need to update");
                    return 'updateToken'
                }

                //console.log("Reset Session Timer End");
                return 'NoActionRequired'
            }
            return 'NoActionRequired'
        }
        catch (ex) {
            //console.log("Reset Session Timer Catch");
            throw ex
        }
    };


    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlertpopup(true);
            setLoading(false)

            throw error
        }
    }


    // PS_178, PS_179, PS_180, PS_181, PS_182, PS_183, PS_184, PS_185,
    const fetchData = async () => {
        setLoading(true); // Set loading state to true
        try {
            let token = await getToken()

            switch (applicationName) {
                case 'outlook': {
                    let payload = {
                        taskId: `${taskId}`,
                        token: token,
                        applicationName: `${applicationName}`,
                        searchValue: searchTerm,
                        sortOrder: sortOrder,
                        sortBy: sortBy,
                        status: filterValueForDropDown,
                        Action: "manageMigration",
                    }
                    const mappedMailboxes: any = await getMappedMailboxes(payload);
                    //console.log("mappedMailboxes", mappedMailboxes);
                    const newData = mappedMailboxes.data.data.map((item: any) => ({
                        ...item,
                        checked: false,
                    }));
                    setData(newData);
                    settotalDataCount(mappedMailboxes.data.data.length)
                    setTotalRecords(mappedMailboxes.data.totalData)
                    break;

                }
                default: {
                    break;
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setLoading(false)
        }
    };

    //PS_107, PS_108
    const setScheduleMigrationDateTime = (value: any) => {
        if (!value) return "-";
        const utcString = new Date(value).toUTCString();
        const date = new Date(utcString);
        const formattedDate = `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${date.getUTCFullYear()} ${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}`;
        return formattedDate;
    };

    //PS_105
    const getHeaderText = (applicationName: any) => {
        switch (applicationName) {
            case 'outlook':
                return 'User Mailbox';
            case 'groups':
                return 'Group';
            case 'roomsandequipments':
                return "Rooms & Equipment's";
            case 'onedrive':
                return 'OneDrive';
            case 'sharedmailbox':
                return 'Shared Mailbox';
            case 'sharepoint':
                return 'Mapped Sites';
            case 'teamschat':
                return 'Mapped Teams Chat';
            case 'teamschannel':
                return 'Mapped Teams Channel';
            default:
                return null;
        }
    };

    //  PS_191, PS_192, PS_193,
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };

    //PS_194, PS_195, PS_196, 
    const handleSearchClick = () => {
        const trimmedSearchTerm = searchTerm.trim();
        if (searchTerm.length === 0) {
            fetchData();
        }
        else if (searchTerm.length !== 0 && trimmedSearchTerm.length !== 0) {
            fetchData();
        }
    };

    //PS_197, PS_198, PS_199,
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            const trimmedSearchTerm = searchTerm.trim();
            if (searchTerm.length === 0) {
                fetchData();
            }
            else if (searchTerm.length !== 0 && trimmedSearchTerm.length !== 0) {
                fetchData();
            }
        }
    };

    //PS_200, PS_201, PS_202,
    const handleSort = (e: any) => {
        try {
            //console.log(e.target, "this is eeee");
            debugger;
            let sortID = e.target.id;
            let sortval = sortID.split("@");


            if (sortval[0] === 'um.userMappingActions') {
                setLoading(true)
                setStatusSortHide(sortID)
                // If sorting by status, sort based on text representation
                setData((data: any) => {
                    return data.slice().sort((a: { userMappingActions: any; }, b: { userMappingActions: any; }) => {
                        const statusA = getStatusText(a.userMappingActions);
                        const statusB = getStatusText(b.userMappingActions);
                        return sortval[1] === 'ASC' ? statusA.localeCompare(statusB) : statusB.localeCompare(statusA);
                    });
                });

                // Set timeout to turn off loader after 5 seconds
                setTimeout(() => {
                    setLoading(false); // Turn off loader
                }, 1000);
            } else {
                setSortHide(sortID);
                setSortBy(sortval[0]);
                setSortOrder(sortval[1]);
            }

        } catch (e: any) {
            //console.log(e.message);
        }
    };

    //PS_106
    const getStatusCSS = (status: any) => {
        // //console.log(status);

        switch (status.toString()) {
            case "0" || null:
                return "warning";
            case "1":
                return "paused";
            case "2":
                return "draft";
            case "3":
                return "scheduled";
            case "4":
                return "draft";
            case "5":
                return "inprogress";
            case "6":
                return "success";
            case "7":
                return "warning";
            default:
                return "draft";
        }
    };


    const getStatusText = (status: any) => {
        switch (status.toString()) {
            case "0" || null:
                return "Warning";
            case "1":
                return "Paused";
            case "2":
                return "Cancel";
            case "3":
                return "Scheduled";
            case "4":
                return "Queue";
            case "5":
                return "In Progress";
            case "6":
                return "Completed";
            case "7":
                return "Failed";
            default:
                return "default";
        }
    };

    // PS_203, PS_204, PS_205
    function handleDropDown(e: any) {
        setFilterValueForDropDown(e.target.value)
    }

    //PS_214, PS_215, PS_216, PS_217, PS_218, PS_219, PS_220, PS_221, PS_222,
    async function handleActionButtonClick(item: any, action: any, isAdmin = false) {
        // debugger
        //console.log("handle user action", item, action, isAdmin)
        try {
            let token = await getToken();

            switch (applicationName) {
                case 'outlook': {
                    if (action === '2' && action === '3' && item?.scheduleMigrationDateTime && !isAdmin) {
                        //console.log("Hello there");
                        let currentDateTimeUTC = moment.utc(); // Current UTC time
                        //console.log("🚀 ~ item?.scheduleMigrationDateTime:", item?.scheduleMigrationDateTime)
                        //console.log("🚀 ~ handleActionButtonClick ~ currentDateTimeUTC:", currentDateTimeUTC)

                        // Convert scheduleMigrationDateTime to moment object if it's not already
                        let scheduledTime = moment.utc(item.scheduleMigrationDateTime);

                        // Check if the difference is within a tolerance (e.g., 5 seconds)
                        if (scheduledTime.diff(currentDateTimeUTC, 'minutes') <= 5) {
                            setLoading(false)
                        }
                        else {
                            //console.log('lets send api')
                            let payload = {
                                taskId: taskId,
                                token: token,
                                id: item.sourceMailId,
                                applicationName: `${applicationName}`,
                                data: item,
                                Action: action,
                                isAdmin: isAdmin,
                                statusText: getStatusCSS(action)
                            }
                            setLoading(true)
                            const response: any = await updateMailUserAction(payload);
                            //console.log("mappedMailboxes", response);
                            if (response.data?.Status_code === 409) {
                                // setAlert(true)
                                setAlertMessage(response.data.message)
                                fetchData()
                                setLoading(false)
                            }
                            else {
                                fetchData()
                                fetchDataInMigration()
                                onManageMigrationSave()
                                setLoading(false)
                            }
                        }
                    }
                    else {
                        //console.log('lets send api')
                        let payload = {
                            taskId: taskId,
                            token: token,
                            id: item.sourceMailId,
                            applicationName: `${applicationName}`,
                            data: item,
                            Action: action,
                            isAdmin: isAdmin,
                            statusText: getStatusCSS(action)
                        }
                        setLoading(true)
                        const response: any = await updateMailUserAction(payload);
                        //console.log("mappedMailboxes", response);
                        if (response.data?.Status_code === 409) {
                            setLoading(false)
                            //   setAlert(true)
                            setAlertMessage(response.data.message)
                            fetchData()
                        }
                        else {
                            fetchData()
                            fetchDataInMigration()
                            onManageMigrationSave()
                            setLoading(false)
                        }
                    }
                    break;
                }
                default: {
                    break;
                }
            }

        }
        catch (error) {
            console.error("Error updating user Action data:", error);
        } finally {
            // setLoading(false)
        }
    }

    const insertLastVisitedPageValue = async (page: string) => {
        try {

            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: page
            };

            //console.log(payload, 'last visited');

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);

            if (!response) {
                //console.log('The current page value failed to insert');
            } else {
                //console.log('The current page value inserted successfully');

            }
            history.push('/task')
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };


    return (
        <>
            <div className="table-responsive theme-table bg-white mb-3">
                <table className="table table-borderless mb-0">
                    <thead>
                        <tr>
                            <th className="text-nowrap table-accordion-head">
                                <span className="d-flex align-items-center gap-3">
                                    <img
                                        src="/images/table-accordion-arrow.svg"
                                        alt="table-accordion-arrow"
                                        className={`cursor-pointer table-accordion-arrow ${isOpen ? "" : "collapsed"}`}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#zoobebe"
                                        onClick={onToggle}
                                    />
                                    <span className="ac-heading" onClick={onToggle}>
                                        Manage Migration
                                    </span>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`collapse ${isOpen ? "show" : ""}`} id="zoobebe">
                            <td colSpan={2}>
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="font-18 font-semibold mb-0 ">
                                        {getHeaderText(applicationName) && (
                                            <>
                                                {getHeaderText(applicationName)} (<span>{totalDataCount}</span>)
                                            </>
                                        )}
                                        {applicationName === 'groups' ? <img
                                            src="/images/tooltip.svg"
                                            alt="tooltip"
                                            width={11}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            data-bs-title="Tooltip content"
                                        /> : null}
                                    </h5>
                                    <span className="d-flex gap-3 flex-wrap align-items-center">
                                        <span className="d-flex gap-3 align-items-center">
                                            <span className="input-group input-search flex-nowrap">
                                                <input
                                                    type="search"
                                                    className="form-control border-end-0 font-14 font-regular w-auto"
                                                    placeholder="Search"
                                                    value={searchTerm}
                                                    onChange={handleSearch}
                                                    onKeyDown={(event) => { handleKeyDown(event) }}
                                                />
                                                <button
                                                    className="btn border-start-0 d-flex align-items-center"
                                                    type="button"
                                                >
                                                    <img src="/images/search.svg" alt="search" className="d-block "
                                                        onClick={handleSearchClick}
                                                    />
                                                </button>
                                            </span>
                                            <label className="font-14 font-semibold label-color text-nowrap ">
                                                Status
                                            </label>
                                            <select
    name="groupType"
    className="form-select w-100 h-100 m-0 font-14 font-regular"
    onChange={(e) => handleDropDown(e)}
>
    <option value="" selected={true}>All</option>
    <option value="3">Scheduled</option>
    <option value="4">Queue</option>
    <option value="5">In Progress</option>
    <option value="1">Paused</option>
    <option value="6">Completed</option>
    <option value="0">Warning</option>
    <option value="7">Failed</option>
</select>
                                        </span>
                                        <button className="btn rounded-1 p-3 refresh-btn action-rounded-btn retry-action d-flex align-items-center justify-content-center" onClick={fetchData} >
                                            <img src="/images/refresh-icon-2.svg" alt="retry" />
                                        </button>
                                    </span>
                                </div>
                                <div className="table-responsive theme-table bg-white d-inline-block w-100 custom-scroll table-y-scroll">
                                    <table className="table table-borderless mb-0">
                                        <thead className="sticky-top">
                                            <tr>
                                                {
                                                    obj && obj[applicationName.toLocaleLowerCase().trim()] && obj[applicationName.toLocaleLowerCase().trim()].map(({ header = '', value = '', sort = '' }: { header: string, value: string, sort: any }, index: number) => (
                                                        <>
                                                            {(role !== 'viewer' || header !== 'Action') && (
                                                                <th key={index} className="text-nowrap">
                                                                    {header}
                                                                    {header !== "Action" && (
                                                                        <span className="ms-1 cursor-pointer">
                                                                            {value !== 'userMappingActions' ? (
                                                                                <>
                                                                                    <img
                                                                                        src={`/images/sort-arrow-up.svg`}
                                                                                        alt="sort-arrow-up"
                                                                                        id={`${sort}@ASC`}
                                                                                        style={{ cursor: 'pointer', display: sortHide === `${sort}@DESC` ? 'inline' : 'none'}}
                                                                                        onClick={(e) => handleSort(e)}
                                                                                    />
                                                                                    <img
                                                                                        src={`/images/sort-arrow-down.svg`}
                                                                                        alt="sort-arrow-down"
                                                                                        id={`${sort}@DESC`}
                                                                                        style={{ cursor: 'pointer', display: sortHide === `${sort}@DESC` ? 'none' : 'inline' }}
                                                                                        onClick={(e) => handleSort(e)}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img
                                                                                        src={`/images/sort-arrow-up.svg`}
                                                                                        alt="sort-arrow-up"
                                                                                        id={`${sort}@ASC`}
                                                                                        style={{ cursor: 'pointer', display: statusSortHide === `${sort}@DESC` ? 'inline' : 'none' }}
                                                                                        onClick={(e) => handleSort(e)}
                                                                                    />
                                                                                    <img
                                                                                        src={`/images/sort-arrow-down.svg`}
                                                                                        alt="sort-arrow-down"
                                                                                        id={`${sort}@DESC`}
                                                                                        style={{ cursor: 'pointer', display: statusSortHide === `${sort}@DESC` ? 'none' : 'inline' }}
                                                                                        onClick={(e) => handleSort(e)}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </th>
                                                            )}
                                                        </>
                                                    ))
                                                }

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.length > 0 ? (
                                                data.map((user: any, index: any) => (
                                                    <tr key={index}>
                                                        {obj[applicationName.toLocaleLowerCase().trim()].map(({ header = '', value = '' }: { header: string, value: string }, index: number) => (
                                                            <td key={index} style={{ textTransform: value.toLowerCase() === "resourcetype" ? 'capitalize' : 'none' }}>
                                                                {
                                                                    value === 'scheduleMigrationDateTime' && user[value] ?
                                                                        setScheduleMigrationDateTime(user[value]) :
                                                                        value === 'Status' ? header === "Status" ?
                                                                            <span className="text-nowrap">
                                                                                <span className={`table-status ${getStatusCSS(user[value])} d-inline-block me-2`}></span>
                                                                                {getStatusText(user[value])}
                                                                            </span>
                                                                            :
                                                                            role !== "viewer" ?
                                                                                <span className="d-inline-flex gap-3">
                                                                                    {getStatusCSS(
                                                                                        user[value]).toLowerCase() === "warning" ? ( // Warning
                                                                                        <>
                                                                                            <button
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    title={"retry"}
                                                                                                    className="btn action-rounded-btn retry-action d-flex align-items-center justify-content-center"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom"
                                                                                                    data-bs-title="Retry"
                                                                                                    onClick={() =>
                                                                                                        handleActionButtonClick(user, '4')
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/refresh-icon-2.svg"
                                                                                                        alt="retry"
                                                                                                    />
                                                                                                </button>

                                                                                            {isAdmin && <button
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="right"
                                                                                                data-bs-title="Cancel"
                                                                                                title={"cancel"}
                                                                                                onClick={() =>
                                                                                                    handleActionButtonClick(user, '2', isAdmin)
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src="/images/cancel-icon.svg"
                                                                                                    alt="cancel"
                                                                                                />
                                                                                            </button>}

                                                                                        </>
                                                                                    ) : getStatusCSS(
                                                                                        user[value]).toLowerCase() === "draft" ||
                                                                                        getStatusCSS(
                                                                                            user[value]).toLowerCase() === "scheduled" ? ( // Queue or Scheduled
                                                                                        <button
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            title={"cancel"}
                                                                                            className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="bottom"
                                                                                            data-bs-title="Cancel"
                                                                                            onClick={() =>
                                                                                                handleActionButtonClick(user, '2')
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                src="/images/cancel-icon.svg"
                                                                                                alt="cancel"
                                                                                            />
                                                                                        </button>
                                                                                    ) :
                                                                                        getStatusCSS(
                                                                                            user[value]).toLowerCase() === "completed" && isAdmin ? ( // Queue or Scheduled
                                                                                            <button
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                title={"cancel"}
                                                                                                className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                                data-bs-toggle="tooltip"
                                                                                                data-bs-placement="bottom"
                                                                                                data-bs-title="Cancel"
                                                                                                onClick={() =>
                                                                                                    handleActionButtonClick(user, '2', isAdmin)
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src="/images/cancel-icon.svg"
                                                                                                    alt="cancel"
                                                                                                />
                                                                                            </button>
                                                                                        ) : getStatusCSS(
                                                                                            user[value]).toLowerCase() === "failed" ? ( // Failed
                                                                                            <>

                                                                                                <button
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    title={"retry"}
                                                                                                    className="btn action-rounded-btn retry-action d-flex align-items-center justify-content-center"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom"
                                                                                                    data-bs-title="Retry"
                                                                                                    onClick={() =>
                                                                                                        handleActionButtonClick(user, '4')
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/refresh-icon-2.svg"
                                                                                                        alt="retry"
                                                                                                    />
                                                                                                </button>

                                                                                                {isAdmin && <button
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="right"
                                                                                                    data-bs-title="Cancel"
                                                                                                    title={"cancel"}
                                                                                                    onClick={() =>
                                                                                                        handleActionButtonClick(user, '2', isAdmin)
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/cancel-icon.svg"
                                                                                                        alt="cancel"
                                                                                                    />
                                                                                                </button>}

                                                                                            </>
                                                                                        ) : getStatusCSS(
                                                                                            user[value]).toLowerCase() === "paused" ? ( // Paused
                                                                                            <>

                                                                                                <button
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    title={"resume"}
                                                                                                    className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom"
                                                                                                    data-bs-title="Resume"
                                                                                                    onClick={() =>
                                                                                                        handleActionButtonClick(user, '5')
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/pause-icon-2.svg"
                                                                                                        alt="in-progress"
                                                                                                        className=""
                                                                                                    />
                                                                                                </button>

                                                                                                {isAdmin && <button
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="right"
                                                                                                    data-bs-title="Cancel"
                                                                                                    title={"cancel"}
                                                                                                    onClick={() =>
                                                                                                        handleActionButtonClick(user, '2', isAdmin)
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/cancel-icon.svg"
                                                                                                        alt="cancel"
                                                                                                    />
                                                                                                </button>}

                                                                                            </>
                                                                                        ) :
                                                                                            getStatusCSS(
                                                                                                user[value]).toLowerCase() === "inprogress" ? ( // Paused
                                                                                                <>

                                                                                                    <button
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                        title={"resume"}
                                                                                                        className="btn action-rounded-btn in-progress-action d-flex align-items-center justify-content-center"
                                                                                                        data-bs-toggle="tooltip"
                                                                                                        data-bs-placement="bottom"
                                                                                                        data-bs-title="Resume"
                                                                                                        onClick={() =>
                                                                                                            handleActionButtonClick(user, '1')
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/images/pause-icon.svg"
                                                                                                            alt="in-progress"
                                                                                                            className=""
                                                                                                        />
                                                                                                    </button>

                                                                                                    {isAdmin && <button
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                        className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                                                                        data-bs-toggle="tooltip"
                                                                                                        data-bs-placement="right"
                                                                                                        data-bs-title="Cancel"
                                                                                                        title={"cancel"}
                                                                                                        onClick={() =>
                                                                                                            handleActionButtonClick(user, '2', isAdmin)
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src="/images/cancel-icon.svg"
                                                                                                            alt="cancel"
                                                                                                        />
                                                                                                    </button>}

                                                                                                </>
                                                                                            ) : null}
                                                                                </span> : null
                                                                            :
                                                                            user[value] ? (user[value]?.length > 21 ? (
                                                                                <span
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="right"
                                                                                    data-bs-title="Type Team Site"
                                                                                    data-bs-original-title=""
                                                                                    title={user[value]}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >{user[value].slice(0, 21) + '...'}</span>
                                                                            ) : user[value]) :
                                                                                '-'
                                                                }
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <div className="text-center">
                                                            <div className="pt-4 pb-3 text-center">
                                                                <img
                                                                    src="/images/no-data-found-img.svg"
                                                                    alt="no-data-found-img"
                                                                    className="mb-3"
                                                                />
                                                                <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                                                <p className="font-10 font-regular color-no-rec">
                                                                    There is no data to show you right now
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {loading && (
                    <div className="container-fluid">
                        <div className="overlay">
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <div
                                    className="spinner-border Loader loader-color align-center "
                                    role="status"
                                >
                                    <span>
                                        <span className="loader-inner-circle"></span>
                                    </span>
                                </div>
                                <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                    Loading..
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/*Failure Popup starts here */}
                {alertpopup ? <div
                    className="modal fade show"
                    tabIndex={-1}
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content popup-brd-radius">
                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                <span className="position-absolute">
                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                                </span>
                            </div>
                            <div className="modal-body border-0 text-center">
                                <h5
                                    className="modal-title mb-3 primary-header"
                                    id="Failure-popupLabel"
                                >
                                    Alert
                                </h5>
                                <p className="primary-data">
                                    {alertMessage}
                                </p>
                            </div>
                            <div className="modal-footer border-0 pb-4 justify-content-center">
                                <button
                                    type="button"
                                    className="btn primary-btn font-14 px-4 font-semibold"

                                    onClick={() => {
                                        if (isSessionEpired) {
                                            history.push('/Login')
                                        }
                                        setAlertpopup(false); setAlertMessage(''); fetchData(); fetchDataInMigration(); onManageMigrationSave()
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/*Failure Popup ends here */}

            </div>

            <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                    onClick={
                        () => {
                            insertLastVisitedPageValue('Configurations')

                            history.push("/Configurations", {
                                state: {
                                    taskId: taskId,
                                    taskName: taskName,
                                }
                            })
                        }
                    }
                    style={{ cursor: 'pointer' }}
                >
                    <img src="/images/left-arw-blue.svg" alt="back" width={12} />
                    <span className="ms-1">Back</span>
                </button>
                <div className="d-md-flex d-grid gap-3">
                    <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center order-2 order-md-0"
                        onClick={
                            () => {
                                insertLastVisitedPageValue('Selectmigration')
                                history.push('/task')
                            }
                        }
                        style={{ cursor: 'pointer' }}>
                        Cancel
                    </button>
                    <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                        disabled={true ? totalRecords === 0 : false}
                        onClick={
                            () => {
                                history.push('/task');
                            }
                        }
                        style={{ cursor: 'pointer' }}>
                        Finish
                        <img src="/images/right-arw.svg" alt="next" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default ManageMigration;
